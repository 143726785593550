import React, {useEffect, useRef, useState} from "react";
import * as ReactDOM from "react-dom"
import {useWindowSize,useFixScrollBar} from "hooks";

import Icon from "components/atoms/Icon";

import classNames from "classnames";

import "./style.scss"

const ModalWindow = ({show, isShowClose, closeClick, size, paddingTop, children}) => {
    const [isHeightSmall, setIsHeightSmall] = useState(false)
    const {height} = useWindowSize()
    const modalRef = useRef(null)

    const modalType = classNames("modal-window", size ?? "modal-lg", {top: paddingTop, "fix-top": isHeightSmall})

    useFixScrollBar(show)

    useEffect(() => {
        if (height < modalRef.current.offsetHeight) {
            setIsHeightSmall(true)
        } else {
            setIsHeightSmall(false)
        }
    }, [height])

    return (
        <React.Fragment>
            {
                show &&
                ReactDOM.createPortal(
                    <div className="modal-container">
                        <div ref={modalRef} className={modalType}>
                            {
                                isShowClose &&
                                <span
                                    className="close-icon"
                                    onClick={() => closeClick(false)}
                                >
                                    <Icon name="close"/>
                                </span>
                            }
                            {children}
                        </div>
                    </div>
                    , document.querySelector("body"))
            }
        </React.Fragment>
    )
}
export default ModalWindow