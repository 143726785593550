import React from "react";
import "./style.scss"
import UnderLine from "components/atoms/UnderLine";

const HomeComingSoon = () => {
    return (
        <section className="home_coming-soon">
            <div className="home_coming-soon_content">
                <div>
                    <h1><span>More game modes</span> <br/>Coming soon!</h1>
                    <UnderLine/>
                    <p>KOMPETE has more multiplayer madness up its sleeve in the future!</p>
                </div>
            </div>
        </section>
    )
}
export default HomeComingSoon