import React, {useEffect, useRef} from "react"

import {useCheckListedOrdersExpirationMutation} from "store/Bazaar/bazaar.api";
import {useConnectedWallet, useConnectWallet, useUtils,} from "hooks";
import {tabOptions, ERROR, SUCCESS, LOCKER_TABS} from "utils"
import {useModalsContext} from "layouts";

import {TabDynamicBlock, TabContent} from "components/molecules";
import LockerListedCosmetics from "./LockerListedCosmetics";
import LockerOwnedCosmetics from "./LockerOwnedCosmetics";
import LockerConvert from "./Konvert/LockerConvert";
import LockerActivity from "./LockerActivity";


const LockerCosmetics = ({activeTab, changeTab, lockerCosmeticRef}) => {
    const {switchChain} = useUtils()
    const {disconnectWallet} = useConnectWallet()
    const {setCurrentModal} = useModalsContext()
    const {isConnected, isWalletConnection, isConnectedAddressCorrect} = useConnectedWallet()

    const [checkOrdersExpiration] = useCheckListedOrdersExpirationMutation()

    const lockerTabRef = useRef(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => walletChecking(), [isConnected]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => checkOrdersExpiration(), [])

    const walletChecking = async () => {
        if (isConnected && !isWalletConnection) {
            setCurrentModal({status: "process", message: "Waiting for switch..."})
            const {status} = await switchChain()

            // if(!status){
            //     const {status: addChainStatus} = await addChain()
            // }

            if (!status) {
                setCurrentModal({status: ""})
                disconnectWallet()
            } else {
                if (!isConnectedAddressCorrect) {
                    setCurrentModal({status: "reject", message: ERROR.WALLET_CONNECTED_MATCH_INVALID})

                    disconnectWallet()
                } else {
                    setCurrentModal({status: "wallet-success", message: SUCCESS.WALLET_CONNECTED})
                    localStorage.setItem("wallet-connection", "CONNECT_SUCCESS")

                    setTimeout(() => {
                        setCurrentModal({status: "", message: ""})
                    }, 2000)
                }
            }
        }
    }

    return (
        <section className="locker_cosmetics" ref={lockerCosmeticRef}>
            <div className="locker_cosmetics_tabs" ref={lockerTabRef}>
                <TabDynamicBlock
                    options={tabOptions}
                    activeTab={activeTab}
                    isSetUrlParams={false}
                    setActiveTab={changeTab}
                />
            </div>
            <div className="locker_cosmetics_content">
                <div className="locker_cosmetics_content_general">
                    <TabContent id={LOCKER_TABS.OWNED} activeTab={activeTab}>
                        <LockerOwnedCosmetics parentSectionRef={lockerCosmeticRef}/>
                    </TabContent>
                    <TabContent id={LOCKER_TABS.LISTED} activeTab={activeTab}>
                        <LockerListedCosmetics/>
                    </TabContent>
                    <TabContent id={LOCKER_TABS.ACTIVITY} activeTab={activeTab}>
                        <LockerActivity/>
                    </TabContent>
                    <TabContent id={LOCKER_TABS.KONVERT} activeTab={activeTab}>
                        <LockerConvert/>
                    </TabContent>
                </div>
            </div>
        </section>
    )
}

export default LockerCosmetics