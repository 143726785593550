import React, {memo} from "react";
import navPaths from "../navPathData.json";
import NavItem from "../ResuableComponents/NavItem";

export const Menu = () => {
    return (
        <div className="navigation-mobile_content_menu">
            <ul>{navPaths.map((elm, i) =>
                <NavItem notLink={elm?.notLink} name={elm.name} path={elm.path} key={i}/>)}
            </ul>
        </div>
    )
}

export default memo(Menu)