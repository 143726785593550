import {png} from "assets/png";

export const BAZAAR_TABS = {
    ITEMS: "items",
    ACTIVITY: "activity",
}

export const BAZAAR_TABS_OPTION = [
    {id: BAZAAR_TABS.ITEMS, name: "Items for Sale", icon: png.OwnedItemsTab},
    {id: BAZAAR_TABS.ACTIVITY, name: "Activity", icon: png.ActivityTab},
]