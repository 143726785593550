import React from "react";

import {useModalsContext} from "layouts";

import {ProcessModal, RejectModal, LoginAlertModal, SuccessModal} from "components/molecules";
import LimitedTimeCurrentCosmeticSuccessful from "./LimitedTimeCurrentCosmeticSuccessful";
import LimitedTimeCurrentCosmeticOrderModal from "./LimitedTimeCurrentCosmeticOrderModal";

const LimitedTimeCurrentCosmeticSteps = ({quantity, changeStep}) => {
    const {currentModal, setCurrentModal} = useModalsContext()

    return (
        <React.Fragment>
            {
                {
                    "login":
                        <LoginAlertModal
                            show={true}
                            changeStep={changeStep}
                            closeClick={() => setCurrentModal({status: ""})}
                        />,
                    "order":
                        <LimitedTimeCurrentCosmeticOrderModal
                            show={true}
                            quantity={quantity}
                        />,
                    "reject":
                        <RejectModal
                            show={true}
                            text1="Oooops!"
                            text2={currentModal.message}
                            isShowClose={true}
                            closeClick={() => setCurrentModal({status: ""})}
                        />,
                    "process":
                        <ProcessModal
                            show={true}
                            text1="Waiting for signature..."
                            text2="After wallet approval, your transaction will be finished shortly."
                        />,
                    "success":
                        <LimitedTimeCurrentCosmeticSuccessful
                            show={true}
                            setWhichModalOpen={() => setCurrentModal({...currentModal, status: ""})}
                            quantity={quantity}
                        />,
                    "wallet-success":
                        <SuccessModal
                            show={true}
                            text1="Success!"
                            text2={currentModal?.message}
                            isShowClose={false}
                        />
                }[currentModal.status]
            }
        </React.Fragment>
    )
}

export default LimitedTimeCurrentCosmeticSteps