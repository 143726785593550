import React, {memo, useEffect, useState} from "react"
import Areas from "../ChartArea";

import {useGetInventoryPriceHistoryQuery, useSyncInventoryPriceHistoryMutation} from "store/Global/global.api";
import {CHART_DEFAULT_DATA, isEmpty} from "utils";

const PriceHistorySection = ({chainId, tokenId}) => {
    const [requestState, setRequestState] = useState({period: "ALL"});

    const {data} = useGetInventoryPriceHistoryQuery({...requestState}, {skip: !(requestState?.token_id || requestState?.chain_id)})

    const [syncInventoryPriceHistory] = useSyncInventoryPriceHistoryMutation()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => getAndSyncInventoryPriceHistory(), [chainId, tokenId]);

    const getAndSyncInventoryPriceHistory = () => {
        if (chainId && tokenId) {
            setRequestState({...requestState, token_id: tokenId, chain_id: chainId})
            syncInventoryPriceHistory({token_id: tokenId})
        }
    }

    return (
        <Areas
            isAxisRight={true}
            isAxisBottom={true}
            isPeriodButtons={true}
            checkedPeriod={requestState.period}
            margin={{top: 0, right: 0, bottom: 0, left: 40}}
            data={data && !isEmpty(data) ? data : CHART_DEFAULT_DATA}
            currentPeriodCallBack={(time) => setRequestState({...requestState, period: time})}
        />
    )
}

export default memo(PriceHistorySection)