import SecretAssaultRifleWeaponWebp from "./T_SecretAssaultRifle_Magma_Ico.webp"
import KompeteBackgroundLightWebp from "./kompete-background-light.webp"
import BasketBallMobileResized from "./basketball-mobile-resized.webp"
import SocialDeductionMobileWebp from "./social-deduction-mobile.webp"
import GameEconomyCosmeticsWebp from "./game-economy-cosmetics.webp"
import KompeteTokenStackedWebp from "./kompete-token-stacked.webp"
import Hero2Resized from "./hero-character-2-mobile-resized.webp"
import BazaarRarityTokenWebp from "./bazaar-rarity-token.webp"
import HeroEcoMainResized from "./hero-eco-main-resized.webp"
import HeroPrivacyResized from "./header-privacypolicy.webp"
import Basketball1Resized from "./basketball-1-resized.webp"
import RarityComponentsWebp from "./rarity-components.webp"
import KompeteTokeBookWebp from "./kompete-token-book.webp"
import GolfMobileResized from "./golf-mobile-resized.webp"
import HeroIndividual2Webp from "./hero-Individual-2.webp"
import HeroIndividual1Webp from "./hero-Individual-1.webp"
import HeroIndividual3Webp from "./hero-Individual-3.webp"
import HeroIndividual4Webp from "./hero-Individual-4.webp"
import HeroIndividual5Webp from "./hero-Individual-5.webp"
import SocialDeductionWebp from "./social-deduction.webp"
import BazaarCosmeticsWebp from "./bazaar-cometics.webp"
import MahzookooIsland5 from "./mahzookoo-island-5.webp"
import MahzookooIsland1 from "./mahzookoo-island-1.webp"
import MahzookooIsland2 from "./mahzookoo-island-2.webp"
import MahzookooIsland4 from "./mahzookoo-island-3.webp"
import MahzookooIsland3 from "./mahzookoo-island-4.webp"
import CosmeticSniperWebp from "./cosmetic_sniper.webp"
import KOMPETEAccess from "./KOMPETE_Early_Access.webp"
import BasketballMobile from "./basketball-mobile.webp"
import KompeteInBaseWebp from "./kompete-in-base.webp"
import CosmeticEmojiWebp from "./cosmetic_emoji.webp"
import FireBackground from "./fire-background-2.webp"
import Hero3Webp from "./home-header-background.webp"
import HeroMobile from "./hero-character-mobile.webp"
import CutOutPiranhaWebp from "./cutout-piranha.webp"
import CosmeticHippoWebp from "./cosmetic_hippo.webp"
import CosmeticSaharWebp from "./cosmetic_sahar.webp"
import GameMapResized from "./game-map-resized.webp"
import EthereumGoldWebp from "./ethereum-gold.webp"
import CoinbaseIconWebp from "./coinbase-icon.webp"
import HeroTermsResized from "./header-terms.webp"
import CosmeticCarWebp from "./cosmetic-car.webp"
import EthereumEcoWebp from "./ethereum-eco.webp"
import Interrogative from "./interrogative.webp"
import HeroEcoMain from "./hero-eco-main.webp"
import GolfResized from "./golf-resized.webp"
import VoolahEcoWebp from "./voolah-eco.webp"
import GameMap from "./mahzookoo-island.webp"
import Basketball1 from "./basketball-1.webp"
import GolfMobile from "./golf-mobile.webp"
import NewAvatar from "./new-avatar.webp"
import HeroNews from "./hero-news.webp"
import HeroEco from "./hero-eco.webp"
import Golf from "./golf.webp"
import T1Png from "./t1.webp"

import SecretAssaultRifleWeaponPng from "./T_SecretAssaultRifle_Magma_Ico.png"
import KompeteBackgroundLightPng from "./kompete-background-light.png"
import SocialDeductionMobilePng from "./social-deduction-mobile.png"
import HeroTermsMobileResized from "./hero-terms-mobile-resized.png"
import HeroMobileResized from "./hero-character-mobile-resized.jpg"
import GameEconomyCosmeticsPng from "./game-economy-cosmetics.png"
import PlaystationMobile from "./Playstation_download_mobile.png"
import KompeteTokenStackedPng from "./kompete-token-stacked.png"
import RedQuestionMarkMedium from "./red_question_mark_medium.png"
import RedQuestionMarkSmall from "./red_question_mark_small.png"
import RedQuestionMarkLarge from "./red_question_mark_large.png"
import FinalMontanaV2_resize from "./FinalMontanaV2-resize.png"
import GooglePlayMobile from "./GooglePlay_download_mobile.png"
import BazaarRarityTokenPng from "./bazaar-rarity-token.png"
import LogoModernizeGame from "./T_Logo_ModernizeGames.png"
import FinalMontana_resize from "./FinalMontage-resize.png"
import AppStoreMobile from "./AppStore_download_mobile.png"
import KompeteTokenBookPng from "./kompete-token-book.png"
import RarityComponentsPng from "./rarity-components.png"
import MysteryCosmetic1 from "./mystery-cosmetics-1.png"
import MysteryCosmetic2 from "./mystery-cosmetics-2.png"
import MysteryCosmetic3 from "./mystery-cosmetics-3.png"
import MysteryCosmetic4 from "./mystery-cosmetics-4.png"
import MysteryCosmetic5 from "./mystery-cosmetics-5.png"
import MysteryCosmetic6 from "./mystery-cosmetics-6.png"
import HeroIndividual1Png from "./hero-Individual-1.png"
import HeroIndividual2Png from "./hero-Individual-2.png"
import HeroIndividual3Png from "./hero-Individual-3.png"
import HeroIndividual4Png from "./hero-Individual-4.png"
import HeroIndividual5Png from "./hero-Individual-5.png"
import HeroThreeResized from "./hero-three-resized.png"
import HeroIndividual10 from "./hero-individual-10.png"
import HeroIndividual11 from "./hero-individual-11.png"
import SocialDeductionPng from "./social-deduction.png"
import Voolah13500Small from "./voolah-13500-small.png"
import HeroIndividual12 from "./hero-individual-12.png"
import EthereumIconGold from "./ethereum-icon-gold.png"
import BazaarCosmeticsPng from "./bazaar-cometics.png"
import Marketplace from "./marketplace-cosmetics.png"
import HeroNewsResized from "./hero-news-resized.png"
import HeroTernsMobile from "./hero-terms-mobile.png"
import HeroIndividual6 from "./hero-individual-6.png"
import HeroIndividual7 from "./hero-individual-7.png"
import HeroIndividual8 from "./hero-individual-8.png"
import GlobalPlayersDiagram from "./players-grph.png"
import Voolah5000Small from "./voolah-5000-small.png"
import Voolah1000Small from "./voolah-1000-small.png"
import Voolah2800Small from "./voolah-2800-small.png"
import HeroIndividual9 from "./hero-individual-9.png"
import CosmeticSniperPng from "./cosmetic_sniper.png"
import Playstation from "./Playstation_download.png"
import HeroPrivacy from "./header-privacypolicy.png"
import TriangleKompete from "./triangle-kompete.png"
import KompeteInBasePng from "./kompete-in-base.png"
import KompeteCoinBig from "./kompete-coin-big.png"
import Voolah500Small from "./voolah-500-small.png"
import Hero3Png from "./home-header-background.png"
import XboxMobile from "./Xbox_download_mobile.png"
import PlayStationPure from "./playstation-pur.png"
import GooglePlayPure from "./google-paly-pure.png"
import CutOutPiranhaPng from "./cutout-piranha.png"
import CosmeticHippoPng from "./cosmetic_hippo.png"
import CosmeticEmojiPng from "./cosmetic_emoji.png"
import CosmeticSaharPng from "./cosmetic_sahar.png"
import MockCosmetic12 from "./mock-cosmetic12.png"
import PolygonKompete from "./polygon-kompete.png"
import GooglePlay from "./GooglePlay_download.png"
import FinalMontanaV2 from "./FinalMontanaV2.png"
import EthereumGoldPng from "./ethereum-gold.png"
import LimitedTimeTab from "./listedItem-tab.png"
import ListedItemsTab from "./listedItem-tab.png"
import CoinbaseIconPng from "./coinbase-icon.png"
import KompeteBadger from "./kompete_badger.png"
import EpicGamePure from "./epick-game-pure.png"
import CosmeticCarPng from "./cosmetic-car.png"
import BaseIconBlue from "./base-icon-blue.png"
import CopyClipboard from "./copyClipboard.png"
import EthereumEcoPng from "./ethereum-eco.png"
import AppStorePure from "./app-store-pure.png"
import OwnedItemsTab from "./ownedItem-tab.png"
import Background from "./background-space.png"
import ComingBrand from "./coming-details.png"
import AppStore from "./AppStore_download.png"
import EthereumIcon from "./ethereum-icon.png"
import FinalMontana from "./FinalMontage.png"
import HeroResized from "./hero-resized.png"
import Rectangle02 from "./rectangle-02.png"
import Rectangle03 from "./rectangle-03.png"
import Rectangle04 from "./rectangle-04.png"
import RarityGroup from "./rarity-group.png"
import IslandPart2 from "./island-part2.png"
import IslandPart3 from "./island-part3.png"
import IslandPart4 from "./island-part4.png"
import IslandPart5 from "./island-part5.png"
import IslandPart6 from "./island-part6.png"
import CubeKompete from "./cube-kompete.png"
import Voolah13500 from "./voolah-13500.png"
import KompeteCoin from "./kompete-coin.png"
import ActivityTab from "./activity-tab.png"
import VoolahEcoPng from "./voolah-eco.png"
import Voolah1000 from "./voolah-1000.png"
import Voolah2800 from "./voolah-2800.png"
import Voolah5000 from "./voolah-5000.png"
import VoolahCoin from "./voolah-coin.png"
import VoolahLogo from "./voolah-logo.png"
import Hero2 from "./hero-character-2.png"
import HeroTerms from "./header-terms.png"
import IslandPart from "./island-part.png"
import Rectangle from "./rectangle-01.png"
import KonvertTab from "./konvert-tab.png"
import MockCosmetic17 from "./mock17.png"
import Xsolla from "./xsolla-service.png"
import Voolah500 from "./voolah-500.png"
import DivineTab from "./divine-tab.png"
import HeroThree from "./hero-three.png"
import KartRace from "./kart-race.png"
import Xbox from "./Xbox_download.png"
import XboxPure from "./xbox-pure.png"
import LogoKompete from "./logo.png"
import newLogo from "./newLogo.png"
import Founder from "./founder.png"
import Coming from "./coming.png"
import NotFound from "./404.png"
import LogoX from "./logo_X.png"
import T16Webp from "./t16.png"
import Check from "./Check.png"
import Coins from "./coins.png"
import T16Png from "./t16.png"
import T1Webp from "./t1.png"
import M7_2 from "./m7_2.png"
import T142 from "./t142.png"
import T141 from "./t142.png"
import M11 from "./m11.png"
import M18 from "./m18.png"
import T10 from "./t10.png"
import T11 from "./t11.png"
import T12 from "./t12.png"
import T13 from "./t13.png"
import T15 from "./t15.png"
import T2 from "./t2.png"
import T3 from "./t3.png"
import T4 from "./t4.png"
import T8 from "./t8.png"
import C1 from "./c1.png"
import C2 from "./c2.png"
import C3 from "./c3.png"
import C4 from "./c4.png"
import C5 from "./c5.png"

export const png = {
    SecretAssaultRifleWeaponWebp,
    KompeteBackgroundLightWebp,
    SocialDeductionMobileWebp,
    GameEconomyCosmeticsWebp,
    KompeteTokenStackedWebp,
    BazaarRarityTokenWebp,
    RedQuestionMarkMedium,
    RedQuestionMarkLarge,
    RedQuestionMarkSmall,
    RarityComponentsWebp,
    KompeteTokeBookWebp,
    BazaarCosmeticsWebp,
    SocialDeductionWebp,
    HeroIndividual3Webp,
    HeroIndividual4Webp,
    HeroIndividual5Webp,
    HeroIndividual1Webp,
    HeroIndividual2Webp,
    CosmeticSniperWebp,
    CosmeticSaharWebp,
    CosmeticEmojiWebp,
    KompeteInBaseWebp,
    CutOutPiranhaWebp,
    CosmeticHippoPng,
    CoinbaseIconWebp,
    EthereumGoldWebp,
    EthereumEcoWebp,
    CosmeticCarWebp,
    VoolahEcoWebp,
    T16Webp,
    T1Webp,

    SecretAssaultRifleWeaponPng,
    KompeteBackgroundLightPng,
    SocialDeductionMobilePng,
    BasketBallMobileResized,
    GameEconomyCosmeticsPng,
    HeroTermsMobileResized,
    KompeteTokenStackedPng,
    FinalMontanaV2_resize,
    BazaarRarityTokenPng,
    GlobalPlayersDiagram,
    KompeteTokenBookPng,
    RarityComponentsPng,
    FinalMontana_resize,
    HeroIndividual2Png,
    HeroPrivacyResized,
    Basketball1Resized,
    HeroIndividual1Png,
    HeroIndividual3Png,
    HeroEcoMainResized,
    HeroIndividual4Png,
    HeroIndividual5Png,
    SocialDeductionPng,
    BazaarCosmeticsPng,
    CosmeticHippoWebp,
    PlaystationMobile,
    HeroMobileResized,
    GolfMobileResized,
    LogoModernizeGame,
    CosmeticSniperPng,
    MysteryCosmetic1,
    MysteryCosmetic2,
    MysteryCosmetic3,
    MysteryCosmetic4,
    MysteryCosmetic5,
    MysteryCosmetic6,
    CosmeticSaharPng,
    CosmeticEmojiPng,
    EthereumIconGold,
    HeroThreeResized,
    BasketballMobile,
    HeroTermsResized,
    GooglePlayMobile,
    HeroIndividual10,
    HeroIndividual11,
    HeroIndividual12,
    MahzookooIsland1,
    Voolah13500Small,
    MahzookooIsland2,
    MahzookooIsland3,
    MahzookooIsland4,
    MahzookooIsland5,
    CutOutPiranhaPng,
    KompeteInBasePng,
    CoinbaseIconPng,
    TriangleKompete,
    HeroIndividual6,
    HeroIndividual7,
    HeroIndividual8,
    HeroIndividual9,
    EthereumGoldPng,
    HeroTernsMobile,
    HeroNewsResized,
    PlayStationPure,
    Voolah1000Small,
    Voolah2800Small,
    Voolah5000Small,
    MockCosmetic12,
    FireBackground,
    CosmeticCarPng,
    LimitedTimeTab,
    MockCosmetic17,
    GameMapResized,
    AppStoreMobile,
    Voolah500Small,
    ListedItemsTab,
    KompeteCoinBig,
    EthereumEcoPng,
    PolygonKompete,
    GooglePlayPure,
    FinalMontanaV2,
    CopyClipboard,
    OwnedItemsTab,
    KompeteBadger,
    Interrogative,
    KOMPETEAccess,
    BaseIconBlue,
    EthereumIcon,
    VoolahEcoPng,
    EpicGamePure,
    AppStorePure,
    FinalMontana,
    Hero2Resized,
    GolfResized,
    HeroEcoMain,
    Basketball1,
    HeroPrivacy,
    Marketplace,
    Rectangle02,
    ActivityTab,
    Rectangle03,
    Rectangle04,
    LogoKompete,
    CubeKompete,
    Voolah13500,
    KompeteCoin,
    HeroResized,
    ComingBrand,
    IslandPart2,
    IslandPart3,
    IslandPart4,
    IslandPart5,
    IslandPart6,
    RarityGroup,
    Playstation,
    KonvertTab,
    VoolahLogo,
    Voolah1000,
    Voolah2800,
    Voolah5000,
    VoolahCoin,
    IslandPart,
    Background,
    HeroMobile,
    XboxMobile,
    GooglePlay,
    GolfMobile,
    HeroThree,
    Hero3Webp,
    HeroTerms,
    Rectangle,
    Voolah500,
    DivineTab,
    NewAvatar,
    AppStore,
    XboxPure,
    Hero3Png,
    HeroNews,
    KartRace,
    NotFound,
    GameMap,
    HeroEco,
    Founder,
    newLogo,
    Coming,
    T16Png,
    Xsolla,
    LogoX,
    Coins,
    T1Png,
    Check,
    Hero2,
    M7_2,
    Golf,
    Xbox,
    T141,
    T142,
    M11,
    M18,
    T10,
    T11,
    T12,
    T13,
    T15,
    T2,
    T3,
    T4,
    T8,
    C1,
    C2,
    C3,
    C4,
    C5
}