import React from "react"
import {png} from "assets/png";
import Image from "components/atoms/Image";
import Title from "components/atoms/TitleTemplate";
import "./style.scss"

const TokenSeven = () => {
    return (
        <section className="token-seven">
                <Image className="c5" alt="coin" src={png.C5}/>
            <div className="container">
                <Title underline={true}>Even more <br/><span>utility</span></Title>
                <div className="content">
                    <div>
                        <Image alt="badger" src={png.KompeteBadger}/>
                    </div>
                    <div>
                        <div>
                            <div><span>1</span></div>
                            <div>Closed testing with the devs</div>
                        </div>
                        <div>
                            <div><span>2</span></div>
                            <div>Exclusive giveaways</div>
                        </div>
                        <div>
                            <div><span>3</span></div>
                            <div>Special cosmetic drops</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TokenSeven