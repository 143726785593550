import React from "react";

import Button from "components/atoms/Button";
import ModalWindowTemplate from "components/molecules/ModalWindowTemplate";
import Image from "components/atoms/Image";

import {svg} from "assets/svg";
import "../style.scss"

const StepRecoverPasswordSuccess = ({show, changeStep, checkMail}) => {
    return (
        <ModalWindowTemplate
            privacy={true}
            powered={true}
            show={show}
            changeStep={changeStep}
            isShowClose={true}
            type="window-lg"
            className="auth-step_step13"
        >
            <div className="auth-step_step13_img-block">
                <Image alt="success" src={svg.Success}/>
            </div>
            <h3>Check Your Email</h3>
            <p>We have sent you a recovery password link to your KOMPETE registered email address.</p>
            <Button view="secondary" onClick={() => checkMail()}>ok got it!</Button>
        </ModalWindowTemplate>
    )
}

export default StepRecoverPasswordSuccess