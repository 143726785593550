import React from "react";
import {svg} from "assets/svg";
import "./style.scss"

const NoCosmetic = ({text}) => {
    return (
        <div className="no-cosmetics">
            <div className="no-cosmetics_content">
                <img alt="reject" src={svg.Reject}/>
                <p>{text}</p>
            </div>
        </div>
    )
}
export default NoCosmetic