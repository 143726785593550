import React from "react"
import {Link} from "react-router-dom";

import ModalWindow from "components/atoms/ModalWindow";
import Image from "components/atoms/Image";
import classNames from "classnames";

import {svg} from "assets/svg";
import "./style.scss"

const ModalWindowTemplate = ({children, className, size, show, changeStep, title, paddingTop, isShowClose = true, privacy, powered}) => {

    const modalContent = classNames("modal-window-template_content", className)

    return (
        <ModalWindow isShowClose={isShowClose} show={show} size={size} closeClick={changeStep} paddingTop={paddingTop}>
            <div className="modal-window-template">
                <h1 className="modal-window-template_title">
                    {title}
                </h1>
                <div className={modalContent}>
                    {children}
                    <div className="modal-window-template_secure">
                        {
                            privacy &&
                            <div><Link to="/privacy">Privacy Policy</Link></div>
                        }
                        {
                            powered &&
                            <div>
                                <Image src={svg.Secured} alt="secure"/>
                                <span>Powered by <span>Modernize Games</span></span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </ModalWindow>
    )
}
export default ModalWindowTemplate