import React, {useEffect} from "react"
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

import {setBackgroundClass} from "store/Global/globalSlice"

import Button from "components/atoms/Button";
import Image from "components/atoms/Image";

import {svg} from "assets/svg";
import {png} from "assets/png";
import "./style.scss"

const KompeteToke = ({changeStep}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setBackgroundClass("fire-back"))

        return () => {
            dispatch(setBackgroundClass(""))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="protected-container_kompete-token-container">
            <div className="protected-container_kompete-token-container_modal">
                <div className="protected-container_kompete-token-container_modal_img">
                    <Image src={svg.Require} alt="secure"/>
                </div>
                <p className="protected-container_kompete-token-container_modal_text">
                    Access denied! Login to your KOMPETE account and own at least one KOMPETE Token to play the Closed
                    Beta.
                </p>
                <div className="protected-container_kompete-token-container_modal_balance-img">
                    <div>
                        <Image src={png.T1} alt="token"/>
                    </div>
                    <div>
                        <Button view="secondary" onClick={() => changeStep("step1")}>Login</Button>
                        <Button view="secondary" onClick={() => navigate("/token")}>Buy token</Button>
                    </div>
                </div>
            </div>
            <div className="protected-container_kompete-token-container_btns-block">
                <Button
                    onClick={() => window.open("https://discord.gg/wz5YrNtJqB", "_blank")}
                    view="white"
                    icon="discordBlack"
                    path={true}
                >
                    Get help in discord
                </Button>
                <Button
                    onClick={() => window.open("https://t.me/KOMPETEgameportal", "_blank")}
                    view="white"
                    icon="telegramBlack"
                    path={true}>
                    Get help in telegram
                </Button>
            </div>
        </div>
    )
}

export default KompeteToke