import React from "react";
import classNames from "classnames";
import {Image} from "components/atoms";
import LimitedTimeCurrentCosmeticEthPrice
    from "components/organisms/Shop/LimitedTimeShop/LimitedTimeCurrentCosmetic/LimitedTimeCurrentCosmeticEthPrice";

import {png} from "assets/png";
import {svg} from "assets/svg";
import "./style.scss"

const LimitedCard = (
    {
        size,
        name,
        image,
        rarity,
        voolah,
        active,
        isLoading,
        isSuccess,
        isNeverSold,
        cartClickCallBack
    }
) => {
    const nftCartStyle = classNames("nft-cart-limited", isLoading ? "" : rarity?.toLowerCase() ?? "close", size, {'select': active}, {'skeleton': isLoading})
    const neverSoldTagStyle = classNames("nft-cart-limited_never-sold-again", size)

    return (
        <div className={nftCartStyle} onClick={() => isSuccess ? cartClickCallBack() : ""}>
            {!isLoading &&
                <React.Fragment>
                    {image && <Image className="nft-img" alt="nft" src={image}/>}
                    {isNeverSold && <div className={neverSoldTagStyle}><span>Never sold again</span></div>}
                    <div className="nft-cart-limited_info">
                        <div className="nft-cart-limited_info_name-block">
                            <span>{isSuccess ? name : "COMING SOON!"}</span>
                        </div>
                        <div className="nft-cart-limited_info_price-block">
                            <div className="nft-cart-limited_info_price-block_voolah">
                                <Image alt="voolah" src={png.VoolahCoin}/>
                                <span>{isSuccess && voolah ? voolah : "?????"}</span>
                            </div>
                            <div className="nft-cart-limited_info_price-block_eth">
                                <Image alt="eth" src={svg.EtheriumGold}/>
                                {isSuccess && voolah ? <LimitedTimeCurrentCosmeticEthPrice
                                    voolahPrice={voolah}
                                    quantity={1}
                                /> : "?????"}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }
        </div>
    );
}

export default LimitedCard