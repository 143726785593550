import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ethers} from "ethers";

import {useCancelListedOrderMutation} from "store/Bazaar/bazaar.api";
import {useConnectedWallet, useConnectWallet} from "hooks";
import {ERROR, errorHandler, STEPS_STATUS} from "utils";
import {useModalsContext} from "layouts";

import CosmeticModalTemplate from "components/molecules/CosmeticModalTemplate";
import {ProcessModal} from "components/molecules";

const BazaarCurrentAlreadyListedWeb3CosmeticRemoveModal = () => {
    const {isConnectedAddressCorrect, address} = useConnectedWallet()
    const {disconnectWallet} = useConnectWallet()
    const {providers} = useConnectedWallet()
    const {walletConnectSigner: signer} = providers()
    const {setCurrentModal} = useModalsContext()
    const {currentModal} = useModalsContext()
    const {order_id} = useParams()

    const [cancelListedOrder, {isError, isSuccess}] = useCancelListedOrderMutation()

    const {name, image_url, rarity} = currentModal.data

    const [isProcess, setIsProcess] = useState(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => monitoringOrderCancelProcess(), [isSuccess, isError])

    const monitoringOrderCancelProcess = () => {
        if (isSuccess) {
            setCurrentModal({...currentModal, status: STEPS_STATUS.ORDER_CANCEL_SUCCESS})
        }

        if (isError) {
            setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.LISTING_FAIL_UPDATE})
        }
    }

    const removeListing = async () => {
        if (!isConnectedAddressCorrect) {
            setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.WALLET_CONNECTED_MATCH_INVALID})
            disconnectWallet()

            return
        }

        setIsProcess(true)

        const message = "Please sign to confirm the removal of your KOMPETE cosmetic listing"
        await signer.signMessage(message)
            .then((signature) => {
                const signerAddress = ethers.utils.verifyMessage(message, signature);

                if (signerAddress === address) {
                    cancelListedOrder({order_id})
                } else {
                    errorHandler(ERROR.CLEAN)
                }
            })
            .catch(() => {
                setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.LISTING_FAIL_UPDATE})
            })
    }

    return (
        <React.Fragment>
            {isProcess ?
                <ProcessModal
                    show={true}
                    text1="Waiting for signature..."
                    text2="After wallet approval, your transaction will be finished shortly."
                /> :
                <CosmeticModalTemplate
                    show={true}
                    name={name}
                    quantity="1"
                    rarity={rarity}
                    image={image_url}
                    isShowClose={true}
                    title="lising update!"
                    closeClick={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
                    buttonOne={{text: "remove listing", callBack: () => removeListing()}}
                />}
        </React.Fragment>
    )
}

export default BazaarCurrentAlreadyListedWeb3CosmeticRemoveModal