import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import {reset} from "store/AuthV2/AuthV2Slice"

import ModalWindowTemplate from "components/molecules/ModalWindowTemplate";
import Button from "components/atoms/Button";

import "../style.scss"

const StepSignUpSuccess = ({show, changeStep}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(reset("signUp"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ModalWindowTemplate
            show={show}
            privacy={true}
            paddingTop={true}
            className="auth-step_step7"
            changeStep={changeStep}
            title={<span>Account Created!</span>}
        >
            <p className="auth-step_step7_text">Your account has been created successfully!</p>
            <Button view="secondary" onClick={() => navigate("/download")}>Download kompete</Button>
        </ModalWindowTemplate>
    )
}

export default StepSignUpSuccess