import React, {useState, useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    userChangeNickname,
    userCheckNickname,
    setData,
    reset,
    resetNickname
} from "store/AuthV2/AuthV2Slice";
import {useLocalStorage} from "hooks";

import {Input, Button, LoadSpinner, LoadSpinnerAllPage} from "components/atoms";
import ModalWindowTemplate from "components/molecules/ModalWindowTemplate";

const StepNickname = ({show, changeStep}) => {
    const {removeToken} = useLocalStorage()
    const [nicknameValue, setNicknameValue] = useState("")
    const {checkNickname, signIn, changeNickname} = useSelector(state => state.authV2)
    const dispatch = useDispatch()
    let timeout;

    useEffect(() => {
        if (changeNickname.success) {
            changeStep("step7")
        }

        return () => {
            dispatch(resetNickname())
            dispatch(reset("changeNickname"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeNickname.success])


    const debounce = useCallback((callback, nickname, delay) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            if (nickname) dispatch(callback({nickname, accessToken: signIn.userData.accessToken}))
        }, delay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const signOut = () => {
        removeToken()
        dispatch(setData({path1: "signIn", path2: "userData", data: null}))
        dispatch(reset("signIn"))
        changeStep("")
        window.location.reload()
    }

    return (
        <React.Fragment>
            <ModalWindowTemplate
                show={show}
                isShowClose={false}
                className="auth-step_step14"
                title={<span>Create Your <br/> KOMPETE Nickname</span>}
            >
                <p>This is your in-game name that everyone will see. Nicknames can be changed later in your profile.</p>
                <div className="auth-step_step14_loadSpinner-block">
                    {checkNickname.loading && <LoadSpinner/>}
                </div>
                <Input
                    status={checkNickname.isValidNickname !== null && !checkNickname.isValidNickname ? "error" : "default"}
                    type="text"
                    label="nickname"
                    name="nickname"
                    onChange={(e) => {
                        setNicknameValue(e.target.value)
                        debounce(userCheckNickname, e.target.value, 1000)
                    }}
                    value={nicknameValue}
                    error={!checkNickname.isValidNickname ? checkNickname.errorMessage : ""}
                    placeholder="Nickname"
                />
                <div className="auth-step_step14_nickname-rules">
                    <ul>
                        <li>Must be between 3 and 16 characters</li>
                        <li>May contain letters, numbers, non-consecutive dashes, periods, underscores, and spaces.</li>
                    </ul>
                </div>
                <Button
                    view="secondary"
                    onClick={() => dispatch(userChangeNickname({
                        nickname: nicknameValue,
                        accessToken: signIn.userData.accessToken
                    }))}
                    disabled={!checkNickname.isValidNickname || checkNickname.loading}
                >
                    Register nickname
                </Button>
                <Button view="opacity" onClick={() => signOut()}>Log out</Button>
            </ModalWindowTemplate>
            <LoadSpinnerAllPage show={changeNickname.loading}/>
        </React.Fragment>
    )
}

export default StepNickname