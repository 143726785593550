import React, {memo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";

import {
    PROCESS,
    deepClone,
    SORT_OPTIONS,
    INVENTORY_TYPE,
    STANDARD_LIMIT_OPTIONS,
    INVENTORY_CORRESPOND_END,
    INVENTORY_FILTRATION_DATA,
    ORDERS_REQUEST_DEFAULT_DATA,
} from "utils";
import {useGetListedInventoriesQuery} from "store/Bazaar/bazaar.api";
import {useDebounce} from "hooks";

import {
    FiltersMobile,
    FiltersSideBar,
    NoCosmetic,
    OwnedCard,
    PaginationWithTools,
    FilterSideBarSkeleton
} from "components/molecules";
import {SkeletonNftCart, Refresh, GridContainer, Input, SelectDropDownDisplay, Icon} from "components/atoms";

const LockerListedCosmetics = () => {
    const inventoryFiltrationDefaultData = deepClone(INVENTORY_FILTRATION_DATA)
    const ordersRequestDefaultData = deepClone(ORDERS_REQUEST_DEFAULT_DATA)

    const navigate = useNavigate()
    const parentSectionRef = useRef(null)
    const debounce = useDebounce()

    const [filtration, setFiltration] = useState(inventoryFiltrationDefaultData)
    const [requestState, setRequestState] = useState(ordersRequestDefaultData)
    const [nameValue, setNameValue] = useState("")

    const {data, isLoading, isFetching} = useGetListedInventoriesQuery({data: requestState})

    const navigateTo = (order) => {
        const {id, chain, inventory} = order
        const {token_id, sku} = inventory
        const inventoryChainSku = sku + INVENTORY_CORRESPOND_END[chain]

        navigate(`/bazaar/inventory/${inventoryChainSku}/${token_id}/${id}`)
    }

    const updateRequestState = (newState) => setRequestState({...requestState, ...newState});

    const setInitialStateOfPagination = () => {
        setFiltration(prev => {
            prev.page.value = 1
            prev.limit.value = STANDARD_LIMIT_OPTIONS[0]

            return prev
        })
    }

    const requestStateHandler = async (event) => {
        const {value, name} = event.target

        if (name === "searchByName") {
            setNameValue(value)

            const callBack = () => {
                updateRequestState({value: value, offset: 0, limit: 12})
                setInitialStateOfPagination()
            }

            debounce(callBack, 500)
        } else {
            window.scrollTo({top: parentSectionRef.current.offsetTop - 100, left: 0, behavior: 'smooth'})

            setFiltration(prev => {
                switch (name) {
                    case "orderBy":
                        prev.page.value = 1
                        prev.orderBy.value = value.value
                        updateRequestState({orderBy: value.value.value})
                        break
                    case "limit":
                        prev.page.value = 1
                        prev.limit.value = value.value
                        updateRequestState({offset: 0, limit: value.value.value})
                        break
                    case "page":
                        prev.page.value = value
                        updateRequestState({offset: requestState.limit * (value ? value - 1 : 0)})
                        break
                    case "refresh":
                        prev = inventoryFiltrationDefaultData
                        updateRequestState(ordersRequestDefaultData)
                        break
                    default:
                        return prev
                }

                return prev
            })
        }
    }

    const filtrationHandler = (attribute, sectionName) => {
        setFiltration(prev => {
            const prevCopy = {...prev}

            prevCopy.filter.find(section => section.name === sectionName).attributes.forEach(attr => {
                if (attr.id === attribute.id) attr.checked = !attr.checked
            })

            return prevCopy
        })
    }

    const applyFiltration = () => {
        const applyData = {};

        filtration.filter.forEach(({filedName, attributes}) => {
            applyData[filedName] = attributes
                .filter(({checked}) => checked)
                .map(({id}) => id);
        });

        updateRequestState({...applyData, limit: 12, offset: 0})
        setInitialStateOfPagination()
    };

    const isAvailableOrders = data?.initial_total
    const isFirstRequest = data?.initial_total === undefined

    return (
        <div className="locker_cosmetics_content_general_listed" ref={parentSectionRef}>
            <div className="locker_cosmetics_content_general_listed_filter-container">
                {!!isAvailableOrders && <FiltersSideBar
                    details={filtration.filter}
                    applyCallBack={() => applyFiltration()}
                    setSideBarDetailsCallBack={(attribute, sectionName) => filtrationHandler(attribute, sectionName)}
                />}
                {isFirstRequest && <FilterSideBarSkeleton/>}
            </div>
            <div className="locker_cosmetics_content_general_listed_items-container">
                {isFirstRequest &&
                    <React.Fragment>
                        <div className="locker_cosmetics_content_general_listed_items-container_filtoration-tools">
                            <div className="refresh-skeleton skeleton"/>
                            <div className="input-skeleton skeleton"/>
                            <div className="select-skeleton skeleton"/>
                        </div>
                        <div
                            className="locker_cosmetics_content_general_listed_items-container_filtoration-tools_mobile"
                        >
                            <div className="filter-mobile-skeleton skeleton"/>
                            <div className="select-mobile-skeleton skeleton"/>
                        </div>
                    </React.Fragment>}
                {!!isAvailableOrders &&
                    <div className="locker_cosmetics_content_general_listed_items-container_filtoration-tools">
                        <Refresh refreshCallBack={() => requestStateHandler({target: {name: "refresh", value: ""}})}/>
                        <Input
                            icon="search"
                            value={nameValue}
                            name="searchByName"
                            placeholder="Search by name or attribute"
                            onChange={(e) => requestStateHandler(e)}
                        />
                        <SelectDropDownDisplay
                            name="orderBy"
                            btnText="Sorted by"
                            optionsList={SORT_OPTIONS}
                            value={filtration.orderBy.value}
                            onChange={(e) => requestStateHandler(e)}
                        />
                        <div
                            className="locker_cosmetics_content_general_listed_items-container_filtoration-tools_mobile"
                        >
                            <FiltersMobile
                                details={filtration.filter}
                                applyCallBack={() => applyFiltration()}
                                setSideBarDetailsCallBack={(attribute, sectionName) => filtrationHandler(attribute, sectionName)}
                            />
                            <SortMobile
                                requestState={requestState}
                                requestStateHandler={(e) => requestStateHandler(e)}
                            />
                        </div>
                    </div>
                }
                {(isLoading || isFetching)
                    ? <GridContainer>
                        <SkeletonNftCart
                            type="listed"
                            cartLineCount={data?.orders?.length ?? requestState.limit}
                        />
                    </GridContainer>
                    : !!data?.total
                        ? <GridContainer>
                            {data?.orders.map(order =>
                                <OwnedCard
                                    key={order.id}
                                    price={order.price}
                                    chainId={order.chain}
                                    name={order.inventory.name}
                                    trade={INVENTORY_TYPE.WEB3}
                                    img={order.inventory.image_url}
                                    onClick={() => navigateTo(order)}
                                    rarity={`card_${order.inventory.rarity.name}`}
                                />
                            )}
                        </GridContainer>
                        : <NoCosmetic text={PROCESS.NO_LISTED_COSMETICS}/>
                }
                {!!isAvailableOrders && <PaginationWithTools
                    totalNft={data?.total}
                    limitData={filtration.limit.data}
                    currentPage={filtration.page.value}
                    limitValue={filtration.limit.value}
                    callBackLimit={(e) => requestStateHandler(e)}
                    callBackCurrentPage={(e) => requestStateHandler({target: {name: "page", value: e}})}
                />}
            </div>
        </div>
    )
}

export default memo(LockerListedCosmetics)


export const SortMobile = ({requestState, requestStateHandler}) => {

    const sort = () => {
        const {orderBy} = requestState
        const ascValue = {
            name: "orderBy",
            value: {name: "Price Low To High", value: {name: "Price Low To High", value: "ASC"}}
        }

        const descValue = {
            name: "orderBy",
            value: {name: "Price High To Low", value: {name: "Price High To Low", value: "DESC"}}
        }
        const currentSortValue = orderBy ? orderBy === "ASC" ? descValue : ascValue : ascValue

        requestStateHandler({target: currentSortValue})
    }

    return (
        <button
            className="filter-mobile-wrapper_btn"
            onClick={() => sort()}
        >
            <Icon name="sort"/><span>Sort</span>
        </button>
    )
}