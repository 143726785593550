import React, {memo} from "react"
import {svg} from "assets/svg";
import {png} from "assets/png";

const SocialButtons = ({text, changeStep}) => {

    const signInSignUp = (network) => {
        if (network === "kompete") {
            if (text === "login") changeStep("step2")
            if (text === "create") changeStep("step5")
        } else {

            const query = new URLSearchParams({
                projectId: process.env.REACT_APP_XSOLLA_PROJECT_ID,
                login_url: window.location.origin
            })
            window.location.replace(`https://login.xsolla.com/api/social/${network}/login_redirect?${query}`)
        }
    }

    return (
        <div className="auth-step_social-buttons">
            <SocialButton
                network={"kompete"}
                text={`${text} with email`}
                img={png.newLogo}
                onClick={signInSignUp}
            />
            <SocialButton
                network={"twitter"}
                text={`${text} with X`}
                img={png.LogoX}
                onClick={signInSignUp}
            />
            <SocialButton
                network={"google"}
                text={`${text} with google`}
                img={svg.Google}
                onClick={signInSignUp}
            />
            <SocialButton
                network={"facebook"}
                text={`${text} with facebook`}
                img={svg.Facebook}
                onClick={signInSignUp}
            />
            <SocialButton
                network={"apple"}
                text={`${text} with apple`}
                img={svg.Apple}
                onClick={signInSignUp}
            />
        </div>
    )
}

export default memo(SocialButtons)

const SocialButton = ({img, text, network, onClick}) => {
    return (
        <div className="social-button" onClick={() => onClick(network)}>
            <div className="left">
                <div className={network}>
                    <img alt={network} src={img}/>
                </div>
                <div className="left_text">
                    <span>{text}</span>
                </div>
            </div>
            <div className="right">
                <span></span>
            </div>
        </div>
    )
}