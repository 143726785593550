import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {useConnectedWallet, useConnectWallet} from "hooks";
import {reset} from "store/AuthV2/AuthV2Slice"
import {validateEmail} from "utils";

import ChangeStepButtonTemplate from "../ReusableComponents/ChangeStepButtonTemplate";
import ModalWindowTemplate from "components/molecules/ModalWindowTemplate";
import CheckBox from "components/atoms/CheckBox";
import Button from "components/atoms/Button";
import Input from "components/atoms/Input";

import "../style.scss"

const StepSignUpForm = ({show, changeStep, fieldHandle, stepData, checkFields, registerUserDispatch}) => {
    const dispatch = useDispatch()

    const {disconnectWallet} = useConnectWallet()
    const {isConnected} = useConnectedWallet()

    const [error, setError] = useState("")

    const {signUp, signUpSocial, userVerification} = useSelector(state => state.authV2)
    const {email, password, checkbox1, checkbox3} = stepData.steps.step5.fields
    const {fields} = stepData.steps.step8

    const isStep5 = stepData.currentStep === "step5"
    const registrationType = userVerification.registration ? "social" : "regular"

    useEffect(() => {
        if (signUp.error || signUpSocial.error) {
            setError(signUp.errorMessage)
            dispatch(reset("signUp"))
        }
        if (signUp.success) {
            dispatch(reset("signUp"))
            setError("")
            changeStep("step6")
        }

        if (isConnected) disconnectWallet()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signUp.error, signUpSocial.error, signUp.success])

    const register = () => {
        const passwordRegexp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g

        if (isStep5) {
            checkFields()
            if (!email.value || !password.value) {
                setError("Account details incorrect. Please enter again")
                return;
            } else {
                if (!validateEmail(email.value)) {
                    setError("Invalid email address")
                    checkFields("email")
                    return;
                }

                if (!password.value.match(passwordRegexp)) {
                    setError("Invalid password")
                    checkFields("password")
                    return;
                }
            }

            if (!checkbox1.value) {
                setError("You must agree to the terms of service")
                return;
            }

            if (!checkbox3.value) {
                setError("You should be 13 and above ")
                return;
            }

        } else {
            if (!fields.checkbox1.value) {
                setError("You must agree to the terms of service")
                return;
            }

            if (!fields.checkbox3.value) {
                setError("You should be 13 years and above ")
                return;
            }
        }
        registerUserDispatch(registrationType)
    }

    return (
        <ModalWindowTemplate
            show={show}
            privacy={true}
            paddingTop={true}
            powered={true}
            className={"auth-step_" + stepData.currentStep}
            changeStep={changeStep}
            title={<span>Create your  <br/> KOMPETE Account</span>}
            isShowClose={isStep5}
        >
            {
                isStep5 &&
                <React.Fragment>
                    <Input
                        status={email.validation || error ? "error" : "default"}
                        type="email"
                        label="email address"
                        name="email"
                        onChange={fieldHandle}
                        value={email.value}
                        error={error}
                        placeholder="Email address"
                    />
                    <Input
                        status={password.validation || error ? "error" : "default"}
                        type="password"
                        label="password"
                        name="password"
                        onChange={fieldHandle}
                        value={password.value}
                        placeholder="Password"
                    />
                    <span className="auth-step_step5_inp-info">
                        Must have 7+ characters, at least 1 number, at least 1 letter, and no whitespace.
                    </span>
                </React.Fragment>
            }
            <div className="checkbox-block">
                <CheckBox
                    extent="large"
                    name="checkbox1"
                    view="green"
                    onChange={fieldHandle}
                    checked={isStep5 ? checkbox1.value : fields.checkbox1.value}
                >
                    I have read and agree to the <Link to="/terms">KOMPETE Terms of Service</Link>
                </CheckBox>
                <CheckBox
                    extent="large"
                    name="checkbox3"
                    view="green"
                    onChange={fieldHandle}
                    checked={isStep5 ? checkbox3.value : fields.checkbox3.value}
                >
                    I am over 13 years old
                </CheckBox>
            </div>
            <Button view="secondary" onClick={() => register()}>Sign up</Button>
            {
                isStep5 &&
                <ChangeStepButtonTemplate changeStep={changeStep} type="signIn" textLink={true}/>
            }
        </ModalWindowTemplate>
    )
}

export default StepSignUpForm