import React from "react";
import {useWindowSize} from "hooks";
import DropdownItem from "components/atoms/DropdownItem";
import Title from "components/atoms/TitleTemplate";

const HomeSpacs = () => {
    const {width} = useWindowSize()

    return (
        <section className="home_tech-specs">
            <Title underline={true}>tech <span>specs</span></Title>
            <div className="home_tech-specs_content container">
                <DropdownItem
                    title=" PC Minimum Specifications"
                >
                    <div className="home_tech-specs_content_dropdown">
                        <ul>
                            <li>
                                <label>Operating system</label>
                                <p>Windows 10 64 Bit</p>
                            </li>
                            <li>
                                <label>CPU</label>
                                <p>Intel Core i3-4340 or AMD FX-6300</p>
                            </li>
                            <li>
                                <label>RAM</label>
                                <p>8 GB RAM</p>
                            </li>
                            <li>
                                <label>Storage Required</label>
                                <p>10 GB Space</p>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <label>Video</label>
                                <p>NVIDIA GeForce 1060 / Intel HD 4000</p>
                            </li>
                            <li>
                                <label>Direct X</label>
                                <p>Requires DirectX 11 Compatible System</p>
                            </li>
                            <li>
                                <label>Network</label>
                                <p>Broadband Internet connection</p>
                            </li>
                            <li>
                                <label>Sound card</label>
                                <p>DirectX Compatible</p>
                            </li>
                        </ul>
                    </div>
                </DropdownItem>
                <DropdownItem
                    title="PC Recommended Specifications"
                    open={width > 991}
                >
                    <div className="home_tech-specs_content_dropdown">
                        <ul>
                            <li>
                                <label>Operating system</label>
                                <p>Windows 11 64 Bit</p>
                            </li>
                            <li>
                                <label>CPU</label>
                                <p>Intel Core i5-2500K or AMD Ryzen R5 1600x</p>
                            </li>
                            <li>
                                <label>RAM</label>
                                <p>12 GB RAM</p>
                            </li>
                            <li>
                                <label>Storage Required</label>
                                <p>10 GB Space</p>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <label>Video</label>
                                <p>NVIDIA GeForce GTX 980 / GTX 1660 or Radeon R9 390 / AMD RX 580</p>
                            </li>
                            <li>
                                <label>Direct X</label>
                                <p>Requires DirectX 12 Compatible System</p>
                            </li>
                            <li>
                                <label>network</label>
                                <p>Broadband Internet connection</p>
                            </li>
                            <li>
                                <label>sound card</label>
                                <p>DirectX Compatible</p>
                            </li>
                        </ul>
                    </div>
                </DropdownItem>
                <DropdownItem
                    title="Mobile Minimum Specifications"
                    open={width < 991}
                >
                    <div className="home_tech-specs_content_dropdown">
                        <ul>
                            <li>
                                <label>Device</label>
                                <p>iPhone 14 / Samsung Galaxy S10+</p>
                            </li>
                            <li>
                                <label>Operating system</label>
                                <p>iOS 13 / Android 13</p>
                            </li>
                            <li>
                                <label>RAM</label>
                                <p>6 GB RAM</p>
                            </li>
                        </ul>
                    </div>
                </DropdownItem>
                <DropdownItem
                    title=" Mobile Recommended Specifications"
                >
                    <div className="home_tech-specs_content_dropdown">
                        <ul>
                            <li>
                                <label>Device</label>
                                <p>iPhone 14 / Samsung Galaxy S22</p>
                            </li>
                            <li>
                                <label>Operating system</label>
                                <p>iOS 16 / Android 13</p>
                            </li>
                            <li>
                                <label>RAM</label>
                                <p>6 GB RAM</p>
                            </li>
                        </ul>
                    </div>
                </DropdownItem>
            </div>
        </section>
    )
}
export default HomeSpacs