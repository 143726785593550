import React from "react";
import {useDispatch} from "react-redux";

import {reset} from "store/VoolahShop/voolahShopSlice"
import {voolahPackageDetails} from "utils";

import {ModalWindowTemplate, PaymentMethod} from "components/molecules";
import {Button, Image} from "components/atoms";

import {png} from "assets/png";

const VoolahOrderModal = ({show, setWhichModalOpen, currentPackageIndex}) => {
    const dispatch = useDispatch()

    const cancelOrder = () => {
        setWhichModalOpen({status: ""})
        dispatch(reset({section: "paymentUIvoolah"}))
    }

    const openWidget = () => {
        setWhichModalOpen({status: ""})
        setTimeout(() => {
            window.XPayStationWidget.open()
        }, 10)
        dispatch(reset({section: "paymentUIvoolah"}))
    }

    return (
        <ModalWindowTemplate
            show={show}
            powered={true}
            size="modal-lg"
            isShowClose={true}
            title="Order Summary"
            changeStep={()=>cancelOrder()}
            className="shop-voolah_order-summery"
        >
            <div className="shop-voolah_order-summery_content">
                <div className="shop-voolah_order-summery_content_info">
                    <div className="shop-voolah_order-summery_content_info_img">
                        <Image alt="cosmetic" src={voolahPackageDetails[currentPackageIndex].src.small}/>
                    </div>
                    <div className="shop-voolah_order-summery_content_info_details">
                        <div className="shop-voolah_order-summery_content_info_details_title">
                            <Image alt="voolah" src={png.VoolahCoin}/>
                            <h5>{voolahPackageDetails[currentPackageIndex].value} Voolah</h5>
                        </div>
                    </div>
                </div>
                <div className="shop-voolah_order-summery_content_payments-methods">
                    <div className="shop-voolah_order-summery_content_payments-methods_head">
                        <span className="shop-voolah_order-summery_content_payments-methods_head_quantity">
                            Available payment methods (1)
                        </span>
                    </div>
                    <PaymentMethod
                        method="xsolla"
                        checked={true}
                        recommended={true}
                        disable={true}
                    />
                </div>
                <div className="shop-voolah_order-summery_content_total">
                    <div>Total</div>
                    <div>${voolahPackageDetails[currentPackageIndex].price} USD</div>
                </div>
                <div className="shop-voolah_order-summery_content_buttons">
                    <Button onClick={openWidget}>Pay Now</Button>
                </div>
            </div>
        </ModalWindowTemplate>
    )
}

export default VoolahOrderModal