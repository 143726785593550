import React from "react"
import {useCountdownTimer} from "hooks";
import "./style.scss"

const CountDown = ({date, day = true}) => {
    const {countdown} = useCountdownTimer(date, day)

    return (
        <div className="countdown">
            <div className="numbers">
                {
                    day &&
                    <React.Fragment>
                        <div className="num-card">
                            <span className="num">{countdown.days}</span>
                            <span className="num-label">Days</span>
                        </div>
                        <span className="divider">:</span>
                    </React.Fragment>
                }
                <div className="num-card">
                    <span className="num">
                        {countdown.hours}
                    </span>
                    <span className="num-label">Hours</span>
                </div>
                <span className="divider">:</span>
                <div className="num-card">
                    <span className="num">{countdown.minutes}</span>
                    <span className="num-label">Minutes</span>
                </div>
                <span className="divider">:</span>
                <div className="num-card">
                    <span className="num"
                          id="secs">{countdown.seconds}</span>
                    <span className="num-label">Seconds</span>
                </div>
            </div>
        </div>
    )
}

export default CountDown