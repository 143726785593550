import React from "react";

import DefaultLayout from "layouts/DefaultLayout";
import EconomyMain from "components/organisms/GameEconomy/EconomyMain";
import EconomyCollecting from "components/organisms/GameEconomy/EconomyCollecting";
import EconomyYouPay from "components/organisms/GameEconomy/EconomyYouPay";
import EconomyBuySell from "components/organisms/GameEconomy/EconomyBuySell";
import Seo from "components/atoms/Seo";
import EcoModular from "components/organisms/GameEconomy/EcoModular";

const GameEconomy = () => {
    return (
        <DefaultLayout>
            <Seo
                title="Game Economy - KOMPETE"
                description="This is the game economy for KOMPETE."
            />
            <EconomyMain/>
            <EcoModular/>
            <EconomyCollecting/>
            <EconomyYouPay/>
            <EconomyBuySell/>
        </DefaultLayout>
    )
}

export default GameEconomy