import {useLocation, useNavigate} from "react-router-dom";
import React from "react";

const NavItem = ({path, name, notLink}) => {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <li
            onClick={() =>
                notLink
                    ? window.open(path, "_blank")
                    : navigate(path)
            }
            className={location.pathname === path ? "active" : ""}
        >
           <span>{name}</span>
        </li>
    )
}

export default NavItem