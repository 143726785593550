import React, {useEffect, memo} from "react";
import {useNavigate} from "react-router-dom";

import {useConnectedWallet, useConnectWallet, useUtils} from "hooks";
import {ERROR, STEPS_STATUS, SUCCESS} from "utils";
import {useModalsContext} from "layouts";

import BazaarCurrentAlreadyListedWeb3CosmeticRemoveModal from "./BazaarCurrentAlreadyListedWeb3CosmeticRemoveModal";
import BazaarCurrentAlreadyListedWeb3CosmeticEditModal from "./BazaarCurrentAlreadyListedWeb3CosmeticEditModal"
import BazaarSuccessModal from "./BazaarCurrentAlreadyListedWeb3CosmeticSuccessModal";
import {SuccessModal, ProcessModal, RejectModal} from "components/molecules";

const BazaarCurrentCosmeticSteps = () => {
    const {currentModal, setCurrentModal} = useModalsContext()

    const {isConnected, isWalletConnection, isConnectedAddressCorrect} = useConnectedWallet()
    const {disconnectWallet} = useConnectWallet()
    const {switchChain} = useUtils()

    const navigate = useNavigate()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect( () => walletChecking(), [isConnected]);

    const walletChecking = async () => {
        if (isConnected && !isWalletConnection) {
            setCurrentModal({status: STEPS_STATUS.PROCESS, message: "Waiting for switch..."})
            const {status} = await switchChain()

            if (!status) {
                setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.FAIL_REQUEST})
                disconnectWallet()
            } else {
                if (!isConnectedAddressCorrect) {
                    setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.WALLET_CONNECTED_MATCH_INVALID})

                    disconnectWallet()
                } else {
                    setCurrentModal({status: STEPS_STATUS.SUCCESS, message: SUCCESS.WALLET_CONNECTED})
                    localStorage.setItem("wallet-connection", "CONNECT_SUCCESS")

                    setTimeout(() => {
                        setCurrentModal({status: STEPS_STATUS.CLOSE, message: ERROR.CLEAN})
                    }, 2000)
                }
            }
        }
    }

    return (
        <React.Fragment>
            {
                {
                    [STEPS_STATUS.PROCESS]:
                        <ProcessModal
                            show={true}
                            text1={currentModal.message ?? "Waiting for signature..."}
                            text2="After wallet approval, your transaction will be finished shortly."
                        />,
                    [STEPS_STATUS.REJECT]:
                        <RejectModal
                            show={true}
                            text1="Ooops!"
                            text2={currentModal?.message}
                            closeClick={() => navigate("/bazaar")}
                        />,
                    [STEPS_STATUS.SUCCESS]:
                        <SuccessModal
                            show={true}
                            text1="Success!"
                            text2={currentModal?.message}
                        />,
                    [STEPS_STATUS.ORDER_EDIT]:
                        <BazaarCurrentAlreadyListedWeb3CosmeticEditModal/>,
                    [STEPS_STATUS.ORDER_REMOVE]:
                        <BazaarCurrentAlreadyListedWeb3CosmeticRemoveModal/>,
                    [STEPS_STATUS.ORDER_EDIT_SUCCESS]:
                        <BazaarSuccessModal
                            title="update successful!"
                            setCurrentModal={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
                        />,
                    [STEPS_STATUS.ORDER_CANCEL_SUCCESS]:
                        <BazaarSuccessModal
                            title="update successful!"
                            setCurrentModal={() => navigate("/locker")}
                        />,
                    [STEPS_STATUS.ORDER_PURCHASE_SUCCESS]:
                        <BazaarSuccessModal
                            title="purchase successful!"
                            setCurrentModal={() => navigate("/locker")}
                        />
                }[currentModal.status]
            }
        </React.Fragment>
    )
}

export default memo(BazaarCurrentCosmeticSteps)