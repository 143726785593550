import React from "react";

import ProgressiveImage from "components/atoms/ProgressiveImage";
import UnderLine from "components/atoms/UnderLine";
import {png} from "assets/png";

import "./style.scss"

const EconomyMain = () => {
    return (
        <div className="economy_main">
            <div></div>
            <div className="economy_main_block">
                <div className="economy_main_block_left-part">
                    <h1>A <span>player driven</span> game economy</h1>
                    <UnderLine/>
                    <p>Play for free, collect cosmetic items, and trade them with other players.</p>
                </div>
                <div className="economy_main_block_right-part">
                    <ProgressiveImage
                        className="desktop-img"
                        imgSrc={png.GameEconomyCosmeticsPng}
                        previewSrc={png.GameEconomyCosmeticsWebp}
                    />
                </div>
            </div>
        </div>
    )
}
export default EconomyMain