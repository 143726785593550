import React from "react";

import {ModalsProvider} from "layouts";

import ProfileSteps from "components/organisms/Profile/ProfileSteps";
import ProfileSetting from "components/organisms/Profile/Profile";
import ProtectedPage from "components/molecules/ProtectedPage";
import DefaultLayout from "layouts/DefaultLayout";
import Seo from "components/atoms/Seo";

const Profile = () => {
    return (
        <DefaultLayout>
            <Seo
                title="Profile - KOMPETE"
                description="Make updates to your KOMPETE profile."
            />
            <ProtectedPage>
                <ModalsProvider>
                    <ProfileSetting/>
                    <ProfileSteps/>
                </ModalsProvider>
            </ProtectedPage>
        </DefaultLayout>
    )
}

export default Profile