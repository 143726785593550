import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {userRecoverPassword, reset} from "store/AuthV2/AuthV2Slice";
import {validateEmail} from "utils";
import form from "./form";

import ModalWindowTemplate from "components/molecules/ModalWindowTemplate";
import LoadSpinnerAllPage from "components/atoms/LoadSpinner/LoadSpinnerAllPage";
import Input from "components/atoms/Input";
import Button from "components/atoms/Button";

import "../style.scss"

const StepRecoverPassword = ({show, stepData, setStepData, changeStep, fieldHandle}) => {
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [isButtonDisable, setButtonDisabled] = useState(true)
    const {loading, success} = useSelector(state => state.authV2.recoverPassword)
    const dispatch = useDispatch()

    useEffect(() => {

        if (success) {
            setStepData(prev => {
                return {
                    ...prev,
                    currentStep: "step13",
                    currentEmail: prev.steps.step3.fields.email.value,
                    steps: {
                        ...prev.steps,
                        step3: form.steps.step3
                    }
                }
            })
            dispatch(reset("recoverPassword"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success])

    const recover = () => {
        const {email} = stepData.steps.step3.fields
        if (email.value) {
            dispatch(userRecoverPassword({username: email.value}))
        }
    }

    const checkEmail = (e) => {
        const {value} = e.target

        let checked = validateEmail(value)

        if (checked) {
            setInvalidEmail(false)
            setButtonDisabled(false)
        } else {
            setInvalidEmail(true)
            setButtonDisabled(true)
        }
    }

    return (
        <React.Fragment>
            <ModalWindowTemplate
                show={show}
                privacy={true}
                powered={true}
                paddingTop={true}
                className="auth-step_step3"
                changeStep={changeStep}
                title={<span>Recover Your KOMPETE  <br/>  Account Password</span>}
            >
                <p className="auth-step_step3_underTitle ">Enter the email address to your KOMPETE account.</p>
                <Input
                    status={invalidEmail ? "error" : "default"}
                    type="email"
                    label="email address"
                    name="email"
                    onChange={(e) => {
                        fieldHandle(e)
                        checkEmail(e)
                    }}
                    value={stepData.steps.step3.fields.email.value}
                    error={invalidEmail ? "Invalid email address" : ""}
                    placeholder="Email address"
                />
                <Button
                    view="secondary"
                    onClick={() => recover()}
                    disabled={isButtonDisable}
                >
                    Recover Password
                </Button>
            </ModalWindowTemplate>
            <LoadSpinnerAllPage show={loading}/>
        </React.Fragment>
    )
}

export default StepRecoverPassword