import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import classNames from "classnames";

import {useConnectedWallet, useConnectWallet, useUtils, useWindowSize} from "hooks";
import {ERROR, errorHandler, LISTING_DAY_OPTIONS, STEPS_STATUS} from "utils";
import {useUpdateListedOrderMutation} from "store/Bazaar/bazaar.api";
import {setData} from "store/Locker/lockerSlice";
import {useModalsContext} from "layouts";

import {useLockerCurrentCosmetic} from "../../Locker/LockerCurrentCosmetic/useLockerCurrentCosmetic";
import {Button, Input, Rarity, Image, SelectDropDownDisplay} from "components/atoms";
import {ModalWindowTemplate, ProcessModal} from "components/molecules";

const BazaarCurrentAlreadyListedWeb3CosmeticEditModal = () => {
    const {isConnectedAddressCorrect} = useConnectedWallet()
    const {getChainDataById, addChain, switchChain} = useUtils()
    const {currentModal, setCurrentModal} = useModalsContext()
    const {disconnectWallet} = useConnectWallet()
    const {order_id} = useParams()
    const {width} = useWindowSize()

    const dispatch = useDispatch()

    const {token_id, name, image_url, rarity, groups, chain_id} = currentModal.data
    const [updateListedOrder, {isSuccess, isError, data}] = useUpdateListedOrderMutation()

    const [expires, setExpires] = useState({name: "7 Days", value: 7})
    const [isStartCreatingOrder, setIsStartCreatingOrder] = useState(false)
    const [isProcess, setIsProcess] = useState(false)
    const [fieldsError, setFieldsError] = useState("")
    const [listPrice, setListPrice] = useState("")

    const {createOrder} = useLockerCurrentCosmetic(expires, listPrice, token_id)

    const contentStyle = classNames("bazaar-current-cosmetic-section_listed_modal_content")
    const cosmeticCartStyle = classNames("bazaar-current-cosmetic-section_listed_modal_content_img", `${rarity}-small`)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => startCreatingOrder(), [isStartCreatingOrder])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => getUpdatedListedOrderAfterUpdate(), [isSuccess, isError])

    const getUpdatedListedOrderAfterUpdate = () => {
        if (isSuccess) {
            setCurrentModal({...currentModal, status: STEPS_STATUS.ORDER_EDIT_SUCCESS})
            dispatch(setData({path1: "currentListedCosmetic", path2: "data", data}))
        }

        if (isError) {
            setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.LISTING_FAIL_UPDATE})
        }
    }

    const updateNow = async () => {
        if (!listPrice || !expires) {
            setFieldsError(ERROR.ALL_FIELDS_REQUIRED)
        } else {
            setFieldsError(ERROR.CLEAN)
            setIsProcess(true)

            if (!isConnectedAddressCorrect) {
                setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.WALLET_CONNECTED_MATCH_INVALID})
                disconnectWallet()

                return
            }

            try {
                const {chainIdHex, chainId} = getChainDataById(chain_id)
                const {status, error} = await switchChain(chainIdHex)

                if (error) errorHandler(ERROR.CLEAN)

                if (!status && error.code === 4902) {
                    const {error} = await addChain(chainId)

                    if (error) errorHandler(ERROR.CLEAN)
                }

                setIsStartCreatingOrder(true)
            } catch (error) {
                setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.WALLET_FAILED})
            }
        }
    }

    const startCreatingOrder = async () => {
        if (isStartCreatingOrder) {
            try {
                const {sellOrder, sellSignature} = await createOrder(chain_id)

                updateListedOrder({
                    order_id,
                    order: sellOrder,
                    signature: sellSignature
                }).unwrap()

            } catch (error) {
                setCurrentModal({status: STEPS_STATUS.REJECT, message: error.message})
            }
        }
    }

    return (
        <React.Fragment>
            {isProcess ?
                <ProcessModal
                    show={true}
                    text1={currentModal.message ?? "Waiting for signature..."}
                    text2="After wallet approval, your transaction will be finished shortly."
                />
                : <ModalWindowTemplate
                    show={true}
                    size="modal-lg"
                    paddingTop={true}
                    isShowClose={true}
                    title="Listing update"
                    changeStep={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
                    className="bazaar-current-cosmetic-section_listed_modal"
                >
                    <div className={contentStyle}>
                        <div className={cosmeticCartStyle}>
                            <Image alt="cosmetic" src={image_url}/>
                        </div>
                        <div className="bazaar-current-cosmetic-section_listed_modal_content_inputs">
                            <div>
                                <h5 className="bazaar-current-cosmetic-section_listed_modal_content_inputs_title">{name}</h5>
                                <div className="bazaar-current-cosmetic-section_listed_modal_content_inputs_rarity">
                                    <React.Fragment>
                                        {React.Children.toArray(
                                            groups.map((item) =>
                                                <Rarity text={item.name} rarity="default"/>
                                            )
                                        )}
                                    </React.Fragment>
                                    <Rarity
                                        text={rarity}
                                        rarity={`${rarity} ${width > 991 ? "small" : "medium"}`}
                                    />
                                </div>
                                <div className="bazaar-current-cosmetic-section_listed_modal_content_inputs_field">
                                    <div
                                        className="bazaar-current-cosmetic-section_listed_modal_content_inputs_price-inp">
                                        <Input
                                            size="md"
                                            name="price"
                                            type="number"
                                            value={listPrice}
                                            placeholder="List Price (KT)"
                                            status={fieldsError ? "error" : "default"}
                                            onChange={(e) => setListPrice(e.target.value)}
                                        />
                                    </div>
                                    <div
                                        className="bazaar-current-cosmetic-section_listed_modal_content_inputs_day-select">
                                        <SelectDropDownDisplay
                                            size="md"
                                            value={expires}
                                            btnText="Expires"
                                            optionsList={LISTING_DAY_OPTIONS}
                                            status={fieldsError ? "error" : "default"}
                                            onChange={(e) => setExpires(e.target.value.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bazaar-current-cosmetic-section_listed_modal_content_btn">
                        <Button
                            size="md"
                            view="secondary"
                            onClick={() => updateNow()}
                        >
                            Update Now
                        </Button>
                        <Button
                            view="danger"
                            onClick={() => setCurrentModal({...currentModal, status: STEPS_STATUS.ORDER_REMOVE,})}
                        >
                            Remove listing
                        </Button>
                    </div>
                    <p className="bazaar-current-cosmetic-section_listed_modal_content_terms">
                        By clicking “Update Now”, you agree to <Link to="/terms">Terms of Service</Link>.
                    </p>
                </ModalWindowTemplate>
            }
        </React.Fragment>

    )
}

export default BazaarCurrentAlreadyListedWeb3CosmeticEditModal