import {png} from "assets/png";

export const TRANSFER_INPUTS_DEFAULT_DATA = {
    quantity: {
        value: "",
        validation: false
    },
    address: {
        value: "",
        validation: false,
    }
}

export const LOCKER_TABS = {
    OWNED: "owned",
    LISTED: "listed",
    ACTIVITY: "activity",
    KONVERT: "konvert"
}

export const tabOptions = [
    {id: LOCKER_TABS.OWNED, name: "Owned Items", icon: png.OwnedItemsTab},
    {id: LOCKER_TABS.LISTED, name: "Listed Items ", icon: png.LimitedTimeTab},
    {id: LOCKER_TABS.ACTIVITY, name: "Activity", icon: png.ActivityTab},
    {id: LOCKER_TABS.KONVERT, name: "Konvert", icon: png.KonvertTab}
]

export const cosmeticFilteringData = {
    page: {value: 1},
    sortBy: {
        data: [
            {name: "Price High To Low", value: "highToLow"},
            {name: "Price Low To High", value: "lowToHigh"},
        ],
        value: null
    },
    limit: {
        data: [
            {name: "12", value: 12},
            {name: "24", value: 24},
            {name: "36", value: 36}
        ],
        value: {name: "12", value: 12},
    },
    searchByNameOrAttr: {value: ""},
    filter: [
        {
            section: "rarity",
            open: true,
            attributes: [
                {id: 0, name: "All", checked: true},
                {id: 1, name: "Ordinary", checked: true},
                {id: 2, name: "Common", checked: true},
                {id: 3, name: "Rare", checked: true},
                {id: 4, name: "Elite", checked: true},
                {id: 5, name: "Legendary", checked: true},
                {id: 6, name: "Divine", checked: true}
            ]
        },
        {
            section: "categories",
            open: false,
            attributes: [
                {id: 0, name: "All", checked: true},
                {id: 1, name: "Outfits", checked: true},
                {id: 2, name: "Tops", checked: true},
                {id: 3, name: "Bottoms", checked: true},
                {id: 4, name: "Feet", checked: true},
                {id: 5, name: "Stickers", checked: true},
                {id: 6, name: "Vehicles", checked: true},
                {id: 7, name: "Weapons", checked: true},
            ]
        }
    ]
}

export const eligibility = [
    {
        name: "ticket",
        text: "Own The Current Seasons’s Ticket",
        eligibility: false
    },
    {
        name: "cosmetic",
        text: "Own the Founding Father’s Outfit",
        eligibility: true
    },
    {
        name: "token",
        text: "Own $500 worth of KOMPETE Token",
        eligibility: false
    },
]

export const OWNED_INVENTORIES_REQUEST_DEFAULT_DATA = {limit: 12, offset: 0, platform: "xsolla"}

export const ORDERS_REQUEST_DEFAULT_DATA = {
    limit: 12,
    offset: 0,
    value: "",
    orderBy: "",
    groups: [],
    rarities: [],
    chains: []
}

export const LISTING_DAY_OPTIONS = [
    {name: "1 Day", value: 1},
    {name: "3 Days", value: 3},
    {name: "7 Days", value: 7},
    {name: "14 Days", value: 14},
    {name: "30 Days", value: 30}
]