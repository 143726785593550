import React from "react";
import VoolahPurchaseSuccessful from "./VoolahPurchaseSuccessful";
import {LoginAlertModal} from "components/molecules";
import VoolahOrderModal from "./VoolahOrderModal";
import {useModalsContext} from "layouts";

const VoolahSteps = ({changeStep, currentPackageIndex}) => {
    const {currentModal, setCurrentModal} = useModalsContext()

    return (
        <React.Fragment>
            {
                {
                    "login":
                        <LoginAlertModal
                            show={true}
                            changeStep={changeStep}
                            closeClick={() => setCurrentModal({status: ""})}
                        />,
                    "order":
                        <VoolahOrderModal
                            show={true}
                            currentPackageIndex={currentPackageIndex}
                            setWhichModalOpen={() => setCurrentModal({status: ""})}
                        />,
                    "success":
                        <VoolahPurchaseSuccessful
                            show={true}
                            currentPackageIndex={currentPackageIndex}
                            setWhichModalOpen={() => setCurrentModal({status: ""})}
                        />
                }[currentModal.status]
            }
        </React.Fragment>
    )
}

export default VoolahSteps