import React from "react"

import Title from "components/atoms/TitleTemplate";
import Button from "components/atoms/Button";
import Image from "components/atoms/Image";

import {png} from "assets/png";
import "./style.scss"

const TokenFour = () => {
    return (
        <section className="token-four">
            <div className="container">
                <Title underline={true}>Where to <span>buy</span></Title>
                <div className="steps">
                    <div className="step-item">
                        <div className="icon">
                            <Image alt="eth" src={png.EthereumIconGold}/>
                        </div>
                        <p>Ethereum</p>
                        <Button
                            view="secondary"
                            onClick={() => window.open("https://app.uniswap.org/swap?outputCurrency=0x1E0b2992079B620AA13A7c2E7c88D2e1E18E46E9", "_blank")}
                        >
                            Buy on eth
                        </Button>
                    </div>
                    <div className="step-item">
                        <div className="icon">
                            <Image alt="fiat" src={png.BaseIconBlue}/>
                        </div>
                        <p>Base</p>
                        <Button
                            view="secondary"
                            onClick={() => window.open(" https://app.uniswap.org/swap?outputCurrency=0x8f019931375454Fe4EE353427eB94E2E0C9e0a8C&chain=base", "_blanck")}
                        >
                            Buy on base
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TokenFour