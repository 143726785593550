import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {reset, userAddWallet, userRecoverPassword} from "store/AuthV2/AuthV2Slice";
import {useConnectedWallet, useConnectWallet} from "hooks";
import {addressReduction, ERROR, SUCCESS} from "utils";
import {useModalsContext} from "layouts";

import ProfileUserInfoBlock from "./ProfileUserInfoBlock";
import ProfileButtonBlock from "./ProfileButtonBlock";
import {Image} from "components/atoms";

import {png} from "assets/png";
import "./style.scss"

const ProfileSetting = () => {
    const dispatch = useDispatch()

    const {isConnected, address, isWalletConnection} = useConnectedWallet()
    const {disconnectWallet, openWalletModal} = useConnectWallet()
    const {setCurrentModal} = useModalsContext()

    const [isUpdatedWallet, setIsUpdatedWallet] = useState(false)
    const [disable, setDisable] = useState(false)

    const {addWallet, signIn, recoverPassword} = useSelector(state => state.authV2)

    const isSocialLogin = localStorage.getItem("login") === "social"
    const {nickname, wallet, email, username, picture} = signIn.userData

    useEffect(() => {
        if (addWallet.success) {
            setCurrentModal({status: "wallet-success", message: SUCCESS.WALLET_UPDATED})
            resetUpdatedWalletData(true)

            setTimeout(() => {
                setCurrentModal({status: "", message: ""})
            }, 2000)
        }

        if (addWallet.error) {
            setCurrentModal({status: "reject", message: ERROR.WALLET_CONNECTED_USED_INVALID})
            resetUpdatedWalletData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addWallet.success, addWallet.error]);

    useEffect(() => {
        if (isConnected) disconnectWallet()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isConnected && !isWalletConnection) {
            dispatch(userAddWallet({wallet: address}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConnected])

    const resetPassword = () => {
        setDisable(true)
        dispatch(userRecoverPassword({username: username}))

        setTimeout(() => {
            setDisable(false)
        }, 60000)
    }

    const resetUpdatedWalletData = (isUpdate) => {
        if (isUpdate) {
            setIsUpdatedWallet(true)
            setTimeout(() => {
                setIsUpdatedWallet(false)
            }, 6000)
        }

        dispatch(reset("addWallet"))
        localStorage.removeItem("sync_date")
        disconnectWallet()
    }

    return (
        <section className="profile">
            <div className="profile_container">
                <h1>Profile Settings</h1>
                <div className="profile_container_avatar">
                    <div className="profile_container_avatar_img">
                        <Image alt="avatar" src={picture ? picture : png.NewAvatar}/>
                    </div>
                    <h4 className="profile_container_avatar_name">{nickname}</h4>
                </div>
                <div className="profile_container_info">
                    <ul>
                        <ProfileUserInfoBlock title="Nickname" info={nickname}/>
                        <ProfileUserInfoBlock title="Email address" info={email}/>
                        <ProfileUserInfoBlock
                            title="Linked wallet"
                            info={wallet ? addressReduction(wallet, 5) : "None"}
                        />
                    </ul>
                </div>
                <div className="profile_container_buttons-block">
                    {!isSocialLogin &&
                        <ProfileButtonBlock
                            title="Change Password"
                            buttonText="Request password"
                            requestInfo={recoverPassword.success ? "Password requested!" : recoverPassword.error ? recoverPassword.errorMessage : ""}
                            isDisable={disable}
                            clickCallBack={resetPassword}
                        />}
                    <ProfileButtonBlock
                        title="Update Wallet"
                        buttonText="Update"
                        requestInfo={isUpdatedWallet ? "Wallet updated successfully!" : ""}
                        clickCallBack={() => openWalletModal()}
                    />
                </div>
            </div>
        </section>
    )
}

export default ProfileSetting