import React from "react";
import {ModalWindowTemplate} from "components/molecules";
import {Button} from "components/atoms";
import "./style.scss"

const LoginAlertModal = ({show, changeStep, closeClick}) => {
    return (
        <ModalWindowTemplate
            show={show}
            paddingTop={true}
            isShowClose={true}
            changeStep={closeClick}
            className="login-alert-modal"
            title="You need to login or create a KOMPETE account to purchase"
        >
            <Button
                onClick={() => {
                    changeStep("step1")
                    closeClick("")
                }}
            >
                Login
            </Button>
            <Button
                view="outline"
                onClick={() => {
                    changeStep("step4")
                    closeClick("")
                }}
            >
                Create Account
            </Button>
        </ModalWindowTemplate>
    )
}
export default LoginAlertModal