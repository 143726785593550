import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getPosts} from "store/NewsPosts/postSlice";

import ArticleCart from "components/atoms/ArticleCart";
import Title from "components/atoms/TitleTemplate";

import "./style.scss"

const PostNews = () => {
    const {posts, success} = useSelector(state => state.posts)
    const dispatch = useDispatch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => dispatch(getPosts({first: 3, skip: 0})), [])

    return (
        <section className="current-news-container_news">
            <Title underline={true}>Latest <span>Posts</span></Title>
            <div className="container">
                {success &&
                    posts?.map((post, i) =>
                        <ArticleCart
                            key={i}
                            date={post.date}
                            text={post.title}
                            img={post.image.url}
                            linkTo={`/news/${post.slug}`}
                        />
                    )
                }
            </div>
        </section>
    )
}

export default PostNews