const formConfig = {
    currentStep: "",
    currentEmail:"",
    steps: {
        step2: {
            fields: {
                email: {validation: false, value: ""},
                password: {validation: false, value: ""}
            }
        },
        step3: {
            fields: {
                email: {validation: false, value: ""},
            }
        },
        step5: {
            fields: {
                email: {validation: false, value: ""},
                password: {validation: false, value: ""},
                checkbox1: {validation: false, value: true},
                checkbox2: {validation: false, value: true},
                checkbox3: {validation: false, value: true},
            }
        },
        step8: {
            fields: {
                checkbox1: {validation: false, value: true},
                checkbox2: {validation: false, value: true},
                checkbox3: {validation: false, value: true},
            }
        }
    }
}

export default formConfig