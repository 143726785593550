import React from "react"

import Title from "components/atoms/TitleTemplate";
import Image from "components/atoms/Image";
import Button from "components/atoms/Button";

import {png} from "assets/png";

const HomeEngine = () => {

    return (
        <section className="home_unreal-engine">
            <Title underline={true}> made on <span>unreal engine 5</span></Title>
            <div className="home_unreal-engine_content container">
                <div className="home_unreal-engine_content_engine-img">
                    <Image alt="unrealEngine" src={png.M11}/>
                </div>
                <div className="home_unreal-engine_content_info">
                    <div className="home_unreal-engine_content_info_left">
                        <h2>Created by <br/><span>Modernize games</span></h2>
                        <p>Dastro is an ex pro-gamer that founded Modernize Games in September 2020 after he became
                            frustrated with the current state of multiplayer gaming. </p>
                        <p>Almost four years later, KOMPETE is cruising through Early Access!</p>
                        <Button view="secondary" onClick={() => window.open("https://modernizegames.com", "_blank")}>
                            Learn more
                        </Button>
                    </div>
                    <div className="home_unreal-engine_content_info_right">
                        <div>
                            <h2>Created by <br/><span>Modernize games</span></h2>
                            <div className="home_unreal-engine_content_info_right_img">
                                <Image alt="modernize" src={png.LogoModernizeGame}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default HomeEngine