import React from "react";

import ProgressiveImage from "components/atoms/ProgressiveImage";
import UnderLine from "components/atoms/UnderLine";
import TokenERC20 from "./TokenERC20";

import {png} from "assets/png";
import "./style.scss"

const TokenHero = () => (
    <section className="token-hero">
        <div className="container">
            <div className="flex">
                <div className="description">
                    <h1>Kompete Token</h1>
                    <UnderLine/>
                    <p>A virtual currency that enables players to trade cosmetics with each other on the Bazaar,
                        gain access to closed testing, and unlock exclusive cosmetics!</p>
                </div>
                <div className="thumb">
                    <ProgressiveImage alt="kompete-token" imgSrc={png.T1Png} previewSrc={png.T1Webp}/>
                </div>
            </div>
            <TokenERC20/>
        </div>
    </section>
)
export default TokenHero