import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";

import CosmeticSuccessModalTemplate from "components/molecules/CosmeticModalTemplate";
import {reset} from "store/Locker/lockerSlice";
import {useModalsContext} from "layouts";
import {STEPS_STATUS} from "utils";

const LockerCurrentCosmeticListingSuccess = ({show, setCurrentModal}) => {
    const {currentModal} = useModalsContext()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {sku, image_url, rarity, name, token_id, order} = currentModal.data

    const navigateToLocker = () => {
        navigate("/locker?tab=owned")
        dispatch(reset({section: "listCurrentCosmetic"}))
    }

    const navigateToCurrentListedCosmeticPage = () => {
        navigate(`/bazaar/inventory/${sku}/${token_id}/${order.id}`)
    }

    useEffect(() => {
        return () => {
            dispatch(reset({section: "listCurrentCosmetic"}))
            setCurrentModal({status: STEPS_STATUS.CLOSE})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <CosmeticSuccessModalTemplate
            show={show}
            name={name}
            quantity="1"
            rarity={rarity}
            image={image_url}
            title="Listing successful!"
            buttonOne={{text: "go to locker", callBack: () => navigateToLocker()}}
            buttonTwo={{text: "return to cosmetic", callBack: () => navigateToCurrentListedCosmeticPage()}}
        />
    )
}

export default LockerCurrentCosmeticListingSuccess