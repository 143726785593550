import {useConnectedWallet, useConnectWallet} from "hooks";
import {ERROR, SUCCESS} from "utils";
import config from "web3/config";

export const useUtils = () => {
    const {providers} = useConnectedWallet()
    const {currentChain} = useConnectWallet()

    const getChainDataById = (chainId) => {
        return config.chains[config.networkEnvironment].find(chain => chain.chainId === chainId);
    }

    const switchChain = async (chainId) => {

        const {walletConnectProvider} = providers()
        try {
            await walletConnectProvider.send("wallet_switchEthereumChain", [
                {chainId: chainId ?? currentChain.chainIdHex}
            ])

            return {status: true, message: SUCCESS.SWITCHED, error: null}
        } catch (error) {
            return {status: false, message: ERROR.WRONG, error}
        }
    }

    const addChain = async (chainId) => {
        const {walletConnectProvider} = providers()
        const chain = chainId ? getChainDataById(chainId) : currentChain

        try {
            await walletConnectProvider.send("wallet_addEthereumChain", [{
                chainId: chain.chainIdHex,
                chainName: chain.network,
                rpcUrls: [chain.rpcUrlPublic],
                nativeCurrency: chain.nativeCurrency,
                blockExplorerUrls: [chain.explorerUrl],
            }])

            return {status: true, message: SUCCESS.ADDED, error: null}
        } catch
            (error) {
            return {status: false, message: ERROR.WRONG, error}
        }
    }

    return {
        getChainDataById,
        switchChain,
        addChain
    }
}