import React from "react";
import classNames from "classnames";

import Title from "components/atoms/TitleTemplate";
import ProgressiveImage from "components/atoms/ProgressiveImage";

import {png} from "assets/png";
import "./style.scss"

const EconomyCollecting = () => {
    return (
        <section className="economy_collecting">
            <Title underline={true}>Collecting <span>Cosmetics</span></Title>
            <ItemBlock
                background="gray"
                className="item-one"
                title="Limited Time Shop"
                orgImage={png.CosmeticCarPng}
                noOrgImage={png.CosmeticCarWebp}
            >
                The Limited Time Shop is your go-to destination to stay drippy on Mahzookoo Island. The shop options
                rotate regularly and may sometimes include items that will never be available again! Time is ticking, so
                don't be a bot – grab some gear.
            </ItemBlock>
            <ItemBlock
                reverse={true}
                background="red"
                title="Divine Shop"
                className="item-two"
                orgImage={png.CosmeticSniperPng}
                noOrgImage={png.CosmeticSniperWebp}
            >
                The Divine Shop is a website-exclusive store that offers cosmetics of the highest rarity: Divine. You'll
                need Ethereum to acquire these unique items on the Mainnet or Base Network. Act quickly when we release
                them because once they're gone, they're gone.
            </ItemBlock>
            <ItemBlock
                title="Fame"
                background="gray"
                className="item-three"
                orgImage={png.CosmeticHippoPng}
                noOrgImage={png.CosmeticHippoWebp}
            >
                FAME is a free progression system that allows players to earn exclusive rewards as they level up each
                season. The early levels are easy, but as you progress further, it becomes extremely challenging. These
                items cannot be purchased.
            </ItemBlock>
            <ItemBlock
                title="Ticket"
                reverse={true}
                background="red"
                className="item-four"
                orgImage={png.CosmeticEmojiPng}
                noOrgImage={png.CosmeticEmojiWebp}
            >
                The Ticket is a paid monthly progression system where players work hard to unlock and earn exclusive
                rewards and bonuses! As you advance, each level becomes progressively more challenging. A new Ticket
                starts on the 1st of each month!
            </ItemBlock>
            <ItemBlock
                title="Bazaar"
                background="gray"
                className="item-five"
                orgImage={png.CosmeticSaharPng}
                noOrgImage={png.CosmeticSaharWebp}
            >
                The Bazaar is a peer-to-peer marketplace that enables players to buy and sell cosmetics with each other.
                Here, cosmetics find their true value. If you want to sell your cosmetics to cash out or acquire items
                you missed out on, this is the place to be! The Bazaar exclusively operates with KOMPETE Token.
            </ItemBlock>
        </section>
    )
}

export default EconomyCollecting

const ItemBlock = ({title, className, reverse, background, children, orgImage, noOrgImage}) => {
    const howItemStyle = classNames("economy_collecting_block_item", "container", className, {reverse: reverse})
    const homeItemParentStyle = classNames("economy_collecting_block", background ?? "")
    const imgBlockStyle = classNames("economy_collecting_block_item_img", background ?? "")

    return (
        <div className={homeItemParentStyle}>
            <div className={howItemStyle}>
                <div className={imgBlockStyle}>
                    <ProgressiveImage alt="hero" imgSrc={orgImage} previewSrc={noOrgImage}/>
                </div>
                <div>
                    <h3>{title}</h3>
                    <p>{children}</p>
                </div>
            </div>
        </div>
    )
}