import React from "react"
import DefaultLayout from "layouts/DefaultLayout";
import HomeLetsKompete from "components/organisms/Home/HomeLetsKompete";
import HomeWelcome from "components/organisms/Home/HomeWelcome";
import HomeWhy from "components/organisms/Home/HomeWhy";
import HomeEngine from "components/organisms/Home/HomeEngine";
import HomeNews from "components/organisms/Home/HomeNews";
import HomeComing from "components/organisms/Home/HomeComing";
import HomeSpacs from "components/organisms/Home/HomeSpacs";
import HomeMain from "components/organisms/Home/HomeMain";
import HomeCurrentGameMode from "components/organisms/Home/HomeCurrentGameMode";
import HomeComingSoon from "components/organisms/Home/HomeComingSoon";
import Seo from "components/atoms/Seo";

const Home = () => {

    return (
        <DefaultLayout>
            <Seo
                title="KOMPETE: Team-Based Multiplayer Game"
                description="KOMPETE is a team-based multiplayer game with Battle Royale, Kart Race, Golf, Basketball & More. Free to play with Crossplay."
            />
            <HomeMain/>
            <HomeLetsKompete/>
            <HomeCurrentGameMode/>
            <HomeComingSoon/>
            <HomeWelcome/>
            <HomeWhy/>
            <HomeEngine/>
            <HomeNews/>
            <HomeComing/>
            <HomeSpacs/>
            <HomeComing isBackground={false}/>
        </DefaultLayout>
    )
}
export default Home