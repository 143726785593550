import {gql} from "graphql-request";

export const getNftTransactionsByAddressQuery = gql`
query getNftTransactionsByAddress($skip: Int,$first: Int, $address: Bytes, $subgraphError: _SubgraphErrorPolicy_! = deny) {
  transferSingles(
    skip: $skip,
    first: $first,
    subgraphError: $subgraphError,
    orderBy: blockTimestamp,
    orderDirection: desc,
    where: {or:[{from:$address},{to:$address}]}
  ) {
    id
    value
    KompeteGameAsset_id
    blockTimestamp
    process
    transactionHash
    metadata
    transaction{
      ordersMatched{
        price
      }
    }
  }
}
`

export const getNftTransactionsByTokenIdQuery = gql`
query getNftTransactionsByTokenId($skip: Int,$first: Int,$tokenId: Int, $subgraphError: _SubgraphErrorPolicy_! = deny) {
  transferSingles(
    skip: $skip,
    first: $first, 
    subgraphError: $subgraphError,
    orderBy: blockTimestamp, 
    orderDirection: desc,
    where:{KompeteGameAsset_id:$tokenId}
  ) {
    id
    value
     KompeteGameAsset_id
     blockTimestamp
     metadata
     transactionHash
     process
     transaction{
      ordersMatched{
        price
      }
    }
  }
}
`

export const getNftsTransactionsQuery = gql`
query getNftsTransactions($skip: Int,$first: Int, $subgraphError: _SubgraphErrorPolicy_! = deny) {
  transferSingles(
    skip: $skip,
    first: $first, 
    subgraphError: $subgraphError,
    orderBy: blockTimestamp, 
    orderDirection: desc,
  ) {
    id
    value
    KompeteGameAsset_id
    blockTimestamp
    metadata
    transactionHash
    process
    transaction{
      ordersMatched{
        price
      }
    }
  }
}
`