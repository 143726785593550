import React from "react"
import {useNavigate} from "react-router-dom";

import ProgressiveImage from "components/atoms/ProgressiveImage";
import Title from "components/atoms/TitleTemplate";
import Button from "components/atoms/Button";
import Image from "components/atoms/Image";

import {png} from "assets/png";
import "./style.scss"

const TokenSix = () => {
    const navigate = useNavigate()

    return (
        <section className="token-six">
            <Image className="c1" alt="coin" src={png.C1}/>
            <Image className="c3" alt="coin" src={png.C3}/>
            <Image className="c4" alt="coin" src={png.C4}/>
            <Image className="c2" alt="coin" src={png.C2}/>
            <div className="container">
                <Title underline={true}>Kompete Token<br/><span>utility</span></Title>
                <div className="content">
                    <div>
                        <h3>Use Kompete token <br/><span> on the bazaar</span></h3>
                        <p>Players can seamlessly buy and sell cosmetics with each other at the Bazaar exclusively using
                            KOMPETE Token.</p>
                        <Button view="secondary" onClick={() => navigate("/token")}>Baazar coming soon!</Button>
                    </div>
                    <div>
                        <ProgressiveImage
                            alt="bazaar"
                            imgSrc={png.BazaarRarityTokenPng}
                            previewSrc={png.BazaarRarityTokenWebp}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TokenSix