import React, {useMemo, useCallback} from 'react';

import {withTooltip, TooltipWithBounds, defaultStyles} from '@visx/tooltip';
import {scaleTime, scaleLinear} from '@visx/scale';
import {AreaClosed, Line, Bar} from '@visx/shape';
import {max, extent, bisector} from 'd3-array';
import {LinearGradient} from '@visx/gradient';
import {curveMonotoneX} from '@visx/curve';
import {localPoint} from '@visx/event';
import {GridRows} from '@visx/grid';

import AxisBottom from "@visx/axis/lib/axis/AxisBottom";
import AxisRight from "@visx/axis/lib/axis/AxisRight";
import Group from "@visx/group/lib/Group";

const background = '#000000';
const redGradientP3 = 'rgba(134, 22, 22, 0.5)';
const WhiteOpacity = 'rgba(255,255,255,0.08)';
const A2500 = '#9c1d22';
const axisColor = '#F8F3E9';

const tooltipStylesDisplay = {
    ...defaultStyles,
    minWidth: 110,
    minHeight: 45,
    background: axisColor,
    color: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
};

const tooltipStylesMobile = {
    ...defaultStyles,
    minWidth: 70,
    minHeight: 30,
    background: A2500,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
};

const axisBottomTickLabelProps = {
    dy: '0.3em',
    textAnchor: 'middle',
    fontFamily: 'Arial',
    fontSize: 10,
    fill: axisColor,
};

const axisLeftTickLabelProps = {
    dx: '1.25em',
    dy: '0.3em',
    textAnchor: 'middle',
    fontFamily: 'Arial',
    fontSize: 10,
    fill: axisColor,
};

export default withTooltip(
    ({
         width,
         height,
         margin,
         showTooltip,
         hideTooltip,
         tooltipData,
         tooltipTop = 0,
         tooltipLeft = 0,
         isAxisRight,
         isAxisBottom,
         data
     }) => {

        // accessors
        const getDate = (d) => new Date(d.date);
        const getStockValue = (d) => d.close;
        const bisectDate = bisector((d) => new Date(d.date)).left;

        if (width < 10) return null;

        // bounds
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        // scales
        const dateScale = useMemo(
            () =>
                scaleTime({
                    range: [margin.left, innerWidth + margin.left],
                    domain: extent(data, getDate),
                }),
            [innerWidth, margin.left, data],
        );

        const stockValueScale = useMemo(
            () =>
                scaleLinear({
                    range: [innerHeight + margin.top, margin.top],
                    domain: [0, (max(data, getStockValue) || 0) + 5],
                    nice: true,
                }),
            [margin.top, innerHeight, data],
        );

        // tooltip handler
        const handleTooltip = useCallback(
            (event) => {
                const {x} = localPoint(event) || {x: 0};
                const x0 = dateScale.invert(x);
                const index = bisectDate(data, x0, 1);
                const d0 = data[index - 1];
                const d1 = data[index];
                let d = d0;
                if (d1 && getDate(d1)) {
                    d = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? d1 : d0;
                }
                showTooltip({
                    tooltipData: d,
                    tooltipLeft: x,
                    tooltipTop: stockValueScale(getStockValue(d)),
                });
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [showTooltip, stockValueScale, dateScale, data]
        )
        const xMax = width - margin.left - margin.right;
        const yMax = height - margin.top - margin.bottom;

        // accessors
        const date = (d) => new Date(d.date).valueOf();

        const timeScale = scaleTime({
            domain: [Math.min(...data.map(date)), Math.max(...data.map(date))],
        });

        timeScale.range([0, xMax]);

        return (
            <div className="price-chart">
                <svg width={width} height={height + 50}>
                    <rect
                        x={0}
                        y={0}
                        rx={14}
                        width={width}
                        height={height}
                        fill="url(#area-background-gradient)"
                    />
                    <Group top={25}>
                        <LinearGradient
                            to={background}
                            from={background}
                            id="area-background-gradient"
                        />
                        <LinearGradient
                            toOpacity={0.1}
                            id="area-gradient"
                            to={redGradientP3}
                            from={redGradientP3}
                        />
                        {isAxisBottom &&
                            <AxisBottom
                                top={yMax}
                                scale={timeScale}
                                tickStroke={axisColor}
                                numTicks={width > 520 ? 10 : 5}
                                tickLabelProps={() => axisBottomTickLabelProps}
                            />}
                        {isAxisRight &&
                            <AxisRight
                                tickStroke={axisColor}
                                scale={stockValueScale.range([yMax, 0])}
                                tickLabelProps={() => axisLeftTickLabelProps}
                            />}
                        <GridRows
                            left={margin.left}
                            scale={stockValueScale}
                            width={innerWidth}
                            stroke={WhiteOpacity}
                            strokeOpacity={1}
                            pointerEvents="none"
                        />
                        <AreaClosed
                            data={data}
                            strokeWidth={1}
                            curve={curveMonotoneX}
                            yScale={stockValueScale}
                            fill="url(#area-gradient)"
                            stroke="url(#area-gradient)"
                            x={(d) => dateScale(getDate(d)) ?? 0}
                            y={(d) => stockValueScale(getStockValue(d)) ?? 0}
                        />
                        <Bar
                            rx={14}
                            y={margin.top}
                            x={margin.left}
                            fill="transparent"
                            width={innerWidth}
                            height={innerHeight}
                            onTouchStart={handleTooltip}
                            onTouchMove={handleTooltip}
                            onMouseMove={handleTooltip}
                            onMouseLeave={() => hideTooltip()}
                        />
                    </Group>
                    {tooltipData && (
                        <g>
                            <Line
                                stroke={A2500}
                                strokeWidth={1}
                                strokeDasharray="0"
                                pointerEvents="none"
                                from={{x: tooltipLeft, y: margin.top}}
                                to={{x: tooltipLeft, y: innerHeight + margin.top + 25}}
                            />
                            <circle
                                fill="black"
                                stroke="black"
                                strokeWidth={4}
                                cx={tooltipLeft}
                                fillOpacity={0.1}
                                strokeOpacity={0.1}
                                cy={tooltipTop + 25}
                                pointerEvents="none"
                                r={width > 991 ? 4 : 2}
                            />
                            <circle
                                fill={A2500}
                                stroke="white"
                                cx={tooltipLeft}
                                pointerEvents="none"
                                cy={tooltipTop + 25}
                                r={width > 991 ? 8 : 4}
                                strokeWidth={width > 991 ? 4 : 2}
                            />
                        </g>
                    )}

                </svg>
                {tooltipData && (
                    <div>
                        <TooltipWithBounds
                            key={Math.random()}
                            top={width > 991 ? tooltipTop - 10 : tooltipTop}
                            left={width > 991 ? tooltipLeft + 2 : tooltipLeft}
                            style={width > 991 ? tooltipStylesDisplay : tooltipStylesMobile}
                        >
                            {`${getStockValue(tooltipData)} K`}
                        </TooltipWithBounds>
                    </div>
                )}
            </div>
        );
    },
);