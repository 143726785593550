import React ,{memo} from "react";
import Button from "components/atoms/Button";

const ProfileButtonBlock = ({requestInfo, clickCallBack, isDisable = false, buttonText, title}) => {
    return (
        <div className="profile_container_buttons-block_item">
            <div className="profile_container_buttons-block_item_head">
                <span>{title}</span>
                <span className="request-info">{requestInfo}</span>
            </div>
            <Button
                view="white"
                onClick={clickCallBack}
                disabled={isDisable}
            >
                {buttonText}
            </Button>
            <span className="request-info outside">{requestInfo}</span>
        </div>
    )
}

export default memo(ProfileButtonBlock)