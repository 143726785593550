import React from "react";

import {LoginAlertModal, ProcessModal, RejectModal, SuccessModal} from "components/molecules";
import DivinePurchaseSuccessfulModal from "./DivinePurchaseSuccessfulModal";
import DivineWeb2BrowserAlertModal from "./DivineWeb2BrowserAlertModal";
import DivineOrderModal from "./DivineOrderModal";

const DivineSteps = ({whichModalOpen, setWhichModalOpen, quantity, changeStep}) => {
    return (
        <React.Fragment>
            {
                {
                    "order":
                        <DivineOrderModal
                            show={whichModalOpen.status === "order"}
                            setWhichModalOpen={setWhichModalOpen}
                            quantity={quantity}
                        />,
                    "reject":
                        <RejectModal
                            show={whichModalOpen.status === "reject"}
                            text1="Oooops!"
                            text2={whichModalOpen?.message}
                            isShowClose={true}
                            closeClick={setWhichModalOpen}
                        />,
                    "process":
                        <ProcessModal
                            show={whichModalOpen.status === "process"}
                            text1="Waiting for signature..."
                            text2="After wallet approval, your transaction will be finished shortly."
                        />,
                    "success":
                        <DivinePurchaseSuccessfulModal
                            show={whichModalOpen.status === "success"}
                            setWhichModalOpen={setWhichModalOpen}
                            quantity={quantity}
                        />,
                    "login":
                        <LoginAlertModal
                            show={true}
                            changeStep={changeStep}
                            closeClick={() => setWhichModalOpen({status: ""})}
                        />,
                    "wallet-success":
                        <SuccessModal
                            show={true}
                            text1="Success!"
                            text2={whichModalOpen?.message}
                            isShowClose={false}
                        />,
                    "web2-browser":
                        <DivineWeb2BrowserAlertModal
                            show={true}
                            changeStep={() => setWhichModalOpen({status: ""})}
                        />
                }[whichModalOpen.status]
            }
        </React.Fragment>
    )
}
export default DivineSteps