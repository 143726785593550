import {formatUnits, parseUnits} from "ethers/lib/utils";
import {ZERO_BYTES32} from "@modernize-games/nft-sdk";
import {useSelector} from "react-redux";
import {ethers} from "ethers";

import {ERROR, errorHandler, walletAndTransactionErrorHandle} from "utils";
import {useSendTransactionHashMutation} from "store/Bazaar/bazaar.api";
import {createOrderWithSignature, web3Utils} from "web3/utils";
import {useConnectedWallet} from "hooks";

export const useBazaar = () => {
    const {address, providers} = useConnectedWallet()
    const {walletConnectSigner: signer} = providers()
    const {contractAddress} = useSelector(state => state.web3.currentChain)
    const [sendTransactionHash] = useSendTransactionHashMutation()

    const createOrder = async (price, tokenId, chainId) => {
        const feePercent = 2
        price = price.toString()
        const {marketplace, paymentToken} = await web3Utils.getConfiguration(feePercent, chainId)
        const decimals = await paymentToken?.decimals() ?? 10

        try {
            let allowance = await paymentToken.connect(signer).allowance(address, contractAddress.marketplace)

            const approveAmount = parseUnits('10000000000000000000', decimals)
            const hexDefaultValue = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

            if (allowance._hex === hexDefaultValue || allowance._hex === "0x00") {
                const approve = await paymentToken.connect(signer).approve(contractAddress.marketplace, approveAmount)
                await approve.wait()
            }

            allowance = await paymentToken.connect(signer).allowance(address, contractAddress.marketplace)
            const formatAllowance = formatUnits(allowance, decimals)

            if ((Number(formatAllowance) <= Number(price))) {
                const approve = await paymentToken.connect(signer).approve(contractAddress.marketplace, approveAmount)
                await approve.wait()

                if (formatAllowance <= price) {
                    errorHandler(ERROR.ALLOWANCE_INVALID)
                }
            }

            const {order: buyOrder, signature: buySignature} = await createOrderWithSignature({
                chainId,
                signer,
                token: "kompete",
                price,
                tokenId,
                amount: 1,
                account: address,
                orderSide: "Buy",
                feePercent,
                admin: false
            })

            if (!(await marketplace.validateOrderParameters_(buyOrder)))
                errorHandler(ERROR.INVALID_BUY_ORDER_PARAMS)
            if (!(await marketplace.validateOrder_(buyOrder, buySignature)))
                errorHandler(ERROR.INVALID_BUY_ORDER)


            return {buyOrder, buySignature}

        } catch (error) {
            walletAndTransactionErrorHandle(error)
        }
    }

    const sendTransaction = async (sellOrder, sellSignature, buyOrder, buySignature, chainId, orderId) => {
        const feePercent = 2
        const {marketplace} = await web3Utils.getConfiguration(feePercent, chainId)

        try {
            if (!(await marketplace.ordersCanMatch_(buyOrder, sellOrder))) {
                if (await marketplace.ordersCanMatch_(buyOrder, sellOrder)) {
                    errorHandler(ERROR.INVALID_MATCH)
                }
            }

            let gasLimit

            try {
                gasLimit = (
                    await marketplace.estimateGas.atomicMatch_(
                        buyOrder,
                        buySignature,
                        sellOrder,
                        sellSignature,
                        ZERO_BYTES32,
                    )
                )
                    .mul(110)
                    .div(100)

            } catch {
                gasLimit = ethers.BigNumber.from("400000")
            }

            try {
                const tx = await marketplace
                    .connect(signer)
                    .atomicMatch_(
                        buyOrder,
                        buySignature,
                        sellOrder,
                        sellSignature,
                        ZERO_BYTES32,
                        {
                            gasLimit,
                        },
                    )

                hashCallBack(tx, orderId)

                return tx.wait().then((result) => result)
            } catch (error) {
                walletAndTransactionErrorHandle(error)
            }

        } catch (error) {
            errorHandler(error.message)
        }
    }

    function hashCallBack(transactionDetails, orderId) {
        sendTransactionHash({transaction_hash: transactionDetails.hash, order_id: orderId})
    }

    return {
        createOrder,
        sendTransaction
    }
}