export const useLocalStorage = () => {
    const removeToken = () => localStorage.removeItem("accessToken")
    const removeSyncDate = () => localStorage.removeItem("sync_date")
    const removeLoginType = () => localStorage.removeItem("login")

    return {
        removeToken,
        removeSyncDate,
        removeLoginType
    }
}