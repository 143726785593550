import {CHAIN_ID} from "./enum";

export const LIMIT_DEFAULT_DATA = {value: 12, name: "12"}

export const STANDARD_LIMIT_OPTIONS = [
    {name: "12", value: 12},
    {name: "24", value: 24},
    {name: "36", value: 36}
]

export const TABLE_HEADER = [
    {displayName: "Name", value: "name", className: "table-name"},
    {displayName: "Transaction Id", value: "transactionHash"},
    {displayName: "Transaction Type", value: "process", className: "table-type"},
    {displayName: "Time", value: "blockTimestamp", className: "table-time"},
    {displayName: "Qty", value: "value", className: "table-type"},
    {displayName: "Total Amount", value: "price", className: "table-price"},
]

export const TRANSACTION_HISTORY_SECTION = {
    USER_ACTIVITY: "user-activity",
    INVENTORY_ACTIVITY: "inventory-activity",
    BAZAAR_ACTIVITY: "bazaar-activity"
}

export const SORT_OPTIONS = [
    {name: "Price High To Low", value: "ASC"},
    {name: "Price Low To High", value: "DESC"},
]

export const SORT_OPTIONS_V2 = [
    ...SORT_OPTIONS,
    {name: "Recently Listed", value: "LAST_LISTED"},
    {name: "Ending Soon", value: "EXPIRES_SOON"}
]

export const INVENTORY_FILTRATION_DATA = {
    page: {value: 1},
    orderBy: {
        data: SORT_OPTIONS,
        value: null
    },
    limit: {
        data: STANDARD_LIMIT_OPTIONS,
        value: STANDARD_LIMIT_OPTIONS[0],
    },
    filter: [
        {
            name: "chains",
            filedName: "chains",
            open: false,
            attributes: [
                {name: "Base", id: CHAIN_ID.BASE_MAINNET.toString(), checked: false},
                {name: "Ethereum", id: CHAIN_ID.ETH_MAINNET.toString(), checked: false},
            ]
        },
        {
            name: "rarities",
            filedName: "rarities",
            open: false,
            attributes: [
                {name: "Common", id: 1, checked: false},
                {name: "Divine", id: 2, checked: false},
                {name: "Rare", id: 3, checked: false},
                {name: "Legendary", id: 4, checked: false},
                {name: "Elite", id: 5, checked: false},
                {name: "Ordinary", id: 6, checked: false},
            ]
        },
        {
            name: "categories",
            filedName: "groups",
            open: true,
            attributes: [
                {name: "Outfits", id: "Outfit", checked: false},
                {name: "Tops", id: "Tops", checked: false},
                {name: "Bottoms", id: "Bottoms", checked: false},
                {name: "Feet", id: "Feet", checked: false},
                {name: "Stickers", id: "Stickers", checked: false},
                {name: "Vehicles", id: "Vehicles", checked: false},
                {name: "Weapons", id: "Weapons", checked: false},
                {name: "Utility", id: "Utility", checked: false},
                {name: "Player Cards", id: "Player_Card", checked: false},
                {name: "Player Titles", id: "Player_Title", checked: false},
                {name: "Backgrounds", id: "Avatar_Background", checked: false},
                {name: "Throwables", id: "Throwables", checked: false}
            ]
        }
    ]
}

export const CHART_PERIODS = ["1M", "3M", "6M", "YTD", "ALL"]

export const CHART_DEFAULT_DATA = [{date: "", close: 0}]