import React from "react";

import ProgressiveImage from "components/atoms/ProgressiveImage";
import Title from "components/atoms/TitleTemplate";
import UnderLine from "components/atoms/UnderLine";
import Button from "components/atoms/Button";

import {png} from "assets/png";
import "./style.scss"

const HowToBuyTokenThree = ({changeStep}) => (
    <section className="how-to-buy-token_three">
        <div className="container">
            <Title underline={true}>Success!</Title>
            <div className="how-to-buy-token_three_success">
                <div className="how-to-buy-token_three_success_block-two">
                    <ProgressiveImage alt="token" imgSrc={png.T16Png} previewSrc={png.T16Webp}/>
                </div>
                <div className="how-to-buy-token_three_success_block-one">
                    <h2 className="how-to-buy-token_three_success_block-one_title">
                        Make sure your<br/>
                        <span> tokens Are Accessible!</span>
                    </h2>
                    <UnderLine/>
                    <p className="how-to-buy-token_three_success_block-one_text">
                        To use your KOMPETE Tokens, make sure they are added to the wallet associated with your account.
                        Add a wallet through the Profile section of your KOMPETE account while logged in on our website.
                    </p>
                    <Button onClick={() => changeStep("step1")} view="secondary">Login to website</Button>
                </div>
            </div>
        </div>
    </section>
)

export default HowToBuyTokenThree