import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom"
import React, {useEffect} from "react"

import {ERROR, formatNumberToEuropeanStyle, walletAndTransactionErrorHandle} from "utils";
import {createNFTBuyOrder} from "store/DivineShop/divineShopSlice";
import {useConnectedWallet, useConnectWallet} from "hooks";
import useDivineShop from "../useDivineShop";

import ModalWindowTemplate from "components/molecules/ModalWindowTemplate";
import PaymentMethod from "components/molecules/PaymentMethodTemplate";
import {Button, Rarity, Image} from "components/atoms";

import "../style.scss"

const DivineOrderModal = ({show, setWhichModalOpen, quantity}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {disconnectWallet, openWalletModal} = useConnectWallet()
    const {
        isConnected,
        address,
        getEthBalance,
        providers,
        isConnectedAddressCorrect,
        isConnectedChainCorrect
    } = useConnectedWallet()

    const {currentChain} = useSelector(state => state.web3)
    const {ethBalance, wallet} = useSelector(state => state.authV2.signIn.userData)
    const {data} = useSelector(state => state.divineShop.currentCosmetic)

    const currentInventoryEthPrice = (quantity * (data?.price * 100000)) / 100000
    const formattedEthBalance = ethBalance ? Number(formatNumberToEuropeanStyle(ethBalance)).toFixed(5) : 0
    const isEthBalanceCorrect = Number(ethBalance) >= currentInventoryEthPrice
    const {walletConnectSigner} = providers()

    useEffect(() => {
        if (!ethBalance) getEthBalance(address)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {createSellOrder, createBuyOrder, sendTransaction} = useDivineShop({
        chainId: currentChain.chainId,
        feePercent: 90,
        price: currentInventoryEthPrice.toString(),
        signer: walletConnectSigner,
        quantity,
        tokenId: data.token_id,
        account: wallet
    })

    const mintInventory = async () => {
        if (!isConnectedChainCorrect) {
            setWhichModalOpen({status: "reject", message: ERROR.INVALID_CHAIN})
            disconnectWallet()
            return
        }

        if (!isConnectedAddressCorrect) {
            setWhichModalOpen({status: "reject", message: ERROR.WALLET_CONNECTED_MATCH_INVALID})
            disconnectWallet()
            return
        }

        setWhichModalOpen({status: "process"})

        try {
            const {sellOrder, sellSignature} = await createSellOrder()

            const {buyOrder, buySignature} = await createBuyOrder()

            setTimeout(async () => {
                try {
                    const result = await sendTransaction(sellOrder, sellSignature, buyOrder, buySignature, hashCallBack)
                    if (!result.status)
                        throw new Error(ERROR.TRANSACTION_FAILED)

                    setWhichModalOpen({status: "success"})
                    getEthBalance(address)
                } catch (error) {
                    const {message} = error
                    let errorMessage = message.length > 100 ? walletAndTransactionErrorHandle(error, false) : message
                    setWhichModalOpen({status: "reject", message: errorMessage})
                }
            }, 4000)
        } catch (error) {
            setWhichModalOpen({status: "reject", message: error.message})
        }
    }

    const methodDetails = {
        eth: {
            text: isConnected ? isEthBalanceCorrect ? "Pay with eth" : "Buy eth" : "Connect wallet",
            onClick: () =>
                isConnected
                    ? isEthBalanceCorrect
                        ? mintInventory()
                        : navigate("/buy-kompete-token", {state: {process: "order-more-eth"}})
                    : (setWhichModalOpen({status: ""}), openWalletModal()),
            error: isConnected ? isEthBalanceCorrect ? "" : "Insufficient ETH balance.Please purchase more ETH!" : "",
            totalPrice: `${currentInventoryEthPrice} ETH`
        }
    }

    function hashCallBack(transactionDetails) {
        dispatch(createNFTBuyOrder({sku: data?.sku, transaction: transactionDetails.hash, quantity: quantity}))
    }

    return (
        <ModalWindowTemplate
            show={show}
            powered={true}
            size="modal-lg"
            isShowClose={true}
            title="Order Summary"
            className="shop-divine_order-summery"
            changeStep={() => setWhichModalOpen("")}
        >
            <div className="shop-divine_order-summery_content">
                <div className="shop-divine_order-summery_content_info">
                    <div className="shop-divine_order-summery_content_info_img divine-small">
                        <Image alt="cosmetic" src={data?.image_url}/>
                    </div>
                    <div className="shop-divine_order-summery_content_info_details">
                        <div className="shop-divine_order-summery_content_info_details_title">
                            <h6>{data?.name}</h6>
                            <Rarity
                                size="md"
                                text={data?.rarity}
                                rarity={data?.rarity}
                            />
                        </div>
                        <div className="shop-divine_order-summery_content_info_details_qantity">
                            <p>X {quantity}</p>
                        </div>
                    </div>
                </div>
                <div className="shop-divine_order-summery_content_payments-methods">
                    <div className="shop-divine_order-summery_content_payments-methods_head">
                        <span className="shop-divine_order-summery_content_payments-methods_head_quantity">
                            Available payment methods (1)
                        </span>
                        {!!methodDetails.eth.error &&
                            <span className="shop-divine_order-summery_content_payments-methods_head_error">
                             {methodDetails.eth.error}
                            </span>}
                    </div>
                    <PaymentMethod
                        method="eth"
                        checked={true}
                        disable={true}
                        balance={formattedEthBalance}
                        insufficient={!!methodDetails.eth.error}
                    />
                </div>
                <div className="shop-divine_order-summery_content_total">
                    <div>Total</div>
                    <div>{methodDetails.eth.totalPrice}</div>
                </div>
                {!!methodDetails.eth.error &&
                    <div className="shop-divine_order-summery_content_mobile-error">
                        {methodDetails.eth.error}
                    </div>}
                <div className="shop-divine_order-summery_content_buttons">
                    <Button
                        view="secondary"
                        onClick={() => methodDetails.eth.onClick()}
                    >
                        {methodDetails.eth.text}
                    </Button>
                </div>
            </div>
        </ModalWindowTemplate>
    )
}

export default DivineOrderModal