import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import classNames from "classnames";

import {useAuthorization, useConnectedWallet, useConnectWallet, useUtils} from "hooks";
import {ERROR, formatNumberToEuropeanStyle, scrollToTop, SUCCESS} from "utils";
import {reset, userAddWallet} from "store/AuthV2/AuthV2Slice";
import {useModalsContext} from "layouts";

import LimitedTimeCurrentCosmeticEthPrice from "./LimitedTimeCurrentCosmeticEthPrice";
import LimitedTimeCurrentCosmeticSteps from "./LimitedTimeCurrentCosmeticSteps";
import CosmeticViewTemplate from "components/molecules/CosmeticViewTemplate";
import {Button, Image, QuantityCalculator} from "components/atoms";
import {CountDownWithContainer} from "components/molecules";

import {png} from "assets/png";
import {svg} from "assets/svg";
import "./style.scss"

const LimitedTimeCurrentCosmeticMain = ({changeStep, shopData}) => {
    const dispatch = useDispatch()

    const {switchChain} = useUtils()
    const {isAuth} = useAuthorization()
    const {setCurrentModal} = useModalsContext()
    const {disconnectWallet} = useConnectWallet()
    const {address} = useConnectedWallet()
    const {isConnected, isWalletConnection, isConnectedAddressCorrect} = useConnectedWallet()

    const {signIn, addWallet} = useSelector(state => state.authV2)
    const {ethPrice} = useSelector(state => state.global)
    const [quantityValue, setQuantityValue] = useState(1)

    const {inventory, expiration_date} = shopData

    const currentCosmeticStyle = classNames("limited-time-current-cosmetic_main", inventory.rarity)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => checkWalletConnectionAndAddressMatch(), [isConnected]);

    useEffect(() => {
        scrollToTop()
        if (addWallet.success) {
            setCurrentModal({status: "wallet-success", message: SUCCESS.WALLET_CONNECTED})
            localStorage.setItem("wallet-connection", "CONNECT_SUCCESS")
            dispatch(reset("addWallet"))

            setTimeout(() => {
                setCurrentModal({status: "", message: ""})
            }, 2000)
        }

        if (addWallet.error) {
            dispatch(reset("addWallet"))
            setCurrentModal({status: "reject", message: ERROR.WALLET_CONNECTED_USED_INVALID})
            disconnectWallet()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addWallet.success, addWallet.error])

    const checkWalletConnectionAndAddressMatch = async () => {
        if (isConnected && !isWalletConnection) {
            setCurrentModal({status: "process"})
            const {status} = await switchChain()

            if (!status) {
                setCurrentModal({status: ""})
                disconnectWallet()
            } else {
                const isRegisteredWallet = signIn.userData?.wallet

                if (isRegisteredWallet) {
                    if (!isConnectedAddressCorrect) {
                        setCurrentModal({status: "reject", message: ERROR.WALLET_CONNECTED_MATCH_INVALID})

                        disconnectWallet()
                    } else {
                        setCurrentModal({status: "wallet-success", message: SUCCESS.WALLET_CONNECTED})
                        localStorage.setItem("wallet-connection", "CONNECT_SUCCESS")

                        setTimeout(() => {
                            setCurrentModal({status: "", message: ""})
                        }, 2000)
                    }
                } else {
                    dispatch(userAddWallet({wallet: address}))
                }
            }
        }
    }

    const openOrderModal = () => {
        const oneVoolahPrice = 0.01 //USD
        const cosmeticEthPrice =
            (((quantityValue * (inventory.voolah_price * oneVoolahPrice)) / ethPrice) * 1000000) / 1000000

        setCurrentModal({status: "order", data: {...inventory, ethPrice: cosmeticEthPrice}})
    }

    return (
        <section className={currentCosmeticStyle}>
            <CosmeticViewTemplate
                loading={false}
                title={inventory.name}
                rarity={inventory.rarity}
                img={inventory.image_url}
                attribute={[{name: inventory.sub_category}]}
                classname="limited-time-current-cosmetic_main_content container"
            >
                <div className="limited-time-current-cosmetic_main_content_deadline">
                    <CountDownWithContainer
                        day={false}
                        success={true}
                        loading={false}
                        date={expiration_date}
                        text={inventory.never_sold ? "This item will never be sold again!" : "This item expires in:"}
                    />
                </div>
                <div className="limited-time-current-cosmetic_main_content_price-info">
                    <div className="limited-time-current-cosmetic_main_content_price-info_price">
                        <span className="block-title">
                            Price for {quantityValue}
                        </span>
                        <div className="limited-time-current-cosmetic_main_content_price-info_price_content">
                            <div className="limited-time-current-cosmetic_main_content_price-info_price_content_voolah">
                                <Image src={png.VoolahCoin} alt="voolah"/>
                                <span>{formatNumberToEuropeanStyle(quantityValue * Number(inventory?.voolah_price))}</span>
                            </div>
                            <div className="limited-time-current-cosmetic_main_content_price-info_price_content_text">
                                Or
                            </div>
                            <div className="limited-time-current-cosmetic_main_content_price-info_price_content_eth">
                                <Image src={svg.EtheriumGold} alt="Ethereum"/>
                                <LimitedTimeCurrentCosmeticEthPrice
                                    quantity={quantityValue}
                                    voolahPrice={inventory.voolah_price}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="limited-time-current-cosmetic_main_content_price-info_quantity">
                        <span className="block-title">
                            Quantity
                        </span>
                        <QuantityCalculator value={quantityValue} setValue={setQuantityValue} limit={5}/>
                    </div>
                </div>
                <Button
                    onClick={() =>
                        isAuth
                            ? openOrderModal()
                            : setCurrentModal({status: "login"})
                    }
                    disabled={!ethPrice}
                >
                    Buy Now
                </Button>
                <div className="limited-time-current-cosmetic_main_content_description">
                    <span className="block-title">Description</span>
                    <p>{inventory?.description}</p>
                </div>
            </CosmeticViewTemplate>
            <LimitedTimeCurrentCosmeticSteps
                quantity={quantityValue}
                changeStep={changeStep}
            />
        </section>
    )
}

export default LimitedTimeCurrentCosmeticMain