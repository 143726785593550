import React from "react"

import ModalWindowTemplate from "components/molecules/ModalWindowTemplate";
import {Button} from "components/atoms";

import "../style.scss"

const DivineWeb2BrowserAlertModal = ({show, changeStep}) => (
    <ModalWindowTemplate
        show={show}
        powered={true}
        privacy={true}
        paddingTop={true}
        isShowClose={true}
        changeStep={changeStep}
        className="shop-divine_web2-browser"
        title={<span>Can't connect on <br/> web2 browsers</span>}
    >
        <p>Please use Metamask or Trust Wallet app browsers.</p>
        <Button view="outline" onClick={changeStep}>Back to divine shop</Button>
    </ModalWindowTemplate>
)

export default DivineWeb2BrowserAlertModal

