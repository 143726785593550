import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useConnectedWallet, useConnectWallet} from "hooks";
import {userAddWallet, reset} from "store/AuthV2/AuthV2Slice";

import ModalWindowTemplate from "components/molecules/ModalWindowTemplate";
import {Button} from "components/atoms";

import "../style.scss"

const StepIsConnectWallet = ({show, changeStep}) => {
    const dispatch = useDispatch()

    const {isConnected, address} = useConnectedWallet()
    const {disconnectWallet, openWalletModal} = useConnectWallet()

    const {addWallet} = useSelector(state => state.authV2)

    useEffect(() => {
        if (isConnected && !localStorage.getItem("wallet-connection")) {
            dispatch(userAddWallet({wallet: address}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConnected])

    useEffect(() => {
        if (addWallet.success) {
            dispatch(reset("addWallet"))
            changeStep("step14")
            disconnectWallet(true)
        }

        if (addWallet.error) {
            dispatch(reset("addWallet"))
            changeStep("step9")
            disconnectWallet(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addWallet.success, addWallet.error])

    return (
        <React.Fragment>
            <ModalWindowTemplate
                show={show}
                powered={true}
                privacy={true}
                className="auth-step_step6"
                isShowClose={false}
                changeStep={changeStep}
                title={<span>Want to connect a wallet to your KOMPETE Account?</span>}
            >
                <p className="auth-step_step6_text">You don’t need this to play and you can connect it later in your
                    profile.</p>
                <div className="auth-step_step6_buttons">
                    <Button view="secondary" onClick={() => openWalletModal()}>Yes</Button>
                    <Button view="opacity" onClick={() => changeStep("step14")}>No</Button>
                </div>
            </ModalWindowTemplate>
        </React.Fragment>
    )
}

export default StepIsConnectWallet