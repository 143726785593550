import React, {useRef} from "react";

import {BAZAAR_TABS, BAZAAR_TABS_OPTION} from "utils";

import {TabContent, TabDynamicBlock} from "components/molecules";
import BazaarListedCosmetics from "./BazaarListedCosmetics";
import BazaarActivity from "./BazaarActivity";

import "./style.scss"

const BazaarCosmetics = ({activeTab, changeTab}) => {
    const bazaarTabRef = useRef(null)

    return (
        <section className="bazaar_cosmetics">
            <div className="bazaar_cosmetics_tabs" ref={bazaarTabRef}>
                <TabDynamicBlock
                    activeTab={activeTab}
                    isSetUrlParams={false}
                    setActiveTab={changeTab}
                    options={BAZAAR_TABS_OPTION}
                />
            </div>
            <div className="bazaar_cosmetics_content">
                <div className="bazaar_cosmetics_content_general">
                    <TabContent id={BAZAAR_TABS.ITEMS} activeTab={activeTab}>
                        <BazaarListedCosmetics/>
                    </TabContent>
                    <TabContent id={BAZAAR_TABS.ACTIVITY} activeTab={activeTab}>
                        <BazaarActivity/>
                    </TabContent>
                </div>
            </div>
        </section>
    )
}

export default BazaarCosmetics