import React from "react"
import classNames from "classnames";

import Image from "components/atoms/Image";
import UnderLine from "components/atoms/UnderLine";

import {png} from "assets/png";
import "./style.scss"

const HomeComing = ({isBackground = true}) => {
    const homeComingStyle = classNames("home_coming", {"background-image": isBackground})

    return (
        <section className={homeComingStyle}>
            <div className="home_coming_content container">
                <div className="home_coming_content_block">
                    <h4>Targeted release <br/> Platforms</h4>
                    <UnderLine/>
                    <Image alt="coming" src={png.ComingBrand}/>
                </div>
            </div>
        </section>
    )
}

export default HomeComing