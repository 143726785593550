import React from "react"

import SocialButtons from "../ReusableComponents/SocialButtons";
import ModalWindowTemplate from "components/molecules/ModalWindowTemplate";
import ChangeStepButtonTemplate from "../ReusableComponents/ChangeStepButtonTemplate";

import "../style.scss"

const StepSignIn = ({show, changeStep}) => {

    return (
        <ModalWindowTemplate
            show={show}
            powered={true}
            isShowClose={true}
            privacy={true}
            paddingTop={true}
            changeStep={changeStep}
            title={<span>Login or create <br/> a KOMPETE account</span>}
        >
            <SocialButtons changeStep={changeStep} text="login"/>
            <ChangeStepButtonTemplate type="create" changeStep={changeStep}/>
        </ModalWindowTemplate>
    )
}

export default StepSignIn

