import React, {memo} from "react";

import {useConnectedWallet, useConnectWallet, useCountdownTimer} from "hooks";
import {useModalsContext} from "layouts";
import {STEPS_STATUS} from "utils";

import BazaarCurrentCosmeticInfo from "./BazaarCurrentCosmeticInfo";
import {Button, Status} from "components/atoms";

const BazaarCurrentCosmeticOwnerBlock = ({inventory, data, isSuccess, isLoading}) => {
    const {isConnected} = useConnectedWallet()
    const {openWalletModal} = useConnectWallet()
    const {setCurrentModal} = useModalsContext()

    return (
        <React.Fragment>
            <div className="bazaar-current-cosmetic-section_listed">
                <div className="bazaar-current-cosmetic-section_listed_status">
                    <Status>Already listed</Status>
                    <CountDownBlock expirationDate={data?.expiration_date}/>
                </div>
                <Button
                    view="secondary"
                    onClick={() => {
                        isConnected
                            ? setCurrentModal({status: STEPS_STATUS.ORDER_EDIT, data: inventory})
                            : openWalletModal()
                    }}
                >
                    {isConnected ? "Edit listing" : "Connect wallet"}
                </Button>
            </div>
            <BazaarCurrentCosmeticInfo
                price={data?.price}
                isSuccess={isSuccess}
                isLoading={isLoading}
                description={inventory?.description}
            />
        </React.Fragment>
    )
}

export default memo(BazaarCurrentCosmeticOwnerBlock)

const CountDownBlock = ({expirationDate}) => {
    const {countdown} = useCountdownTimer(expirationDate ?? 0, true)

    if (!countdown.days) return null

    return (<Status type="reject">
        <span>Expires in</span>
        {` ${countdown.days} days (${countdown.hours}H: ${countdown.minutes}M: ${countdown.seconds}s)`}
    </Status>)
}