import React from "react";
import classNames from "classnames";
import {useCountdownTimer} from "hooks";

const LimitedTimeCountDown = ({targetDateTime, isLoading, isSuccess}) => {
    const {countdown} = useCountdownTimer(targetDateTime,true)
    const timeStyle = classNames("shop-limited_content_countDown", {"skeleton": isLoading})

    return (
        <div className={timeStyle}>
            <div className="shop-limited_content_countDown_block">
                {!isLoading &&
                    <React.Fragment>
                        {isSuccess ?
                            <h4 className="shop-limited_content_countDown_block_open">
                                <div>
                                    <span> Time left: </span>
                                    <span className="time"> {countdown?.days} </span>
                                    <span> days,</span>
                                </div>
                                <div>
                                    <span className="time"> {countdown?.hours} </span>
                                    <span> hours,</span>
                                </div>
                                <div>
                                    <span className="time"> {countdown?.minutes} </span>
                                    <span> minutes,</span>
                                </div>
                                <div>
                                    <span className="time"> {countdown.seconds} </span>
                                    <span> seconds</span>
                                </div>
                            </h4>
                            : <h4 className="shop-limited_content_countDown_block_close">
                                The next Limited time item will be released soon! Check back later.
                            </h4>}
                    </React.Fragment>}
            </div>
        </div>
    )
}

export default LimitedTimeCountDown