import React from "react"
import ModalWindow from "components/atoms/ModalWindow";
import {svg} from "assets/svg";
import "./style.scss"

const RejectModal = ({show, text1, text2, closeClick, isShowClose = true}) => {

    return (
        <ModalWindow show={show} size="modal-lg" closeClick={closeClick} isShowClose={isShowClose}>
            <div className="reject-block">
                <div className="reject-block_content">
                    <div className="reject-block_content_image">
                        <img alt="reject" src={svg.Reject}/>
                    </div>
                    {
                        text1 &&
                        <p className="reject-block_content_text1">{text1}</p>
                    }
                    {
                        text2 &&
                        <p className="reject-block_content_text2">{text2}</p>
                    }
                </div>
            </div>
        </ModalWindow>
    )
}

export default RejectModal