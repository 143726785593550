import {useDispatch, useSelector} from "react-redux";
import React, {useState, useEffect} from "react";
import classNames from "classnames";

import {useAuthorization, useConnectedWallet, useConnectWallet, useDetectMobile, useUtils} from "hooks";
import {getCurrentDivineNFT} from "store/DivineShop/divineShopSlice";
import {reset, userAddWallet} from "store/AuthV2/AuthV2Slice";
import {ERROR, SUCCESS} from "utils";

import CountDownWithContainer from "components/molecules/Countdown/CountDownWithContainer/CountDownWithContainer";
import CosmeticViewTemplate from "components/molecules/CosmeticViewTemplate";
import QuantityCalculator from "components/atoms/QuantityCalculator";
import {SkeletonText, Button, Image} from "components/atoms";
import DivineSteps from "./modals/DivineSteps";

import {svg} from "assets/svg";
import {png} from "assets/png";
import "./style.scss"

const DivineShop = ({changeStep}) => {
    const {loading, success, data} = useSelector(state => state.divineShop.currentCosmetic)
    const {signIn, addWallet} = useSelector(state => state.authV2)

    const [whichModalOpen, setWhichModalOpen] = useState({status: "", message: ""})
    const [quantityValue, setQuantityValue] = useState(1)

    const {isConnected, address, isWalletConnection, isConnectedAddressCorrect} = useConnectedWallet()
    const {openWalletModal, disconnectWallet} = useConnectWallet()
    const {isMobile} = useDetectMobile()
    const dispatch = useDispatch()
    const {isAuth} = useAuthorization()
    const {switchChain} = useUtils()

    const divineShopStyle = classNames("shop-divine", {"divine": true})
    const isProviderAvailable = typeof window.ethereum !== 'undefined' || typeof window.web3 !== 'undefined'

    useEffect(() => {
        if (!data) dispatch(getCurrentDivineNFT())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dispatch])

    useEffect(() => {
        checkWalletConnectionAndAddressMatch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConnected]);

    useEffect(() => {
        if (addWallet.success) {
            setWhichModalOpen({status: "wallet-success", message: SUCCESS.WALLET_CONNECTED})
            localStorage.setItem("wallet-connection", "CONNECT_SUCCESS")
            dispatch(reset("addWallet"))

            setTimeout(() => {
                setWhichModalOpen({status: "", message: ""})
            }, 2000)
        }

        if (addWallet.error) {
            dispatch(reset("addWallet"))
            setWhichModalOpen({status: "reject", message: ERROR.WALLET_CONNECTED_USED_INVALID})
            disconnectWallet()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addWallet.success, addWallet.error])

    const checkWalletConnectionAndAddressMatch = async () => {
        if (isConnected && !isWalletConnection) {
            setWhichModalOpen({status: "process"})
            const {status} = await switchChain()

            if (!status) {
                setWhichModalOpen({status: ""})
                disconnectWallet()
            } else {
                const isRegisteredWallet = signIn.userData?.wallet

                if (isRegisteredWallet) {
                    if (!isConnectedAddressCorrect) {
                        setWhichModalOpen({status: "reject", message: ERROR.WALLET_CONNECTED_MATCH_INVALID})

                        disconnectWallet()
                    } else {
                        setWhichModalOpen({status: "wallet-success", message: SUCCESS.WALLET_CONNECTED})
                        localStorage.setItem("wallet-connection", "CONNECT_SUCCESS")

                        setTimeout(() => {
                            setWhichModalOpen({status: "", message: ""})
                        }, 2000)
                    }
                } else {
                    dispatch(userAddWallet({wallet: address}))
                }
            }
        }
    }

    const buttonDetails = {
        onClick: () =>
            isAuth
                ? isConnected
                    ? setWhichModalOpen({status: "order"})
                    : isMobile ? isProviderAvailable ? openWalletModal() : setWhichModalOpen({status: "web2-browser"}) : openWalletModal()
                : setWhichModalOpen({status: "login"}),
        text: isAuth ? isConnected ? "Buy Now" : "Connect Wallet" : "Sign In"
    }

    return (
        <section className={divineShopStyle}>
            <CosmeticViewTemplate
                rarity="divine"
                loading={loading}
                classname="shop-divine_content"
                attribute={success ? [{name:data?.groups[0]}] : [{name: "????"}]}
                img={loading ? "" : success ? data?.image_url : png.RedQuestionMarkLarge}
                title={loading ? "" : success ? data?.name : <span className="interrogative-color">???????????</span>}
            >
                <div className="shop-divine_content_deadline">
                    <CountDownWithContainer
                        day={true}
                        loading={loading}
                        success={success}
                        text="This item will never be sold again!"
                        date={loading ? "" : success ? data?.expiration_date : false}
                    />
                </div>
                <div className="shop-divine_content_price-info">
                    <div className="shop-divine_content_price-info_price">
                        <span className="shop-divine_content_price-info_price_title">Price for {quantityValue}</span>
                        <div className="shop-divine_content_price-info_price_content">
                            <Image src={svg.EtheriumGold} alt="Ethereum"/>
                            <span className={loading ? "skeleton" : ""}>
                                {loading ? "" : success ?
                                    data?.price && (quantityValue * (data?.price * 100000)) / 100000
                                    : <span className="interrogative-color">???????????</span>}
                            </span>
                        </div>
                    </div>
                    {loading ? "" : success ?
                        <div className="shop-divine_content_price-info_quantity">
                            <span className="shop-divine_content_price-info_quantity_title">Quantity</span>
                            <QuantityCalculator value={quantityValue} setValue={setQuantityValue} limit={5}/>
                        </div> : ""
                    }
                </div>
                {loading ? "" : success ? <React.Fragment>
                        <Button onClick={() => buttonDetails.onClick()}>{buttonDetails.text}</Button>
                    </React.Fragment>
                    : ""
                }
                <div className="shop-divine_content_description">
                    <span className="shop-divine_content_description_title">Description</span>
                    {loading
                        ? <SkeletonText textLineCount={5}/>
                        : success
                            ? <p>{data?.description}</p>
                            : <p>The next Divine item will be released soon! Check back later.</p>}
                </div>
            </CosmeticViewTemplate>
            <DivineSteps
                changeStep={changeStep}
                quantity={quantityValue}
                whichModalOpen={whichModalOpen}
                setWhichModalOpen={setWhichModalOpen}
            />
        </section>
    )
}
export default DivineShop