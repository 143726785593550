import React, {useEffect} from "react";
import {ModalWindowTemplate} from "components/molecules";
import {Button, Image} from "components/atoms";

import {voolahPackageDetails} from "utils";
import {png} from "assets/png";

const VoolahPurchaseSuccessful = ({show, currentPackageIndex, setWhichModalOpen}) => {

    const valueOfPurchase = voolahPackageDetails[currentPackageIndex].value

    useEffect(() => {
        window._paq.push(['trackEvent', "Voolah Purchase", `Amount ${valueOfPurchase}`, 'Purchase'])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ModalWindowTemplate
            show={show}
            size="modal-lg"
            isShowClose={false}
            title="Purchase successful!"
            className="shop-voolah_purchase-successull"
        >
            <div className="shop-voolah_purchase-successful_content">
                <p className="shop-voolah_purchase-successful_content_message">
                    You will be charged by XSOLLA
                </p>
                <div className="shop-voolah_purchase-successful_content_info">
                    <div className="shop-voolah_purchase-successful_content_info_img">
                        <Image alt="cosmetic" src={voolahPackageDetails[currentPackageIndex].src.small}/>
                    </div>
                    <div className="shop-voolah_purchase-successful_content_info_details">
                        <div className="shop-voolah_purchase-successful_content_info_details_title">
                            <Image alt="voolah" src={png.VoolahCoin}/>
                            <h5>{valueOfPurchase} Voolah</h5>
                        </div>
                    </div>
                </div>
                <Button onClick={() => setWhichModalOpen({status: ""})}>Go to shop</Button>
            </div>
        </ModalWindowTemplate>
    )
}

export default VoolahPurchaseSuccessful