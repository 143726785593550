import React from "react";

import DefaultLayout from "layouts/DefaultLayout";
import PageMain from "components/atoms/PageMain";
import WebsiteRightsContent from "../PrivacyPolicy/WebsiteRightsContent";
import Seo from "components/atoms/Seo";

import {png} from "assets/png";
import "./styles.scss"

export const TermsConditions = () => {

    return (
        <DefaultLayout>
            <Seo
                title="Terms - KOMPETE"
                description=""
            />
            <PageMain
                page="termsConditionDesktop"
                originalImage={png.HeroTerms}
                resizeImage={png.HeroTermsResized}
                title="TERMS"
                text={<>Here’s what you’re agreeing to by accessing <br/> our site and playing KOMPETE</>}
                filter={true}
            />
            <WebsiteRightsContent>
                <h3>Terms of Service</h3>
                <p>Last Revised on July 12, 2024</p>
                <p>Welcome to the Terms of Service (these “<b>Terms</b>”) for the website
                    <a href="https://kompete.game" target="_blank" rel="noreferrer"> https://kompete.game</a> (the
                    “<b>Website</b>”),
                    the KOMPETE game (the “<b>Game</b>”), and all other applications, content, smart contracts, tools,
                    features, and functionality offered on or through our Website and the Game (collectively with the
                    Website and the Game, the “<b>Services</b>”), in each case operated by or on behalf of Modernize
                    Marketing
                    LLC (“<b>Company</b>,” “<b>we</b>,” or “<b>us</b>”).
                </p>
                <p>
                    These Terms govern your access to and use of the Services. Please read these Terms carefully, as
                    they include important information about your legal rights. By accessing and/or using the Services,
                    you are agreeing to these Terms. If you do not understand or agree to these Terms, please do not use
                    the Services.
                </p>
                <p>
                    For purposes of these Terms, “<b>you</b>,” “<b>your</b>” and “<b>User(s)</b>” means you as the user
                    of the Services. If you use the Services on behalf of a company or other entity then “you” includes
                    you and that entity, and you represent and warrant that (a) you are an authorized representative of
                    the entity with the authority to bind the entity to these Terms, and (b) you agree to these Terms on
                    the entity’s behalf.
                </p>
                <p>
                    Section 9 contains an arbitration clause and class action waiver. By agreeing to these Terms, you
                    agree (a) to resolve all disputes with us related to the Services through binding individual
                    arbitration (unless expressly provided otherwise in Section 9), which means that you waive any right
                    to have those disputes decided by a judge or jury, and (b) to waive your right to participate in
                    class actions, class arbitrations, or representative actions in connection with your use of the
                    Services.
                </p>
                <h5>1. SERVICES; WALLETS; ACCOUNTS</h5>
                <ol className="list-latin">
                    <li>
                        <u>Description of the Services.</u>The Services offer a unique sports simulation gaming
                        experience via the Game and other related experiences through the combination of full character
                        customization, multiple game modes, and the integration of virtual currency (“<b>Voolah</b>”)
                        used to purchase in-game cosmetics and assets, as provided under Section 1.c. below. You may
                        access the Game wherever the Company makes it available, including but not limited to a Private
                        Launcher or any third-party platforms such as the Epic Games Store, Steam, iOS App Store, Google
                        Play Store, Xbox and Playstation. You are solely responsible for complying with the terms of use
                        and end user license agreement of any third-party platform or provider.
                    </li>
                    <li>
                        <u>Account.</u> Your unique account created by you (“<b>Account</b>”) gives you access to the
                        Services and functionality that we may establish and maintain from time to time and in our sole
                        discretion. You may never use another user’s Account without permission. When creating your
                        Account, you must provide accurate and complete profile information, and you just keep this
                        information up to date. You are solely responsible for the activity that occurs on your Account,
                        and you must keep your password secure. We encourage you to use “strong” passwords (passwords
                        that use a combination of upper and lowercase letters, numbers and symbols) with your Account.
                        You must notify us immediately of any breach of security or unauthorized use of your Account.
                        We will not be liable for any losses caused by any unauthorized use of your Account. By
                        providing us with your email address, phone number, or other contact information, you consent
                        to our using such information to send you service-related notices, including any notices
                        required by law, in lieu of communication by postal mail. We may also use your email address to
                        send you other promotional messages, such as changes to features of our Site and special offers.
                        If you do not want to receive such email messages, you may opt out or change your preferences by
                        contacting support at <a href="mailto:legal@modernizegames.com">legal@modernizegames.com</a>.
                        Opting out may prevent you from receiving email messages regarding updates, improvements, or
                        offers; however, opting out will not prevent you from receiving service-related notices. You
                        acknowledge that you do not own the Account you use to access our Site. Notwithstanding anything
                        in these Terms to the contrary, you agree that we have the absolute right to manage, regulate,
                        control, modify and/or eliminate any data stored by us or on our behalf on our (including by our
                        third party hosting providers’) servers as we see fit in our sole discretion, in any general or
                        specific case and that we have no liability to you based on our exercise of such right. All data
                        on our servers are subject to deletion, alteration, or transfer. Notwithstanding any value
                        attributed to such data by you or any third party, you understand and agree that any data,
                        Account history and Account content residing on our servers, may be deleted, altered, moved or
                        transferred at any time for any reason in our discretion, with or without notice and with no
                        liability of any kind. We do not provide or guarantee, and expressly disclaim, any value, cash
                        or otherwise, attributed to any data residing on our servers. By connecting to our Site with a
                        third-party service, you give us permission to access and use your information from that service
                        as permitted by that service, and to store your log-in credentials for that service.
                    </li>
                    <li>
                        <u>Game Assets</u>. The Services provide a marketplace (the “<b>KOMPETE Game Shops</b>”) for you
                        to purchase Game assets, such as vehicles, weapon wraps, basketballs, clothes, accessories,
                        character emotes (dances), and other assets (“<b>Game Assets</b>”). You may also receive Game
                        Assets by playing the Game and earning Game experience points (“<b>Game XP</b>”) for certain
                        matches played. Game Assets may be rewarded upon achieving milestone levels of Game XP. Your
                        purchase of certain in-Game passes will also unlock your availability to earn additional Game
                        Assets with your Game XP. Game Assets can be used in the Services in various ways that we make
                        available, such as to represent in-Game assets that you can use while you play the Game. We make
                        no promises or guarantees regarding the functionality, utility, or value of any Game Assets, and
                        such Game Assets may evolve and change as we update and modify the Game and the Services.
                    </li>
                    <li>
                        <u>Purchases.</u> The Services provides two methods for Users to make purchases of Game Assets:
                        <ol className="list-roman pad">
                            <li>
                                <u>Fiat-based Purchases.</u> Voolah is the official virtual currency of the Game and can
                                be used to acquire Game Assets. All Game Assets purchased with Voolah or earned via
                                KOMPETE gameplay may be subject to a hold period of up to seven (7) days (“Hold
                                Period"), during which time the Game Assets may be used within the Game, but will not be
                                eligible for KONVERT (as defined under Section 1.g.). Company may make Voolah available
                                for purchase through the Services, the Website or through third-party providers (i.e.
                                Xsolla). Voolah does not have any value in real currency, which means they are not a
                                substitute for real currency and does not earn interest. Only those over the age of 18
                                may purchase Voolah or engage in other real-money transactions through the Services. To
                                purchase Voolah, you may be required to disclose personal information, including but not
                                limited to your name, date of birth, address, and identification number (“Personal
                                Data”). You understand and acknowledge that the Company may be required to deliver such
                                Personal Data and other information to U.S. regulatory authorities upon request. [WL1]
                                When you purchase Voolah, you receive a limited, non-refundable, non-transferable
                                (except as specifically set out in these Terms), revocable license to use Voolah only
                                for your personal entertainment in connection with the Services. Your license to use
                                Voolah will end when these Terms or your Account terminates, or in any other way
                                outlined in these Terms. We may put limits on Voolah (e.g. how much a User can acquire)
                                or do things that change the perceived value of Voolah (e.g. lowering the cost to buy
                                them) at any time. Except for the limited licenses granted in these Terms, Company has
                                and retains all rights in Voolah. This includes the right to modify, revoke, or
                                terminates a User’s license to use Voolah without notice, payment, or liability to User.
                                Company does not make any guarantees regarding Voolah, their availability, quality or
                                value.
                            </li>
                            <li>
                                <u>Token Purchases</u>. Tokens including Ethereum and the KOMPETE Token serve as the
                                Ethereum-based native currencies for the web3-based game shops and secondary
                                marketplace. These tokens can be used to purchase non-fungible token Game Assets
                                (“<b>NFT Game Assets</b>”). Tokens including Ethereum and KOMPETE Tokens are available
                                for purchase on the Website, and may be made available for purchase and trade on
                                centralized exchanges, such as Binance or Kucoin. Users must use a third-party
                                cryptocurrency wallet, such as MetaMask or Coinbase Wallet, to hold, store, and make
                                available for use with KOMPETE Website services, assets including KOMPETE Tokens,
                                Ethereum, and NFT Game Assets. You may be required to share Personal Data in order to
                                use such features. Your use of any third-party services will be subject to the policies,
                                terms of use, and fees of the third-parties. You acknowledge and agree that the Company
                                is not liable or responsible in any way for any damages or losses incurred by any person
                                in connection with your purchase of KOMPETE Tokens and NFT Game Assets. You acknowledge
                                and understand that KOMPETE Tokens and Game Assets may not have or retain any value,
                                use, or purpose outside of the Game. Further details on the Game and KOMPETE Tokens are
                                set forth in the whitepaper, currently available at &nbsp;
                                <a rel="noreferrer"
                                   href="https://modernize-games.gitbook.io/kompete-whitepaper/kompete-game-economy/kompete-token">
                                    https://modernize-games.gitbook.io/kompete-whitepaper/kompete-game-economy/kompete-token
                                </a>, as we may update from time to time.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <u>NFT Game Assets.</u> Your purchase of Game Assets through the Website or the KOMPETE Game
                        Shop (as described below) with KOMPETE Tokens or Ethereum will be accompanied by non-fungible
                        tokens (“<b>NFTs</b>”), and will be delivered as NFTs into your integrated cryptocurrency
                        wallet. Your ownership of these NFT Game Assets are tracked via the Ethereum or Base blockchain.
                        Your ownership of these NFT Game Assets will give you certain rights and privileges to use the
                        NFT Game Assets within the Services, for as long as we make the Services and such rights and
                        privileges available to you. Your ownership of an NFT Game Asset grants you the right to use,
                        display and sell the NFT Game Asset within the Services, and does <b>not</b> convey to you any
                        other rights, including any underlying intellectual property rights in the NFT Game Assets,
                        including but not limited to any copyright, design patent, trademark rights, and the rights to
                        create any derivative works. You are responsible for all payments, fees, and costs when engaging
                        in any transactions involving NFT Game Assets, including, without limitation, the purchase
                        price, transaction fees, fees associated with your secondary or subsequent sales of the NFT
                        Game Assets, and all other fees associated with your use of the NFT Game Assets via the
                        Services.
                    </li>
                    <li>
                        <u>KOMPETE Game Shop and Bazaar Marketplace.</u> We provide the KOMPETE Game Shop for users to
                        purchase and sell Game Assets (including NFT Game Assets) and other Services-related offerings
                        (collectively, “<b>Offerings</b>”) to you directly from us. The KOMPETE Bazaar is provided to
                        facilitate peer-to-peer sales conducted via decentralized or third-party exchanges or protocols
                        whereby buyers and sellers can buy, sell, and trade Game Assets with each other. We may charge
                        a transaction fee on all peer-to-peer sales that occur via the KOMPETE Bazaar (which may be
                        calculated as a percentage of the overall sale price of the Game Assets). We may offer ways to
                        receive a discount on such transaction fees. The scope of, and pre-requisitions for receiving
                        any, such discount offers are subject to change by us in our sole discretion at any time. To
                        facilitate transactions via the KOMPETE Game Shop and Bazaar, we have integrated with one or
                        more third-party exchanges and services. We do not own or control these third-party or
                        decentralized services, including but not limited to services such as Opensea, LooksRare or the
                        Ethereum or Base blockchains. We do not control the transfer of cryptocurrency using these
                        third-party services or decentralized protocols, and we do not have the ability to cancel or
                        reverse certain transactions via the KOMPETE Game Shop or Bazaar Marketplace. Except as may
                        otherwise be provided in these Terms, we do not control or endorse purchases or sales of Game
                        Assets, including NFT Game Assets or other Offerings, outside of the Services, including the
                        KOMPETE Game Shop or Bazaar Marketplace. With respect to Game Assets that are not sold directly
                        by us, we are not a party to any agreement entered into between an end user buyer and a seller
                        on the KOMPETE Bazaar Marketplace. Other than with respect to items sold directly by us, we have
                        no control over the conduct of buyers, sellers, and any other users of the KOMPETE Game Shop or
                        Bazaar Marketplace. We accept no liability for any aspect of the buyer and seller peer-to-peer
                        interaction on the KOMPETE Bazaar Marketplace.
                    </li>
                    <li>
                        <u>Conversion to NFT Game Assets.</u> Users using Voolah to purchase Game Assets receive a web2
                        version of the Game Assets. All Game Assets purchased with Voolah are subject to the HOld
                        Period, as defined above in 1(d)(i). After the hold period, Users may, at Users’ discretion,
                        convert the web2 version of the Game Assets into NFT Game Assets through a Company provided
                        conversion process, otherwise known as KONVERT(“KONVERT”). Game assets that are converted into
                        NFT Game Assets through KONVERT may not be reversed and cannot be converted back to the web2
                        version of the Game Assets. The Company is not responsible for any failed transactions, network
                        transaction fees, or the loss thereof, incurred while utilizing KONVERT. Users may be required
                        to share Personal Data and must be over the age of 18 to use KONVERT. In order to be eligible to
                        use KONVERT, you must satisfy the minimum requirements, as expressed on The Website at the time
                        of using KONVERT. The minimum requirements are subject to change without notice:

                        <ol className="list-roman pad">
                            <li>
                                Own a minimum number of KOMPETE Tokens, in the amount indicated on the KOMPETE
                                Website at the time of use of KONVERT, in your connected wallet, to be confirmed at
                                the time you attempt to use KONVERT.
                            </li>
                            <li>Own the ‘Founding Father’ Game Asset in your connected wallet.</li>
                            <li>Own the current month’s Ticket.</li>
                        </ol>
                    </li>
                    <li>
                        <u>Transaction</u>. If you wish to purchase Voolah, KOMPETE Tokens, or make any other purchases
                        offered through our Services or any other authorized third-party provider
                        (the “<b>Transaction</b>”), you are required to pay the applicable fees. Unless otherwise
                        indicated, all fees and other charges are in U.S. dollars, and all payments shall be in U.S.
                        currency. You agree to provide current, complete, and accurate purchase and Account information
                        for all Transactions. You may be asked to supply certain relevant information, such as your
                        credit card number and its expiration date, your billing address and your shipping information.
                        You represent and warrant that you have the right to use any credit card that you submit in
                        connection with a Transaction and that the associated information entered is accurate. By
                        submitting such information, you grant to us the right to process Transactions, or to provide
                        such information to third-parties for purposes of facilitating Transactions. Company may use
                        Xsolla (“<b>Xsolla</b>”) as a third-party provider for the processing of the Transactions.
                        Xsolla is a global distributor of in-game items, keys, and inconvertible VC, but shall not be
                        responsible for the distribution, tracking or recording of any NFT Game Assets. Verification of
                        information may be required prior to the acknowledgment or completion of any Transaction. You
                        agree to pay all charges incurred by you or on your behalf through the Services, at the prices
                        in effect when such charges are incurred. In addition, you are responsible for any taxes
                        applicable to your Transactions. Your right to use the Service is subject to any expenditure
                        limits established by The Company or by your credit card issuer. If payment cannot be charged
                        to your credit card or your charge is returned for any reason, including chargeback, Company
                        reserves the right to either suspend or terminate your access and purchase. If a charge is
                        declined or reversed by the credit card issuer or network, you agree to pay Company a service
                        charge and to reimburse Company for all reasonable costs of collection. Your credit card issuer
                        may also assess its customary charge for such transactions.
                    </li>
                    <li>
                        <u>Wallet.</u> To make purchases offered on the Web3 Game, you need to link to the Services via
                        a non-custodial digital wallet made available by a third party and that is compatible with the
                        Ethereum or Base networks and the Services (a “<b>Digital Wallet</b>”). A list of supported
                        wallets will be displayed by us, such as on the Services website. Linking a Digital Wallet to
                        the Services is required to be able to purchase, store, and engage in transactions using the
                        Services, and to connect your NFT Game Assets and KOMPETE tokens with the Services. Assets
                        stored in your Digital Wallet are owned and controlled solely by you. Digital Wallets are not
                        associated with, maintained by, controlled by, or affiliated with us. We may not be able to help
                        you recover assets stored in your Digital Wallet, as these are non-custodial wallets in your
                        control. We accept no responsibility or liability to you in connection with your use of a
                        Digital Wallet and make no representations or warranties regarding how the Services will operate
                        with any specific Digital Wallet. The private keys, credentials, passwords, seed phrases, or
                        secret recovery passphrases necessary to decrypt or gain access to a Digital Wallet are held
                        solely by you, and not by us. If you choose to transfer items from one Digital Wallet to
                        another, such transfers will occur on the public blockchain and we accept no liability or
                        responsibility for any such transfer, including for any loss, data corruption, or other negative
                        impact that may occur to your Offerings or Digital Wallets or other assets when attempting to
                        transfer assets between Digital Wallets. Users may be required to share Personal Data and must
                        be over the age of 18 to integrate a Digital Wallet to the Services. We reserve the right, with
                        or without prior notice and in our sole and complete discretion, to discontinue, modify, or
                        limit the operation of any Digital Wallet with the Services, and further, reserves the right to
                        terminate or otherwise penalize accounts which are found to violate the age requirement or other
                        Terms. You represent and warrant that you are the lawful owner of any Digital Wallet and that
                        you are using that Digital Wallet under the terms and conditions of the applicable provider of
                        the Digital Wallet. If you have any issues with your Digital Wallet, please contact your Digital
                        Wallet provider.
                    </li>
                    <li>
                        <u>Promotional Codes.</u> We may offer certain promotional codes, referral codes, discount
                        codes, coupon codes, or similar offers (“Promotional Codes”) that may be redeemed for discounts
                        on future Offerings, or other features or benefits related to the Services, subject to any
                        additional terms that the Company establishes. You agree that Promotional Codes: (a) must be
                        used in a lawful manner; (b) must be used for the intended audience and purpose; (c) may not be
                        duplicated, sold, or transferred in any manner, or made available by you to the general public
                        (whether posted to a public forum, coupon collecting service, or otherwise), unless expressly
                        permitted by the Company; (d) may be disabled or have additional conditions applied to them by
                        the Company at any time for any reason without liability to the Company; (e) may only be used
                        pursuant to the specific terms that the Company establishes for such Promotional Code; (f) are
                        not valid for cash or other credits or points; and (g) may expire prior to your use.
                    </li>
                    <li>
                        <u>Tournaments.</u> The Company may host tournaments of the Game from time to time. Your
                        participation in any tournament is subject to your agreement to these terms, the KOMPETE Code
                        of Conduct, and the official Tournament Rules as expressed by The Company. Participation in
                        Tournaments may make you eligible to receive rewards, including Voolah and KOMPETE Token, to be
                        distributed by the Company. Any rewards made available in KOMPETE Token from the Company will
                        only be distributed to users with an integrated Digital Wallet. Users shall be informed in the
                        Tournament Rules or subsequent communications of any scheduled deadlines for integrating their
                        Digital Wallet in regards to a particular tournament rewards distribution, as possible. The
                        Company reserves the right to withhold rewards to users who do not integrate a Digital Wallet
                        to their account by the scheduled deadline, or for any other cause the Company deems reasonable,
                        including but not limited to violations of the minimum age requirements relating to the
                        operation of a Digital Wallet with the Services.
                    </li>
                    <li>
                        <u>Changes and Pricing.</u> We may, at any time, revise or change the pricing, availability,
                        specifications, content, descriptions, or features of any Offerings sold by us, and to correct
                        pricing errors that may inadvertently occur (and to cancel any orders in our sole discretion
                        that were purchased with pricing errors). All such changes shall be effective immediately upon
                        posting of such new Offerings prices to the Services. While we attempt to be as accurate as we
                        can in our descriptions for the Offerings, we do not warrant that Offerings descriptions are
                        accurate, complete, reliable, current, or error-free. The inclusion of any Offerings for
                        purchase through the Services at a particular time does not imply or warrant that the Offerings
                        will be available at any other time. We reserve the right to change prices for Offerings
                        displayed on the Services at any time.
                    </li>
                    <li>
                        <u>Purchases Made for Consumptive Use.</u> You acknowledge and affirm that you are acquiring
                        Offerings, whether through the Marketplace or otherwise through the Services, for purposes of
                        use in connection with the Services and, in the case of the Game Assets, including NFT Game
                        Assets, to play the Game or for other collectible purposes, and in each case not for any
                        investment or speculative purposes. Any economic benefit that may be derived from appreciation
                        in the value of an Offering or NFT Game Asset is incidental to obtaining it for its use in
                        connection with the Services or its collectible purpose.
                    </li>
                    <li>
                        <u>ALL SALES FINAL.</u> ALL PURCHASES MADE THROUGH THE SERVICES ARE FINAL AND NON-REFUNDABLE,
                        INCLUDING PURCHASED GAME ASSETS SUBSEQUENTLY CONVERTED INTO NFT GAME ASSETS, EXCEPT AS REQUIRED
                        BY APPLICABLE LAW OR REGULATION (AND IN SUCH INSTANCES WHERE REQUIRED BY APPLICABLE LAW, THE
                        COMPANY’S ABILITY TO ISSUE ANY SUCH REFUND IS LIMITED TO WHETHER THE APPLICABLE SMART CONTRACT
                        ENABLES THE COMPANY TO REASONABLY REVERSE OR CANCEL A TRANSACTION). NEVERTHELESS, YOU
                        ACKNOWLEDGE THAT THE COMPANY DOES NOT IN MOST CASES HAVE THE ABILITY TO REVERSE OR CANCEL A
                        TRANSACTION AND IS NOT REQUIRED OR OBLIGATED TO PROVIDE A REFUND OF ANY PURCHASE FOR ANY REASON,
                        AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR TIME, ITEMS, AMOUNTS, OR VALUES IN
                        A GAME ACCOUNT (INCLUDING ANY UNUSED OR PARTIAL USED MEMBERSHIP TIME), EVEN IF AN ACCOUNT IS
                        CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY.
                    </li>
                </ol>

                <h5>2. PRIVACY POLICY AND OTHER TERMS</h5>
                <ol className="list-latin">
                    <li>
                        <u>Privacy Policy.</u> Our Privacy Policy describes how we handle the information you provide to
                        us when you use the Services. For an explanation of our privacy practices, please visit our
                        Privacy Policy located at
                        <a href="https://KOMPETE.game/privacy" target="_blank"
                           rel="noreferrer"> https://KOMPETE.game/privacy</a>.
                    </li>
                    <li>
                        <u>Other Terms.</u> In connection with your use of the Services, you also agree to abide by the
                        following other Services-related policies:
                        <ul className="list-none">
                            <li>Discord: <a href="https://discord.com/terms" target="_blank"
                                            rel="noreferrer">https://discord.com/terms</a></li>
                            <li>Telegram: <a href="https://telegram.org/tos" target="_blank"
                                             rel="noreferrer">https://telegram.org/tos</a></li>
                            <li>Xsolla: <a href="https://xsolla.com/terms-of-service" target="_blank"
                                           rel="noreferrer">https://xsolla.com/terms-of-service</a></li>
                            <li>DMarket: <a href="https://dmarket.com/terms-of-use" target="_blank"
                                            rel="noreferrer">https://dmarket.com/terms-of-use</a></li>
                            <li>Epic: <a href="https://www.epicgames.com/site/en-US/tos" target="_blank"
                                         rel="noreferrer">https://www.epicgames.com/site/en-US/tos</a></li>
                            <li>Steam: <a href="https://store.steampowered.com/eula/471710_eula_0" target="_blank"
                                          rel="noreferrer">https://store.steampowered.com/eula/471710_eula_0</a></li>
                            <li>iOS: <a href="https://www.apple.com/legal/sla/" target="_blank"
                                        rel="noreferrer">https://www.apple.com/legal/sla/</a></li>
                            <li>Android: <a href="https://play.google.com/about/play-terms.html" target="_blank"
                                            rel="noreferrer">https://play.google.com/about/play-terms.html</a></li>
                            <li>PlayStation: <a href="https://www.playstation.com/en-us/legal/psn-terms-of-service/"
                                                target="_blank"
                                                rel="noreferrer">https://www.playstation.com/en-us/legal/psn-terms-of-service/</a>
                            </li>
                            <li>Xbox: <a href="https://www.microsoft.com/en-us/servicesagreement" target="_blank"
                                         rel="noreferrer">https://www.microsoft.com/en-us/servicesagreement</a></li>
                        </ul>
                    </li>
                </ol>

                <h5>3. ELIGIBILITY; RIGHTS WE GRANT YOU; USAGE LIMITATIONS</h5>

                <ol className="list-latin">
                    <li>
                        <u>Eligibility.</u> You must be 13 years of age or older to use the Services. Additional aspects
                        of the Services may have further age restrictions that will be presented to you in connection
                        with those Services. Minors under the age of majority in their jurisdiction but that are at
                        least 13 years of age are only permitted to use the Services if the minor’s parent or guardian
                        or other holder of parental authority accepts these Terms on the minor’s behalf prior to use of
                        the Services. Children under the age of 13 are not permitted to use the Services. You cannot use
                        the Services if (1) the Office of Foreign Assets Control of the United States Treasury
                        Department lists you as a specially designated national and/or blocked person; (2) the Bureau of
                        Industry and Security of the United States Department of Commerce lists you on its denied
                        persons list or lists of parties of concern; or (3) you’re on any similar list promulgated by an
                        official agency or department of the United States government. Further, if you use the Services,
                        you may not transfer or provide to us any currency, digital assets, or other items that have
                        been derived from any illegal or unlawful activity. By using the Services, you represent and
                        warrant that you meet these all requirements.
                    </li>
                    <li>
                        <u>License Grant.</u>
                        <ol className="list-roman pad">
                            <li>
                                Subject to your compliance with these Terms, the Company hereby grants to you, a
                                personal, worldwide, royalty-free, non-assignable, non-sublicensable, non-transferrable,
                                and non-exclusive license to access and use the software provided to you as part of the
                                Services (and, to the extent we offer you any software for download as part of the
                                Services, to download, install, and execute a single copy of such software onto the
                                equipment or device specified by us). This license has the sole purpose of enabling you
                                to use and enjoy the benefit of the Services as provided by us, in the manner permitted
                                by these Terms, and subject to the use restrictions described below. Your access and use
                                of the Services may be interrupted from time to time for any of several reasons,
                                including, without limitation, the malfunction of equipment, periodic updating,
                                maintenance, or repair of the Services or other actions that Company, in its sole
                                discretion, may elect to take.
                            </li>
                            <li>
                                When you purchase a Game Asset, you own the item as a user of the Game, or the Game NFT
                                as a token on the blockchain, but you do not own any intellectual property rights in the
                                Services-related content and/or content derived from the Services (“<b>Content</b>”)
                                associated with such NFT except for the license grants expressly set forth herein. If
                                you purchase or otherwise lawfully acquire a Game Asset, then subject to your compliance
                                with these Terms, the Company hereby grants you a worldwide, non-exclusive,
                                non-transferable, royalty-free license to use, copy, and display the Content that is
                                specifically associated with such purchased Game Asset, and solely for the following
                                purposes: (a) for your own non-commercial and not-for-profit use within or related to
                                the Services and not otherwise in conflict with these Terms or any other
                                Services-related policies (e.g., not to create and sell merchandise related to such
                                Content); (b) as part of the Marketplace and any other marketplace that permits the
                                purchase and sale of your Game Assets, provided that the marketplace digitally or
                                cryptographically verifies that only the actual owner can display the Content in
                                connection with their sale of their Game Asset; or (c) as part of a third-party website
                                or application that permits the display, inclusion, involvement, or participation of
                                your Game Asset, provided that the website/application digitally or cryptographically
                                verifies the ownership of the Game Asset to ensure that only the actual owner can
                                display the Content, and provided that the Content is no longer visible once the owner
                                of the Game Asset leaves the website/application. This license only lasts as long as you
                                are the valid owner and holder of the Game Asset associated with the licensed Content.
                                If you sell or transfer the Game Asset to another person, this license will transfer to
                                such other owner or holder of the Game Asset, and you will no longer have the benefits
                                of such license. All rights not expressly granted are reserved.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <u>Restrictions On Your Use of the Services.</u> You may not do, or assist others in doing, any
                        of the following, unless applicable laws or regulations prohibit these restrictions, or you have
                        our written permission to do so:

                        <ol className="list-roman pad">
                            <li>
                                download, modify, copy, distribute, transmit, translate, display, perform, reproduce,
                                duplicate, publish, license, create derivative works from, or offer for sale any Content
                                or other materials or information contained on, or obtained from or through, the
                                Services, provided, however, that such activities are permitted strictly for
                                non-commercial and not-for-profit uses;
                            </li>
                            <li>
                                duplicate, decompile, reverse engineer, disassemble, decode or derive source code from
                                the Services (including any underlying idea or algorithm), or attempt to do any of the
                                same, except if we have permitted you to do so (such as under an open source license we
                                have granted);
                            </li>
                            <li>
                                use, offer, promote, advertise, make available, and/or distribute the following or
                                assist therein:
                                <ol className="list-latin pad-2">
                                    <li>
                                        cheats (e.g., methods, not expressly authorized by the Company to influence
                                        and/or facilitate the gameplay in an unfair manner, including exploits of any
                                        in-Game bugs, and thereby granting you and/or any other user an advantage over
                                        other players not using such methods),
                                    </li>
                                    <li>
                                        automation software (bots) (i.e., any code and/or software, not expressly
                                        authorized by the Company, that allows the automated control of a Game or any
                                        other feature of the Services, e.g., the automated control of a character in a
                                        Game),
                                    </li>
                                    <li>
                                        hacks (i.e., accessing or modifying the software of the Services in any manner
                                        not expressly authorized by the Company),
                                    </li>
                                    <li>
                                        modifications (mods) not expressly authorized by the Company, or
                                    </li>
                                    <li>
                                        any other code and/or software, not expressly authorized by the Company,
                                        including third-party software, that can be used in connection with the Services
                                        and/or any component or feature thereof which changes and/or facilitates the
                                        gameplay or other functionality;
                                    </li>
                                </ol>
                            </li>
                            <li>
                                engage in any manipulative activity that violates the integrity of the prices of assets
                                on the Marketplace, including (but not limited to) the manipulative tactics commonly
                                known as spoofing and wash trading;
                            </li>
                            <li>
                                access or use the Services in any manner that could disable, overburden, damage,
                                disrupt, or impair the Services or interfere with any other party’s access to or use of
                                the Services or use any device, software, or routine that causes the same;
                            </li>
                            <li>
                                attempt to gain unauthorized access to, interfere with, damage, or disrupt the Services,
                                accounts registered to other users, or the computer systems or networks connected to the
                                Services;
                            </li>
                            <li>
                                circumvent, remove, alter, deactivate, degrade, or thwart any technological measure or
                                content protections of the Services;
                            </li>
                            <li>
                                use any unauthorized robot, spider, crawlers, or other automatic device, process,
                                software, or queries that intercepts, “mines,” scrapes, or otherwise accesses the
                                Services to monitor, extract, copy, or collect information or data from or through the
                                Services, or engage in any manual process to do the same, provided, however, that the
                                Company may, at its sole and absolute discretion, allow the use of certain third-party
                                user interfaces;
                            </li>
                            <li>
                                exploit a design flaw, undocumented problem, or program bug in the Services, including
                                but not limited to creating, using, or transacting with any in-Game item created or
                                copied by such exploit;
                            </li>
                            <li>
                                host, provide, or develop matchmaking services for the Game(s), or intercept, emulate,
                                or redirect the communication protocols used by the Company in any way, for any purpose,
                                including without limitation unauthorized play over the internet, network play (except
                                as expressly authorized by the Company), or as part of content aggregation networks;
                            </li>
                            <li>
                                facilitate, create, or maintain any unauthorized connection to the Services including
                                without limitation (i) any connection to any unauthorized server that emulates, or
                                attempts to emulate, the Services; and (ii) any connection using third-party programs or
                                tools not expressly authorized by the Company;
                            </li>
                            <li>
                                allow another party/person/entity access to your account information or game session or
                                otherwise share your account;
                            </li>
                            <li>
                                attempt to sell, sublicense, rent, lease, grant a security interest in, or otherwise
                                transfer any copy of the Services, any component thereof, or your rights to the Services
                                to any other party in any way not expressly authorized herein;
                            </li>
                            <li>
                                introduce any viruses, trojan horses, worms, logic bombs, or other materials that are
                                malicious or technologically harmful into our systems;
                            </li>
                            <li>
                                use the Services for illegal, harassing, unethical, or disruptive purposes, including
                                engaging in any conduct intended to disrupt or diminish the game experience for other
                                players or disrupt operation of the Company’s Services in any way, including:
                                <ol className="list-latin pad-2">
                                    <li>
                                        disrupting or assisting in the disruption of any computer used to support the
                                        Services or any Game environment, or
                                    </li>
                                    <li>
                                        harassment, “griefing,” abusive behavior or chat, conduct intended to
                                        unreasonably undermine or disrupt the Game experiences of others, deliberate
                                        inactivity, or disconnecting;
                                    </li>
                                </ol>
                            </li>
                            <li>
                                use the Services in such a way as to infringe the privacy, intellectual property rights,
                                or other rights of third parties;
                            </li>
                            <li>
                                violate any applicable law or regulation in connection with your access to or use of the
                                Services; or
                            </li>
                            <li>
                                access or use the Services in any way not expressly permitted by these Terms.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <u>Use of the Services.</u> You are responsible for providing the computer, mobile device,
                        wireless service plan, software, Internet connections, and/or other equipment or services that
                        you need to download, install, and use any aspect of the Services. We do not guarantee that any
                        aspect of the Services can be accessed and used on any particular device or with any particular
                        service plan. We do not guarantee that any aspect of the Services or will be available in, or
                        that orders for Offerings can be placed from, any particular geographic location.
                    </li>
                    <li>
                        <u>Monitoring.</u>
                        <ol className="list-roman">
                            <li>
                                WHILE RUNNING, THE SERVICES (INCLUDING THE GAME) AND COMPANY MAY MONITOR YOUR COMPUTER
                                OR MOBILE DEVICE’S MEMORY FOR UNAUTHORIZED THIRD-PARTY PROGRAMS RUNNING EITHER
                                CONCURRENTLY WITH A GAME OR OUT OF PROCESS. AN “UNAUTHORIZED THIRD-PARTY PROGRAM” AS
                                USED HEREIN SHALL BE DEFINED AS ANY THIRD-PARTY SOFTWARE PROHIBITED BY THE “RESTRICTIONS
                                ON YOUR USE OF THE SERVICES” SECTION ABOVE. IN THE EVENT THAT THE SERVICES DETECT AN
                                UNAUTHORIZED THIRD-PARTY PROGRAM (a) THE SERVICES MAY COMMUNICATE INFORMATION BACK TO
                                THE COMPANY, INCLUDING WITHOUT LIMITATION YOUR ACCOUNT NAME, WALLET ADDRESS, DETAILS
                                ABOUT THE UNAUTHORIZED THIRD-PARTY PROGRAM DETECTED, AND THE TIME AND DATE; AND/OR (b)
                                THE COMPANY MAY EXERCISE ANY OR ALL OF ITS RIGHTS UNDER THESE TERMS, WITH OR WITHOUT
                                PRIOR NOTICE TO YOU.
                            </li>
                            <li>
                                Additionally, the Services may include a tool that will allow your computer system to
                                forward information to the Company in the event of a crash of any aspect of the
                                Services, including system and driver data, and by agreeing hereto you consent to the
                                Company receiving and/or using this data.
                            </li>
                            <li>
                                If the Services detect the use of an Unauthorized Third-Party Program, your access to
                                the Services may be terminated with or without additional notice to you.
                            </li>
                        </ol>
                    </li>
                </ol>

                <h5>4. OWNERSHIP AND CONTENT</h5>
                <ol className="list-latin">
                    <li>
                        <u>Ownership of the Services.</u> The Services, including their “look and feel” (e.g., text,
                        graphics, user interface, design, images, logos, icons, software), Content (including Game
                        audiovisual assets and artwork), data, information, and other materials (“KOMPETE IP”), are
                        protected under copyright, trademark, and other intellectual property laws. You agree that the
                        Company and/or its licensors own all right, title, and interest in and to the Services
                        (including any and all intellectual property rights therein) and you agree not to take any
                        action(s) inconsistent with such ownership interests. We and our licensors reserve all rights in
                        connection with the Services and its content (other than Your Content, defined below),
                        including, without limitation, the exclusive right to create derivative works.
                    </li>
                    <li>
                        <u>Ownership of Trademarks.</u> The Company’s name, Modernize Games, the name of the Game,
                        KOMPETE, the stylized logo, and all related names, logos, product and service names, designs,
                        and slogans are trademarks of the Company or its affiliates or licensors. Other names, logos,
                        product and service names, designs, and slogans that appear on the Services are the property of
                        their respective owners, who may or may not be affiliated with, connected to, or sponsored by
                        us.
                    </li>
                    <li>
                        <u>Ownership of Feedback.</u>We welcome feedback, bug reports, comments, and suggestions for
                        improvements to the Services (“<b>Feedback</b>”). You acknowledge and expressly agree that any
                        contribution of Feedback does not and will not give or grant you any right, title, or interest
                        in the Services or in any such Feedback. All Feedback becomes the sole and exclusive property of
                        the Company, and the Company may use and disclose Feedback in any manner and for any purpose
                        whatsoever without further notice or compensation to you and without retention by you of any
                        proprietary or other right or claim. You hereby assign to the Company any and all right, title,
                        and interest (including, but not limited to, any intellectual property right) that you may have
                        in and to any and all Feedback.
                    </li>
                    <li>
                        <u>KOMPETE IP Limited License Grant.</u> We support users who use KOMPETE IP to create free fan
                        projects for the benefit of the community (“<b>Projects</b>”). On condition that you adhere to
                        all
                        requirements under these Terms, we hereby grant you a personal, non-exclusive,
                        non-sublicensable, non-transferable, revocable, limited license for you to use, display and
                        create derivative works based on KOMPETE IP, strictly for noncommercial community use, unless as
                        specifically provided below.

                        <ol className="list-roman">
                            <li>
                                This license excludes the use of KOMPETE IP in other games and applications; you may not
                                take any part of the KOMPETE IP (e.g., character cosmetics, appearance, abilities, maps,
                                icons, items, etc.) and use it in another game or application. We reserve the right to
                                deny anyone the use of the KOMPETE IP at any time, for any reason or no reason, in our
                                sole discretion. If we deny you the right to use KOMPETE IP, you must stop developing,
                                publishing and/or distributing your Project and cease use of any KOMPETE IP immediately.
                            </li>
                            <li>
                                We allow passive ad revenue generation for some content, subscriptions and donations on
                                streaming channels. You may not create commercial Projects, including any Project that
                                crowdsources any portion of its funding, any Project that involves a business or legal
                                entity, or any Project where the content of the Project is gated with a paywall (e.g.,
                                Patreon, Youtube Premium, etc.) without a written license agreement from us. You may not
                                use any third-party intellectual property in your Project without the third-party’s
                                permission. For the avoidance of confusion, you may not use the logos and trademarks of
                                Modernize Marketing, LLC or KOMPETE in your Project unless you have a written license
                                agreement with us. You may not register domain names, social media accounts, or any
                                other such similar actions using any part of our trademarks or names, including but not
                                limited to the KOMPETE logo, trademarks, trade names, the Game, character names, and map
                                names. You may not use our trademarks or names related to the KOMPETE IP as keywords or
                                internet search tags.
                            </li>
                            <li>
                                If you share your Project, you must conspicuously include the following language on your
                                Project and/or your Project’s website: “[Your Project Title] was created under the
                                KOMPETE Terms of Service using assets owned by Modernize Marketing LLC. Modernize
                                Marketing LLC is not affiliated with this project, and does not endorse or sponsor this
                                project.”
                            </li>
                        </ol>
                    </li>
                    <li>
                        <u>Your Content License Grant.</u> We may share or spotlight fan Projects, and you agree that we
                        may use, copy, modify, distribute and make derivative works of your Project in any form, on a
                        royalty-free, non-exclusive, irrevocable, transferable, sub-licensable, worldwide basis, for any
                        purpose and without having to credit you, compensate you, or obtain your approval. In addition,
                        in connection with your use of the Services, you may be able to create, post, upload, or submit
                        content to be made available through the Services (<b>User Generated Content</b> or “<b>UGC</b>”),
                        such as user profiles, comments, character cosmetics, character or team names, sound files,
                        images, music files, game assets, and other UGC. You agree that we may share any UGC you upload
                        to the Game or Services, and the actions we take in operating the Services are not considered
                        legal violations. Accordingly, by using the Services and uploading your UGC, you grant us a
                        license to access, use, host, cache, store, reproduce, transmit, display, publish, distribute,
                        modify (for technical purposes, e.g., making sure content is viewable on smartphones as well as
                        computers and other devices) and make derivative works of the UGC. You agree that these rights
                        and licenses are royalty free, non-exclusive, transferable, sub-licensable, worldwide, and
                        irrevocable,and includes a right for us to make your UGC available to, and pass these rights
                        along to, others for any purpose, without your approval, and without providing you with credit
                        or compensation.. As part of the foregoing license grant you agree that the other users of the
                        Services shall have the right to comment on and/or tag your UGC and/or to use, publish, display,
                        modify, or include a copy of your UGC as part of their own use of the Services. By posting or
                        submitting your UGC through the Services, you represent and warrant that you have, or have
                        obtained, all rights, licenses, consents, permissions, power, and/or authority necessary to
                        grant the rights granted herein for your UGC. You agree that your UGC will not contain material
                        subject to copyright or other proprietary rights, unless you have the necessary permission or
                        are otherwise legally entitled to post the material and to grant us the license described above.
                    </li>
                    <li>
                        <u>Notice of Infringement – DMCA Policy.</u> If you believe that any text, graphics, photos,
                        audio, videos, or other materials or works uploaded, downloaded or appearing on the Services
                        have been copied in a way that constitutes copyright infringement, you may submit a notification
                        to our copyright agent in accordance with 17 U.S.C. § 512(c) of the Digital Millennium Copyright
                        Act (the “DMCA”), by providing the following information in writing:

                        <ol className="list-roman">
                            <li>
                                identification of the copyrighted work that is claimed to be infringed;
                            </li>
                            <li>
                                identification of the allegedly infringing material that is requested to be removed,
                                including a description of where it is located on the Services;
                            </li>
                            <li>
                                information for our copyright agent to contact you, such as an address, telephone
                                number, and e-mail address;
                            </li>
                            <li>
                                a statement that you have a good faith belief that the identified, allegedly infringing
                                use is not authorized by the copyright owners, its agent, or the law;
                            </li>
                            <li>
                                a statement that the information above is accurate, and under penalty of perjury, that
                                you are the copyright owner or the authorized person to act on behalf of the copyright
                                owner; and
                            </li>
                            <li>
                                the physical or electronic signature of a person authorized to act on behalf of the
                                owner of the copyright or of an exclusive right that is allegedly infringed.
                            </li>
                        </ol>
                        <p>
                            Notices of copyright infringement claims should be sent by email to: <a
                            href="mailto:legal@modernizegames.com">legal@modernizegames.com</a>
                            . It is our policy, in appropriate circumstances and at our discretion, to disable or
                            terminate the accounts of users who repeatedly infringe copyrights or intellectual property
                            rights of others when using the Services.
                        </p>
                    </li>
                </ol>

                <h5>5. THIRD-PARTY SERVICES AND MATERIALS</h5>
                <ol className="list-latin">
                    <li>
                        Use of Third-Party Materials in the Services. Certain Services may display, include, or make
                        available content, data, information, applications, or materials from third parties
                        (“<b>Third-Party Materials</b>”) or provide links to certain third-party websites. By using the
                        Services, you acknowledge and agree that the Company is not responsible for examining or
                        evaluating the content, accuracy, completeness, availability, timeliness, validity, copyright
                        compliance, legality, decency, quality, or any other aspect of such Third-Party Materials or
                        websites. We do not warrant or endorse and do not assume and will not have any liability or
                        responsibility to you or any other person for any third-party services, Third-Party Materials,
                        or third-party websites, or for any other materials, products, or services of third parties.
                        Third-Party Materials and links to other websites are provided solely as a convenience to you.
                    </li>
                    <li>
                        Use of Unreal® Engine from Epic Games, Inc.
                        <ol className="list-roman">
                            <li>
                                The Game includes the Unreal® Engine code and other code, materials, and information
                                (the “Epic Materials”) from Epic Games, Inc. (“Epic”). All Epic Materials are provided
                                on an “as is” and “as available” basis, “with all faults” and without warranty of any
                                kind. [Licensor], Epic, and Epic’s affiliates disclaim all warranties, conditions,
                                common law duties, and representations (express, implied, oral, and written) with
                                respect to the Epic Materials, including without limitation all express, implied, and
                                statutory warranties and conditions of any kind, such as title, non-interference with
                                your enjoyment, authority, non-infringement, merchantability, fitness or suitability for
                                any purpose (whether or not Epic knows or has reason to know of any such purpose),
                                system integration, accuracy or completeness, results, reasonable care, workmanlike
                                effort, lack of negligence, and lack of viruses, whether alleged to arise under law, by
                                reason of custom or usage in the trade, or by course of dealing. Without limiting the
                                generality of the foregoing, Company, Epic, and Epic’s affiliates make no warranty that
                                (1) any of the Epic Materials will operate properly, including as integrated in the
                                Game, (2) that the Epic Materials will meet your requirements, (3) that the operation of
                                the Epic Materials will be uninterrupted, bug free, or error free in any or all
                                circumstances, (4) that any defects in the Epic Materials can or will be corrected, (5)
                                that the Epic Materials are or will be in compliance with a platform manufacturer’s
                                rules or requirements, or (6) that a platform manufacturer has approved or will approve
                                this [Product], or will not revoke approval of this Game for any or no reason. Any
                                warranty against infringement that may be provided in Section 2-312 of the Uniform
                                Commercial Code or in any other comparable statute is expressly disclaimed by Company
                                and Epic. Company, Epic, and Epic’s affiliates do not guarantee continuous, error-free,
                                virus-free, or secure operation of or access to the Epic Materials. This paragraph will
                                apply to the maximum extent permitted by applicable law.
                            </li>
                            <li>
                                To the maximum extent permitted by applicable law, neither Company, Epic, Epic’s
                                licensors, nor its or their affiliates, nor any of Company’s or Epic’s service
                                providers, shall be liable in any way for loss or damage of any kind resulting from the
                                use or inability to use the Epic Materials or otherwise in connection with these Terms,
                                including but not limited to loss of goodwill, work stoppage, computer failure, or
                                malfunction, or any and all other commercial damages or losses. In no event will
                                Company, Epic, Epic’s licensors, nor its or their affiliates, nor any of Company’s or
                                Epic’s service providers be liable for any loss of profits or any indirect, incidental,
                                consequential, special, punitive, or exemplary damages, or any other damages arising out
                                of or in connection with these Terms or the Epic Materials, or the delay or inability to
                                use or lack of functionality of the Epic Materials, even in the event of Company’s,
                                Epic’s, or Epic’s affiliates’ fault, tort (including negligence), strict liability,
                                indemnity, product liability, breach of contract, breach of warranty, or otherwise and
                                even if Company, Epic or Epic’s affiliates have been advised of the possibility of such
                                damages. These limitations and exclusions regarding damages apply even if any remedy
                                fails to provide adequate compensation.
                            </li>
                            <li>
                                Because some states or jurisdictions do not allow the exclusion or the limitation of
                                liability for consequential or incidental damages, in such states or jurisdictions, the
                                liability of Company, Epic, Epic’s licensors, its and their affiliates, and any of
                                Company’s or Epic’s service providers shall be limited to the full extent permitted by
                                law.
                            </li>
                        </ol>
                    </li>
                </ol>
                <h5>6. UPDATES AND PATCHES</h5>
                <p className="pad-p">
                    The Company (or a third party on the Company’s behalf) may deploy or provide patches, updates, and
                    modifications to the Services that must be installed for you to continue to use the Services. The
                    Company may update the Services remotely, including, without limitation, any aspect of the Services
                    residing on your computer or game machine, without your knowledge or consent, and you hereby grant
                    to the Company your consent to deploy and apply such patches, updates, and modifications as the
                    Company, in its sole discretion, deems necessary or advisable. You also agree that the Company may
                    change, modify, suspend, “nerf,” “buff,” discontinue, or restrict your access to any features or
                    parts (including but not limited to Game Assets) of the Services at any time without notice or
                    liability to you.
                </p>

                <h5>7. TERMINATION</h5>
                <ol className="list-latin">
                    <li>
                        You acknowledge and agree that the Company, in its sole and absolute discretion, may (i) stop
                        providing support for or access to the Services at any time, for any reason or no reason, and
                        (ii) terminate your right to use the Services and terminate these Terms immediately at any time
                        without notice or liability to you.
                    </li>
                    <li>
                        In the event of termination of these Terms or your rights or license granted hereunder, you must
                        (i) cease to use the Services; (ii) immediately and permanently remove from all of your devices
                        all aspects of the Services in your possession and control. Upon termination of these Terms for
                        any reason, all licenses granted herein immediately shall terminate.
                    </li>
                </ol>

                <h5>8. DISCLAIMERS; LIMITATIONS OF LIABILITY; INDEMNIFICATION</h5>
                <ol className="list-latin">
                    <li>
                        <u>DISCLAIMERS.</u> YOUR ACCESS TO AND USE OF THE SERVICES ARE AT YOUR OWN RISK. YOU UNDERSTAND
                        AND AGREE THAT THE SERVICES ARE PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS. WITHOUT
                        LIMITING THE FOREGOING, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, THE COMPANY, ITS
                        PARENTS, AFFILIATES, RELATED COMPANIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES,
                        PARTNERS, AND LICENSORS (THE “<b>COMPANY ENTITIES</b>”) DISCLAIM ALL WARRANTIES AND CONDITIONS,
                        WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                        NON-INFRINGEMENT. THE COMPANY ENTITIES MAKE NO WARRANTY OR REPRESENTATION AND DISCLAIM ALL
                        RESPONSIBILITY AND LIABILITY FOR: (A) THE COMPLETENESS, ACCURACY, AVAILABILITY, TIMELINESS,
                        SECURITY, OR RELIABILITY OF THE SERVICES; (B) ANY HARM TO YOUR COMPUTER SYSTEM, LOSS OF DATA,
                        OR OTHER HARM THAT RESULTS FROM YOUR ACCESS TO OR USE OF THE SERVICES; (C) THE OPERATION OR
                        COMPATIBILITY WITH ANY OTHER APPLICATION OR ANY PARTICULAR SYSTEM OR DEVICE; (D) WHETHER THE
                        SERVICES WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE
                        BASIS; AND (E) THE DELETION OF, OR THE FAILURE TO STORE OR TRANSMIT, YOUR CONTENT, DATA, AND
                        OTHER COMMUNICATIONS MAINTAINED BY THE SERVICES. NO ADVICE OR INFORMATION, WHETHER ORAL OR
                        WRITTEN, OBTAINED FROM THE COMPANY ENTITIES OR THROUGH THE SERVICES, WILL CREATE ANY WARRANTY OR
                        REPRESENTATION NOT EXPRESSLY MADE HEREIN. FOR CLARITY, WE HAVE NO CONTROL OVER AND MAKE NO
                        GUARANTEES OR PROMISES WITH RESPECT TO THE FUNCTIONALITY OF THE ETHEREUM BLOCKCHAIN AND/OR THE
                        EXECUTION OF SMART CONTRACTS AND/OR TRANSACTIONS THEREIN.
                    </li>
                    <li>
                        <u>LIMITATIONS OF LIABILITY.</u> TO THE MAXIMUM EXTENT NOT PROHIBITED BY LAW, YOU AGREE THAT IN
                        NO EVENT WILL THE COMPANY ENTITIES BE LIABLE FOR ANY INDIRECT, SPECIAL, EXEMPLARY, INCIDENTAL,
                        CONSEQUENTIAL, OR PUNITIVE DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
                        GOODS OR SERVICES, LOSS OF USE, DATA OR PROFITS, BUSINESS INTERRUPTION, OR ANY OTHER DAMAGES OR
                        LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE SERVICES), HOWEVER CAUSED
                        AND UNDER ANY THEORY OF LIABILITY, WHETHER UNDER THESE TERMS OR OTHERWISE ARISING IN ANY WAY IN
                        CONNECTION WITH THE SERVICES OR THESE TERMS AND WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
                        (INCLUDING NEGLIGENCE OR OTHERWISE) EVEN IF THE COMPANY ENTITIES HAVE BEEN ADVISED OF THE
                        POSSIBILITY OF SUCH DAMAGE. SOME JURISDICTIONS (SUCH AS THE STATE OF NEW JERSEY) DO NOT ALLOW
                        THE EXCLUSION OR LIMITATION OF CERTAIN INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
                        EXCLUSION OR LIMITATION MAY NOT APPLY TO YOU IF YOU ARE A RESIDENT OF THOSE JURISDICTIONS. THE
                        COMPANY ENTITIES’ TOTAL LIABILITY TO YOU FOR ANY DAMAGES FINALLY AWARDED SHALL NOT EXCEED THE
                        GREATER OF (I) THE AMOUNT YOU PAID THE COMPANY ENTITIES, IF ANY, IN THE PAST SIX (6) MONTHS FOR
                        THE SERVICES (OR OFFERINGS PURCHASED ON THE SERVICES) GIVING RISE TO THE CLAIM, OR (II) $100
                        USD. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL
                        PURPOSE.
                    </li>
                    <li>
                        <u>Indemnification.</u> By entering into these Terms and accessing or using the Services, you
                        agree that you shall defend, indemnify, and hold the Company Entities harmless from and against
                        any and all claims, costs, damages, losses, liabilities, and expenses (including attorneys’ fees
                        and costs) incurred by the Company Entities arising out of or in connection with: (a) your
                        violation or breach of any term of these Terms or any applicable law or regulation; (b) your
                        violation of any rights of any third party; (c) your access to or use of the Services; (d) Your
                        Content, or (e) your negligence or willful misconduct.
                    </li>
                    <li>
                        <u>Assumption of Risks.</u> You acknowledge and agree that there are risks associated with
                        purchasing and using virtual currency, tokens, holding NFTs and using blockchain technology.
                        These include, but are not limited to, risk of losing access to the virtual currency, tokens,
                        and NFTs due to loss of a PIN, wallet keys, two-factor authentication devices, or log-in
                        information, user error when transferring NFTs, custodial error or purchaser error, smart
                        contract error, risk of blockchain attacks, risk of hacking and security weaknesses, risk of
                        unfavorable regulatory intervention in one or more jurisdictions, risks related to token
                        taxation, risk of personal information disclosure, risk of uninsured losses, unanticipated
                        risks, and volatility risks. You acknowledge that you have obtained sufficient information to
                        make an informed decision to purchase a Game Asset, including carefully reviewing the code of
                        the smart contract and the NFT, and fully understand and accept the functions of the same. The
                        regulatory regime governing blockchain technologies, cryptocurrencies, and tokens is uncertain,
                        and new regulations or policies may materially adversely affect the development of the Services,
                        and therefore the potential utility or value of your Game Assets. Upgrades to any blockchain
                        network or hard forks in such networks, or a change in how transactions are confirmed on such
                        blockchain networks, may have unintended, adverse effects on all blockchains, including any that
                        are related to your Game NFTs.
                    </li>
                    <li>
                        <u>Taxes.</u> You are solely responsible to pay any and all sales, use, value-added, and other
                        taxes, duties, and assessments (except taxes on our net income) now or hereafter claimed or
                        imposed by any governmental authority associated with your use of the Services. Except for
                        income taxes levied on us, you: (a) will be solely responsible for reporting any tax obligations
                        when, if ever, such obligations arise as a result of your use of the Services or in relation to
                        an Offering; (b) will pay or reimburse Company for all national, federal, state, local, or other
                        taxes and assessments of any jurisdiction, including value added taxes and other taxes as may be
                        required, and amounts levied in lieu thereof based on charges set, services performed, or
                        payments made hereunder, as are now or hereafter may be imposed under the authority of any
                        national, state, local, or any other taxing jurisdiction; and (c) will not be entitled to deduct
                        the amount of any such taxes, duties, or assessments from payments (including transaction fees)
                        made to us pursuant to these Terms. Neither these Terms nor any other communication from the
                        Company constitutes tax advice, and users are solely responsible for determining what, if any,
                        taxes apply to their interaction with Offerings and the Services.
                    </li>
                    <li>
                        <u>Force Majeure.</u> We will not be liable or responsible to you, nor be deemed to have
                        defaulted under or breached these Terms, for any failure or delay in fulfilling or performing
                        any of our obligations under these Terms or in operating the Services, when and to the extent
                        such failure or delay is caused by or results from any events beyond the Company’s ability to
                        control, including acts of God, flood, fire, earthquake, epidemics, pandemics, quarantine
                        restrictions, tsunami, explosion, war, invasion, hostilities (whether war is declared or not),
                        terrorist threats or acts, riot or other civil unrest, government order, law, or action,
                        embargoes or blockades, strikes, labor stoppages or slowdowns or other industrial disturbances,
                        shortage of adequate or suitable Internet connectivity, telecommunication breakdown, shortage of
                        adequate power or electricity, and other similar events beyond our control.
                    </li>
                </ol>

                <h5>9. GOVERNING LAW; ARBITRATION; CLASS ACTION WAIVER</h5>
                <ol className="list-latin">
                    <li>
                        <u>Governing Law.</u> These Terms are governed by the laws of the State of California, without
                        regard to conflict of laws rules, and the proper venue for any disputes arising out of or
                        relating to any of the same will be the arbitration venue set forth herein, or if arbitration
                        does not apply, then the state and federal courts located in California. You and the Company
                        agree that the United Nations Convention on Contracts for the International Sale of Goods will
                        not apply to the interpretation or construction of these Terms.
                    </li>
                    <li>
                        <u>Informal Process First.</u> You agree that in the event of any dispute between you and the
                        Company Entities, you will first contact the Company and make a good faith sustained effort to
                        resolve the dispute before resorting to more formal means of resolution, including without
                        limitation, any court action or arbitration.
                    </li>
                    <li>
                        <u>Arbitration Agreement and Class Action Waiver.</u> After the informal dispute resolution
                        process, any remaining dispute, controversy, or claim (collectively, “<b>Claim</b>”), whether
                        based in contract, tort, statute, fraud, or any other legal theory, relating in any way to your
                        use of the Company’s services and/or products, including the Services, will be resolved by
                        arbitration, including the resolution of threshold questions as to the arbitrability of the
                        Claim. YOU AND THE COMPANY AGREE THAT “Claim” AS DEFINED IN THESE TERMS SHALL NOT INCLUDE ANY
                        CLAIM OR CAUSE OF ACTION FOR INTELLECTUAL PROPERTY INFRINGEMENT, MISAPPROPRIATION, DILUTION, OR
                        OTHER MISUSE OF INTELLECTUAL PROPERTY RIGHTS. You and the Company agree that any Claim will be
                        settled by final and binding arbitration, using the English language, administered by the
                        American Arbitration Association (AAA) under its Consumer Arbitration Rules (the “<b>Rules</b>”)
                        then in effect (those rules are deemed to be incorporated by reference into this section, and as
                        of the date of these Terms). Any arbitration under these Terms will take place on an individual
                        basis in accordance with the Rules. Class arbitrations and class actions are not permitted. YOU
                        UNDERSTAND THAT BY AGREEING TO THESE TERMS, YOU AND THE COMPANY ARE EACH WAIVING THE RIGHT TO
                        TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION OR CLASS ARBITRATION. You and Company further
                        agree: (i) to arbitrate all Claims between the parties pursuant to the provisions in these
                        Terms; (ii) these Terms memorialize a transaction in interstate commerce; and (iii) the Federal
                        Arbitration Act (9 U.S.C. § 1, et seq.) governs the interpretation and enforcement of this
                        Section. The arbitrator’s decision(s) shall be made pursuant to the Rules and will be final and
                        binding. The arbitrator will have authority to award temporary, interim, or permanent injunctive
                        relief or relief providing for specific performance of these Terms as permitted by the governing
                        California law and subject to the terms and limitations in these Terms. The award rendered by
                        the arbitrator may be entered in any court having jurisdiction. Notwithstanding the foregoing
                        agreement to arbitration, you and the Company will have the right to bring an action in a court
                        of proper jurisdiction for injunctive or other equitable or conservatory relief, pending a final
                        decision by the arbitrator.
                    </li>
                    <li>
                        <u>Confidentiality.</u> You and Company agree that any Claim that has been submitted to
                        arbitration, and all related proceedings, including any settlement agreement, shall be kept
                        confidential. In the event you or the Company file the arbitration award with any court of
                        competent jurisdiction in order to have that award confirmed in a court order or judgment, or
                        any other proceeding under the Federal Arbitration Act, then each party shall take measures to
                        the extent permitted by applicable law to redact or file under seal any highly sensitive
                        business or personal information that appear in the award and any other supporting documents
                        filed in such a proceeding.
                    </li>
                    <li>
                        <u>Conduct of the Arbitration.</u> Any arbitration will be conducted by a single, neutral
                        arbitrator appointed in accordance with the Rules, except that, if the amount in dispute exceeds
                        $10 million USD, the arbitration shall be conducted by a panel of three neutral arbitrators
                        appointed in accordance with the Rules. The arbitration shall have its seat in California. The
                        arbitrator will conduct hearings, if any, by videoconference (which you may join telephonically
                        if you are unable to reasonably access or utilize videoconference technology), rather than by
                        personal appearances, during ordinary business hours in the time zone in which the party
                        initiating the arbitration resides. The arbitrator may direct that the hearing be held in person
                        upon the request of any party if the arbitrator concludes that doing so would be appropriate
                        under the circumstances. Any in-person appearances will be held at a location which is
                        reasonably convenient to both parties with due consideration of their ability to travel and
                        other pertinent circumstances. If the parties are unable to agree on a location, such
                        determination should be made by the arbitrator. For the avoidance of doubt, the arbitration
                        shall be deemed to take place in California, and any resulting award(s) will be rendered in
                        California, regardless of the location or method of any hearings.
                    </li>
                </ol>

                <h5>10. APPLE ENABLED APPLICATIONS </h5>
                <p className="pad-p">
                    The Services operate in connection with products made commercially available by Apple, Inc.
                    ("<b>Apple</b>"), in addition to other devices and operating systems. With respect to Services that
                    are made available to you in connection with an Apple-branded product (such Services shall
                    hereinafter be known as "<b>Apple-Enabled Services</b>"), the following terms and conditions apply,
                    in addition to the terms and conditions set forth in these Terms:
                </p>
                <ol className="list-latin">
                    <li>
                        You and Company acknowledge that these Terms operate between you and Company only and not with
                        Apple and that as between Company and Apple, Company, not Apple, is responsible for the
                        Apple-Enabled Services and the content thereof;
                    </li>
                    <li>
                        You acknowledge that the Apple iOS App Store ("<b>App Store</b>") contains rules and conditions
                        that govern the use of software made available therein (collectively "<b>Usage Rules</b>"). Such
                        Usage Rules are contained within the App Store Terms of Service ("<b>App Store Terms</b>").
                        Regarding the Services, you may not use the Apple-Enabled Services in any manner that is in
                        violation of or inconsistent with these Usage Rules or the App Store Terms;
                    </li>
                    <li>
                        Your license to use the Apple-Enabled Services is limited to a non-transferable license to use
                        the Apple-Enabled Services on an iOS product that you own or control, as permitted by the Usage
                        Rules set forth in the App Store Terms
                    </li>
                    <li>
                        You acknowledge that Apple has no obligation whatsoever to provide any maintenance or support
                        services with respect to the Apple-Enabled Services;
                    </li>
                    <li>
                        You acknowledge that Apple is not responsible for any product or software warranties, whether
                        expressed or implied by law. In the event of any failure of Apple-Enabled Services to conform to
                        any applicable warranty, you may notify Apple, via the methods described in the App Store, and
                        Apple will refund the purchase price for the Apple Enabled Services to you, if any; and, to the
                        maximum extent permitted by applicable law, Apple will have no other warranty obligation
                        whatsoever with respect to the Apple-Enabled Services, or any other claims, losses, liabilities,
                        damages, costs, or expenses attributable to any failure to conform to any warranty, to the
                        extent that it cannot be disclaimed under applicable law. For a discussion of any warranties
                        related to the Services, please see the section herein entitled "Disclaimer and Limitation of
                        Liability;"
                    </li>
                    <li>
                        Company and you acknowledge that Company, not Apple, is responsible for addressing any claims of
                        you or any third party relating to the Apple-Enabled Services or your possession and/or use of
                        those Apple-Enabled Services, including but not limited to i) product liability claims; ii) any
                        claim that the Apple-Enabled Services fail to conform to any applicable legal or regulatory
                        requirement; and iii) claims arising under consumer protection or similar legislation;
                    </li>
                    <li>
                        In the event of any third party claim that the Apple-Enabled Services or the end-user's
                        possession and use of those Apple-Enabled Services infringes that third party's intellectual
                        property rights, as between Company and Apple, Company, not Apple, will be solely responsible
                        for the investigation, defense, settlement, and discharge of any such intellectual property
                        infringement claim;
                    </li>
                    <li>
                        You represent and warrant that i) you are not located in any country that is subject to a U.S.
                        government embargo, or that has been designated by the U.S. Government as a "terrorist
                        supporting" country; ii) you are not listed on any U.S. Government list of prohibited or
                        restricted parties; and iii) you are not located in any other country or jurisdiction from which
                        you would be barred from using the Services by applicable law; and
                    </li>
                    <li>
                        If you have any complaints, questions, comments, or concerns with respect to the Apple-Enabled
                        Services, you should direct them to: tos@modernizegames.com
                    </li>
                </ol>

                <h5>11. ADDITIONAL PROVISIONS</h5>

                <ol className="list-latin">
                    <li>
                        <u>Updating These Terms.</u> We may modify these Terms from time to time in which case we will
                        update the “Last Revised On” date at the top of these Terms. If we make changes that are
                        material, we will use reasonable efforts to attempt to notify you. However, it is your sole
                        responsibility to review these Terms from time to time to view any such changes. The updated
                        Terms will be effective as of the time of posting, or such later date as may be specified in the
                        updated Terms. Your continued access or use of the Services after the modifications have become
                        effective will be deemed your acceptance of the modified Terms.
                    </li>
                    <li>
                        <u>Reps and Warranties.</u> In addition to any other representation and warranties made herein,
                        you hereby represent and warrant that (i) not prohibited from receiving or using any aspect of
                        the Services under applicable laws and (ii) Company has not previously disabled your Account or
                        your access to the Services for a violation of the law or these Terms or other applicable
                        agreements, terms of use, or contracts.
                    </li>
                    <li>
                        <u>Electronic Communication.</u> Each and every time you send an email or other electronic
                        communication to Company, such communication will constitute an electronic communication. By
                        using the Services, you consent to receive electronic communications and you agree that all
                        agreements, notices, disclosures and other communications that Company provides to you via
                        electronic communication, individually and collectively, satisfy any legal requirement that such
                        communications be in writing.
                    </li>
                    <li>
                        <u>Termination of License and Your Account.</u> If you breach any of the provisions of these
                        Terms, all licenses granted by the Company will terminate automatically. Additionally, the
                        Company may suspend, disable, or delete your Account and/or the Services (or any part of the
                        foregoing) with or without notice, for any or no reason. If the Company deletes your Account for
                        any suspected breach of these Terms by you, you are prohibited from re-registering for the
                        Services under a different name or wallet address. In the event of Account deletion for any
                        reason, the Company may, but is not obligated to, delete any of Your Content. The Company shall
                        not be responsible for the deletion of or failure to delete Your Content. All sections which by
                        their nature should survive the termination of these Terms shall continue in full force and
                        effect subsequent to and notwithstanding any termination of these Terms by the Company or you.
                        Termination will not limit any of the Company’s other rights or remedies at law or in equity.
                    </li>
                    <li>
                        <u>Injunctive Relief.</u> You agree that a breach of these Terms will cause irreparable injury
                        to the Company for which monetary damages would not be an adequate remedy and the Company shall
                        be entitled to equitable relief in addition to any remedies it may have hereunder or at law
                        without a bond, other security, or proof of damages.
                    </li>
                    <li>
                        <u>Waiver.</u> No act or failure to act by Company will be deemed a waiver of any right
                        contained
                        in these Terms, and any waiver by Company must be in writing and signed by an officer of
                        Company. If Company does expressly waive any provisions of these Terms, such waiver shall not be
                        a waiver of any other provisions of these Terms, and the waived provisions hall not be waived
                        for all time in the future.
                    </li>
                    <li>
                        <u>California Residents.</u> If you are a California resident, in accordance with Cal. Civ. Code
                        § 1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer
                        Services of the California Department of Consumer Affairs by contacting them in writing at 1625
                        North Market Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at (800) 952-5210.
                    </li>
                    <li>
                        <u>Export Laws.</u> You agree that you will not export or re-export, directly or indirectly, the
                        Services and/or other information or materials provided by the Company hereunder, to any country
                        for which the United States or any other relevant jurisdiction requires any export license or
                        other governmental approval at the time of export without first obtaining such license or
                        approval. In particular, but without limitation, the Services may not be exported or re-exported
                        (a) into any U.S. embargoed countries or any country that has been designated by the U.S.
                        Government as a “terrorist supporting” country, or (b) to anyone listed on any U.S. Government
                        list of prohibited or restricted parties, including the U.S. Treasury Department’s list of
                        Specially Designated Nationals or the U.S. Department of Commerce Denied Person’s List or Entity
                        List. You warrant and represent that you’re not located in, under the control of, or a national
                        or resident of any embargoed country. By using the Services, you represent and warrant that you
                        are not located in any such country or on any such list. You are responsible for and hereby
                        agree to comply at your sole expense with all applicable United States export laws and
                        regulations.

                    </li>
                    <li>
                        <u>Miscellaneous.</u> If any provision of these Terms shall be unlawful, void, or for any reason
                        unenforceable, then that provision shall be deemed severable from these Terms and shall not
                        affect the validity and enforceability of any remaining provisions. These Terms and the licenses
                        granted hereunder may be assigned by the Company but may not be assigned by you without the
                        prior express written consent of the Company. No waiver by either party of any breach or default
                        hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. The
                        section headings used herein are for reference only and shall not be read to have any legal
                        effect. The Services are operated by us in the United States. Those who choose to access the
                        Services from locations outside the United States do so at their own initiative and are
                        responsible for compliance with applicable local laws.
                    </li>
                    <li>
                        <u>How to Contact Us.</u> You may contact us regarding the Services or these Terms at:
                        <a href="mailto:legal@modernizegames.com<"> legal@modernizegames.com</a>
                    </li>
                </ol>
            </WebsiteRightsContent>
        </DefaultLayout>
    )
}

export default TermsConditions