import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {memo} from "react";

import classNames from "classnames";

import {useConnectedWallet, useConnectWallet} from "hooks";
import {formatNumberToEuropeanStyle} from "utils";

import SwitchBlock from "../UserDetails/SwitchBlock";

import {png} from "assets/png";

const MenuUser = ({signOut}) => {
    const navigate = useNavigate()
    const {
        wallet,
        picture,
        nickname,
        voolahBalance,
        kompeteBalance
    } = useSelector(state => state.authV2.signIn.userData)
    const {isConnected} = useConnectedWallet()
    const {disconnectWallet} = useConnectWallet()

    const noRegisteredWallet = classNames({"no-wallet": !wallet})

    return (
        <div className="navigation-mobile_content_user-menu">
            <h3>{nickname}</h3>
            <img alt="avatar" src={picture ? picture : png.NewAvatar}/>
            <div className="navigation-mobile_content_user-menu_balance">
                <SwitchBlock/>
                <div>
                    <img alt="voolah" src={png.VoolahCoin}/>
                    <span>{formatNumberToEuropeanStyle(voolahBalance)}</span>
                </div>
                <div className={noRegisteredWallet}>
                    <img alt="kompete-token" src={png.T1Png}/>
                    <span>{formatNumberToEuropeanStyle(kompeteBalance ? Number(kompeteBalance).toFixed(2) : 0)}</span>
                </div>
            </div>
            <div className="navigation-mobile_content_user-menu_options">
                <button onClick={() => navigate("/profile")}>Profile</button>
                <button disabled={!isConnected} onClick={() => disconnectWallet()}>Wallet Disconnect</button>
                <button onClick={() => signOut()}>Logout</button>
            </div>
        </div>
    )
}

export default memo(MenuUser)