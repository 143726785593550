import React from "react";
import ModalWindow from "components/atoms/ModalWindow";
import LoadSpinnerTwo from "components/atoms/LoadSpinnerTwo";
import "./style.scss"

const ProcessModal = ({show, isShowClose = false, closeClick, text1, text2}) => {
    return (
        <ModalWindow show={show} size="modal-lg" isShowClose={isShowClose} closeClick={closeClick}>
            <div className="process-block">
                <div className="process-block_content">
                    <LoadSpinnerTwo/>
                    <p className="process-block_content_text1">{text1}</p>
                    <p className="process-block_content_text2">{text2}</p>
                </div>
            </div>
        </ModalWindow>
    )
}

export default ProcessModal