import React from "react"

import Title from "../../atoms/TitleTemplate";
import Image from "../../atoms/Image";

import {png} from "assets/png";
import "./style.scss"

const HomeWelcome = () => {

    return (
        <section className="home_welcome">
            <Title underline={true}> Welcome to <br/> <span>Mahzookoo Island</span></Title>
            <div className="home_welcome_content">
                <div className="home_welcome_content_main-img">
                    <Image alt="island" src={png.GameMap}/>
                </div>
                <div className="home_welcome_content_island-img">
                    <div>
                        <Image alt="island" src={png.MahzookooIsland1}/>
                        <Image alt="island" src={png.MahzookooIsland2}/>
                    </div>
                    <div>
                        <Image alt="island" src={png.MahzookooIsland3}/>
                        <Image alt="island" src={png.MahzookooIsland4}/>
                    </div>
                </div>
                <div className="home_welcome_content_info">
                    <div className="first-block-content">
                        <h2><span>One island</span> for all the<br/>game modes.</h2>
                        <p>Get ready for an adventure like no other on Mahzookoo Island, the ultimate destination for
                            KOMPETETITORS. You'll find yourself amid a battle royale-style landscape filled with POIs,
                            race tracks, basketball courts, golf courses, and more.</p>
                        <p>But the fun doesn't stop there! Mahzookoo Island is constantly expanding with new playable
                            locations and exciting future game modes that are added all the time. Say goodbye to the
                            mainland and hello to Mahzookoo Island!!!</p>
                    </div>
                    <div>
                        <h2><span>One Island</span> for all <br/>the game modes.</h2>
                        <Image alt="place" src={png.MahzookooIsland5}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeWelcome