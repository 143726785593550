import React, {memo, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {
    PROCESS,
    ENVIRONMENTS,
    INVENTORY_TYPE,
    scrollInToView,
    LIMIT_DEFAULT_DATA,
    STANDARD_LIMIT_OPTIONS,
    OWNED_INVENTORIES_REQUEST_DEFAULT_DATA
} from "utils";
import {resetRefetchInventory} from "store/Locker/lockerSlice";
import {useGetInventoryQuery} from "store/Locker/locker.api";
import {createXsollaFetch} from "config/createXsollaFetch";
import {XSOLLA_STORE} from "store/endpoint";

import {NoCosmetic, PaginationArrowWithTools, OwnedCard} from "components/molecules";
import {GridContainer, SkeletonNftCart} from "components/atoms";

const LockerOwnedCosmetics = ({parentSectionRef}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [limitValue, setLimitValue] = useState(LIMIT_DEFAULT_DATA)
    const [requestState, setRequestState] = useState(OWNED_INVENTORIES_REQUEST_DEFAULT_DATA)

    const {data, isLoading, isFetching, refetch} = useGetInventoryQuery({...requestState})
    const {refetchInventory} = useSelector(state => state.locker)

    useEffect(() => {
        if (refetchInventory) {
            refetch()
            dispatch(resetRefetchInventory())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchInventory]);

    useEffect(() => {
        if (data && data.is_voolah_available) {
            setRequestState(prev => ({...prev, offset: 1}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const navigateTo = async (inventory) => {
        const {is_nft, sku} = inventory
        const state = {state: {request: true}}

        if (is_nft) {
            const baseUrl = ENVIRONMENTS.XSOLLA_STORE_TARGET_SERVER
            const endpoint = XSOLLA_STORE.GET_CURRENT_INVENTORY(ENVIRONMENTS.XSOLLA_PROJECT_ID, sku)

            const response = await createXsollaFetch({baseUrl, endpoint, requiresAuthorization: true})
            const tokenId = response.data.custom_attributes.token_id

            navigate(`/locker/inventory/${sku}/${tokenId}`, state)
        } else {
            navigate(`/locker/inventory/${sku}`, state)
        }
    }

    const updateRequestState = (newState) => setRequestState({...requestState, ...newState});

    const limitValueHandler = (event) => {
        scrollInToView(parentSectionRef)

        const {value} = event.target.value;
        setLimitValue(value)

        updateRequestState({offset: 0, limit: value.value});
    }

    const pageChangeHandler = (arrow) => {
        scrollInToView(parentSectionRef)

        const {offset, limit} = requestState;
        const newSkip = arrow === "next" ? offset + limit : offset - limit;

        updateRequestState({offset: newSkip});
    }

    const inventoriesDataLength = data?.owned_inventories?.length

    return (
        <div className="locker_cosmetics_content_general_owned">
            {(isLoading || data.is_voolah_available || isFetching)
                ? <GridContainer>
                    <SkeletonNftCart
                        type="owned"
                        cartLineCount={inventoriesDataLength ?? requestState.limit}
                    />
                </GridContainer>
                : inventoriesDataLength
                    ? <GridContainer>
                        {data?.owned_inventories?.map(inventory =>
                            <OwnedCard
                                name={inventory.name}
                                key={inventory.item_id}
                                img={inventory.image_url}
                                count={inventory.quantity}
                                chainId={inventory.chain_id}
                                rarity={`card_${inventory.rarity}`}
                                onClick={() => navigateTo(inventory)}
                                trade={inventory.is_nft ? INVENTORY_TYPE.WEB3 : INVENTORY_TYPE.WEB2}
                            />
                        )}
                    </GridContainer>
                    : !inventoriesDataLength && <NoCosmetic text={PROCESS.NO_COSMETICS}/>}
            {!!inventoriesDataLength && <PaginationArrowWithTools
                limitValue={limitValue}
                offset={requestState.offset}
                callBackLimit={limitValueHandler}
                callBackArrow={pageChangeHandler}
                dataCount={inventoriesDataLength}
                limitData={STANDARD_LIMIT_OPTIONS}
            />}
        </div>
    )
}

export default memo(LockerOwnedCosmetics)