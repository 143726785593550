import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {authV2Service} from "./AuthV2Service";

const initialState = {
    signUp: {
        loading: false,
        success: false,
        error: false,
        errorMessage: null,
        data: {}
    },

    signIn: {
        loading: false,
        success: false,
        error: false,
        errorMessage: null,
        userData: {}
    },

    recoverPassword: {
        loading: false,
        success: false,
        error: false,
        errorMessage: null
    },

    checkNickname: {
        loading: false,
        success: false,
        error: false,
        errorMessage: null,
        isValidNickname: null,
        isValidInfo: null
    },

    changeNickname: {
        loading: false,
        success: false,
        error: false,
        errorMessage: null,
    },

    userVerification: {
        loading: false,
        success: false,
        error: false,
        errorMessage: null,
        registration: false,
        socialLogin: false,
    },

    signUpSocial: {
        loading: false,
        success: false,
        error: false,
        errorMessage: null,
    },

    addWallet: {
        loading: false,
        success: false,
        error: false,
        errorMessage: null
    },

    userDataXsolla: {
        loading: false,
        success: false,
        error: false,
    }
}

export const userSignUp = createAsyncThunk("user/signUp", async (data, thunkAPI) => {
    try {
        return await authV2Service.userSignUp(data, thunkAPI)
    } catch (error) {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

export const userSignIn = createAsyncThunk("user/signin", async (data, thunkAPI) => {
    try {
        return await authV2Service.userSignIn(data, thunkAPI)
    } catch (error) {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

export const userRecoverPassword = createAsyncThunk("user/recover/password", async (data, thunkAPI) => {
    try {
        return await authV2Service.userRecoverPassword(data, thunkAPI)
    } catch (error) {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

export const userCheckNickname = createAsyncThunk("user/check/nickname", async (data, thunkAPI) => {
    try {
        return await authV2Service.userCheckNickname(data, thunkAPI)
    } catch (error) {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

export const userChangeNickname = createAsyncThunk("user/change/nickname", async (data, thunkAPI) => {
    try {
        return await authV2Service.userChangeNickname(data, thunkAPI)
    } catch (error) {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

export const userGetData = createAsyncThunk("user/get/data", async (data, thunkAPI) => {
    try {
        return await authV2Service.userGetData(data, thunkAPI)
    } catch (error) {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

export const userVerificationSocial = createAsyncThunk("user/verification/social", async (data, thunkAPI) => {
    try {
        return await authV2Service.userVerificationSocial(data, thunkAPI)
    } catch (error) {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

export const userSignUpSocial = createAsyncThunk("user/signUp/social", async (data, thunkAPI) => {
    try {
        return await authV2Service.userSignUpSocial(data, thunkAPI)
    } catch (error) {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

export const userAddWallet = createAsyncThunk("user/add/wallet", async (data, thunkAPI) => {
    try {
        return await authV2Service.userAddWallet(data, thunkAPI)
    } catch (error) {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

export const userGetVoolahBalance = createAsyncThunk("user/get/voolah/balance", async (_, thunkAPI) => {
    try {
        const jwt = thunkAPI.getState().authV2.signIn.userData.accessToken

        return await authV2Service.getUserVoolahBalance(jwt)
    } catch (error) {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

export const syncXsollaUserToDB = createAsyncThunk("sync/user/db", async (_, thunkAPI) => {
    try {
        return await authV2Service.syncXsollaUserToDB(thunkAPI)
    } catch {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

const authV2Slice = createSlice({
    name: "authV2",
    initialState,
    reducers: {
        reset: (state, action) => {
            state[action.payload].loading = false
            state[action.payload].success = false
            state[action.payload].error = false
            state[action.payload].errorMessage = null
        },
        setData: (state, action) => {
            const {path1, path2, data} = action.payload
            state[path1][path2] = data
        },
        resetNickname: (state, action) => {
            state.checkNickname.error = true
            state.checkNickname.loading = false
            state.checkNickname.success = false
            state.checkNickname.isValidNickname = null
            state.checkNickname.errorMessage = null
        },
        setBalance: (state, action) => {
            const {currencyType, balance} = action.payload
            state.signIn.userData[currencyType] = balance
        }
    },
    extraReducers: (builder => {
        builder
            .addCase(userSignUp.pending, (state) => {
                state.signUp.loading = true
            })
            .addCase(userSignUp.fulfilled, (state, action) => {
                state.signUp.loading = false
                state.signUp.success = true
                state.signUp.error = false
                state.signUp.errorMessage = null
                state.signIn.userData.accessToken = action.payload
                window._paq.push(['trackEvent', 'Sign up', 'Success'])
            })
            .addCase(userSignUp.rejected, (state, action) => {
                state.signUp.loading = false
                state.signUp.success = false
                state.signUp.error = true
                state.signUp.errorMessage = action.payload
            })

            .addCase(userSignIn.pending, (state) => {
                state.signIn.loading = true
            })
            .addCase(userSignIn.fulfilled, (state, action) => {
                state.signIn.loading = false
                state.signIn.success = true
                state.signIn.error = false
                state.signIn.errorMessage = null
                state.signIn.userData = action.payload
                window._paq.push(['trackEvent', 'Sign in', 'Success'])
            })
            .addCase(userSignIn.rejected, (state, action) => {
                state.signIn.loading = false
                state.signIn.success = false
                state.signIn.error = true
                state.signIn.errorMessage = action.payload.message || action.payload
            })

            .addCase(userRecoverPassword.pending, (state) => {
                state.recoverPassword.loading = true
            })
            .addCase(userRecoverPassword.fulfilled, (state, action) => {
                state.recoverPassword.loading = false
                state.recoverPassword.success = true
                state.recoverPassword.error = false
                state.recoverPassword.errorMessage = null
            })
            .addCase(userRecoverPassword.rejected, (state, action) => {
                state.recoverPassword.loading = false
                state.recoverPassword.success = false
                state.recoverPassword.error = true
                state.recoverPassword.errorMessage = action.payload
            })

            .addCase(userCheckNickname.pending, (state) => {
                state.checkNickname.loading = true
            })
            .addCase(userCheckNickname.fulfilled, (state, action) => {
                state.checkNickname.loading = false
                state.checkNickname.success = true
                state.checkNickname.isValidNickname = action.payload === 200
                state.checkNickname.errorMessage = ""
            })
            .addCase(userCheckNickname.rejected, (state, action) => {
                state.checkNickname.error = true
                state.checkNickname.loading = false
                state.checkNickname.success = false
                state.checkNickname.isValidNickname = false
                state.checkNickname.errorMessage = action.payload
            })

            .addCase(userChangeNickname.pending, (state) => {
                state.changeNickname.loading = true
            })
            .addCase(userChangeNickname.fulfilled, (state, action) => {
                state.changeNickname.loading = false
                state.changeNickname.success = true
                window._paq.push(['trackEvent', 'Nickname', 'Success'])
                window._paq.push(['trackEvent', 'Nickname-more', `${action.payload}`])
            })
            .addCase(userChangeNickname.rejected, (state, action) => {
                state.changeNickname.error = true
                state.changeNickname.loading = false
                state.changeNickname.success = false
                state.changeNickname.errorMessage = action.payload
            })

            .addCase(userGetData.pending, (state) => {
                state.userDataXsolla.loading = true
                state.signIn.loading = true
            })
            .addCase(userGetData.fulfilled, (state, action) => {
                state.userDataXsolla.loading = false
                state.userDataXsolla.success = true
                state.userDataXsolla.error = false
                state.signIn.loading = false
                state.signIn.success = true
                state.signIn.error = false
                state.signIn.userData = action.payload
            })
            .addCase(userGetData.rejected, (state, action) => {
                state.userDataXsolla.loading = false
                state.userDataXsolla.success = false
                state.userDataXsolla.error = true
                state.signIn.loading = false
                state.signIn.success = false
                state.signIn.error = true
            })

            .addCase(userVerificationSocial.pending, (state) => {
                state.userVerification.loading = true
                state.userVerification.socialLogin = true
            })
            .addCase(userVerificationSocial.fulfilled, (state, action) => {
                state.userVerification.loading = false
                state.userVerification.success = true
                state.signIn.success = true
                state.userVerification.registration = action.payload.type === "registration"
                state.signIn.userData = action.payload.type === "firstLogin" ? action.payload.user : {accessToken: action.payload.token}
                window._paq.push(['trackEvent', 'Sign in', 'Success'])
            })
            .addCase(userVerificationSocial.rejected, (state, action) => {
                state.userVerification.error = true
                state.userVerification.loading = false
                state.userVerification.success = false
                state.userVerification.errorMessage = action.payload
            })

            .addCase(userSignUpSocial.pending, (state) => {
                state.signUpSocial.loading = true
            })
            .addCase(userSignUpSocial.fulfilled, (state) => {
                state.signUpSocial.loading = false
                state.signUpSocial.success = true
            })
            .addCase(userSignUpSocial.rejected, (state, action) => {
                state.signUpSocial.error = true
                state.signUpSocial.loading = false
                state.signUpSocial.success = false
                state.signUp.errorMessage = action.payload
            })

            .addCase(userAddWallet.pending, (state) => {
                state.addWallet.loading = true
            })

            .addCase(userAddWallet.fulfilled, (state, action) => {
                state.addWallet.loading = false
                state.addWallet.success = true
                state.signIn.userData.wallet = action.payload
            })
            .addCase(userAddWallet.rejected, (state, action) => {
                state.addWallet.loading = false
                state.addWallet.success = false
                state.addWallet.error = true
                state.addWallet.errorMessage = action.payload
            })

            .addCase(userGetVoolahBalance.fulfilled, (state, action) => {
                state.signIn.userData.voolahBalance = action.payload
            })
            .addCase(userGetVoolahBalance.rejected, (state) => {
                state.signIn.userData.voolahBalance = "0"
            })
    })
})

export const {reset, setData, resetNickname, setBalance} = authV2Slice.actions
export default authV2Slice.reducer