import React, {memo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import classNames from "classnames";

import {LISTING_DAY_OPTIONS, ERROR, STEPS_STATUS, errorHandler} from "utils";
import {useListCurrentInventoryMutation} from "store/Bazaar/bazaar.api";
import {useConnectedWallet, useConnectWallet, useUtils} from "hooks";
import {getCurrentCosmetic} from "store/Locker/lockerSlice";
import {useModalsContext} from "layouts";

import {Button, Input, SelectDropDownDisplay, Status} from "components/atoms";
import CosmeticViewTemplate from "components/molecules/CosmeticViewTemplate";
import {useLockerCurrentCosmetic} from "./useLockerCurrentCosmetic";

import "./style.scss"

const LockerCurrentNotListedWeb3CosmeticSection = ({sku, tokenId, data, isLoading}) => {
    const {isConnected, isConnectedAddressCorrect} = useConnectedWallet()
    const {openWalletModal, disconnectWallet} = useConnectWallet()
    const {getChainDataById, addChain, switchChain} = useUtils()
    const {setCurrentModal} = useModalsContext()

    const dispatch = useDispatch()

    const [listInventory, {isSuccess, isError, isLoading: isLoadingListing}] = useListCurrentInventoryMutation()
    const {currentCosmetic} = useSelector(state => state.locker)

    const currentCosmeticStyle = classNames("locker-current-cosmetic_section", data?.rarity ?? "ordinary")

    const [expires, setExpires] = useState({name: "7 Days", value: 7})
    const [isStartCreatingOrder, setIsStartCreatingOrder] = useState(false)
    const [fieldsError, setFieldsError] = useState("")
    const [listPrice, setListPrice] = useState("")

    const {createOrder} = useLockerCurrentCosmetic(expires, listPrice, data?.token_id)

    useEffect(() => {
        if (isStartCreatingOrder) {
            startCreatingOrder()
            setIsStartCreatingOrder(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStartCreatingOrder]);

    useEffect(() => {
        if (tokenId) {
            dispatch(getCurrentCosmetic({token_id: tokenId}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenId])

    useEffect(() => {
        if (isError && !isLoadingListing) {
            setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.LISTING_FAIL_UPDATE})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, isError])

    const listNow = async () => {
        if (!listPrice || !expires) {
            setFieldsError("All fields are required")
        } else {
            setFieldsError(ERROR.CLEAN)
            setCurrentModal({status: STEPS_STATUS.PROCESS})

            if (!isConnectedAddressCorrect) {
                setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.WALLET_CONNECTED_MATCH_INVALID})
                disconnectWallet()

                return
            }

            try {
                const {chain_id} = data
                const {chainIdHex, chainId} = getChainDataById(chain_id)
                const {status, error} = await switchChain(chainIdHex)

                if (error) errorHandler(ERROR.CLEAN)

                if (!status && error.code === 4902) {
                    const {error} = await addChain(chainId)

                    if (error) errorHandler(ERROR.CLEAN)
                }

                setIsStartCreatingOrder(true)
            } catch (error) {
                setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.WALLET_FAILED})
            }
        }
    }

    const startCreatingOrder = async () => {
        try {
            const {sellOrder, sellSignature} = await createOrder(data.chain_id)

            const order = await listInventory({
                inventory_sku: sku,
                currency: "kompete",
                order: sellOrder,
                signature: sellSignature
            })

            if (order.data.success) {
                setCurrentModal({status: STEPS_STATUS.LISTING_SUCCESS, data: {...data, order: {...order.data.data}}})
                setExpires({name: "7 Days", value: 7})
                setListPrice("")
            } else {
                setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.LISTING_FAIL_UPDATE})
            }
        } catch (error) {
            setCurrentModal({status: STEPS_STATUS.REJECT, message: error.message})
        }
    }

    return (
        <section className={currentCosmeticStyle}>
            <CosmeticViewTemplate
                isShare={false}
                isTransfer={true}
                title={data?.name}
                rarity={data?.rarity}
                img={data?.image_url}
                chain={data?.chain_id}
                attribute={data?.groups}
                loading={isLoading || currentCosmetic.loading}
                isNeverSoldAgain={currentCosmetic.data?.never_sold_again}
                classname="locker-current-cosmetic_section_content container"
                transferCallBack={() => setCurrentModal({status: "transfer", data})}
            >
                <div className="locker-current-cosmetic_section_not-listed">
                    <Status type="reject">Not listed</Status>
                    <div className="locker-current-cosmetic_section_not-listed_inp">
                        <Input
                            name="price"
                            type="number"
                            value={listPrice}
                            error={fieldsError}
                            placeholder="List Price (KT)"
                            status={fieldsError ? "error" : "default"}
                            onChange={(e) => setListPrice(e.target.value)}
                        />
                        <SelectDropDownDisplay
                            value={expires}
                            btnText="Expires"
                            optionsList={LISTING_DAY_OPTIONS}
                            status={fieldsError ? "error" : "default"}
                            onChange={(e) => setExpires(e.target.value.value)}
                        />
                        <Button
                            view="secondary"
                            onClick={() => isConnected ? listNow() : openWalletModal()}
                        >
                            {isConnected ? "List Now" : "Connect wallet"}
                        </Button>
                    </div>
                    <div className="locker-current-cosmetic_section_not-listed_link">
                        <p>By clicking "List Now", you agree to <Link to="/"> Terms of Service</Link></p>
                    </div>
                </div>
            </CosmeticViewTemplate>
        </section>
    )
}

export default memo(LockerCurrentNotListedWeb3CosmeticSection)