import React from "react";

import SocialButtons from "../ReusableComponents/SocialButtons";
import ModalWindowTemplate from "components/molecules/ModalWindowTemplate";
import ChangeStepButtonTemplate from "../ReusableComponents/ChangeStepButtonTemplate";

const StepSignUp = ({show, changeStep}) => {

    return (
        <ModalWindowTemplate
            show={show}
            privacy={true}
            powered={true}
            paddingTop={true}
            className={"auth-step_step4"}
            changeStep={changeStep}
            title={<span>Create your<br/>  KOMPETE Account</span>}
        >
            <SocialButtons text="create" changeStep={changeStep}/>
            <ChangeStepButtonTemplate type="signIn" changeStep={changeStep}/>
        </ModalWindowTemplate>
    )
}

export default StepSignUp