import React from "react";
import classNames from "classnames";

import ModalWindowTemplate from "components/molecules/ModalWindowTemplate";
import {Button, Image, Rarity} from "components/atoms";

import "./style.scss"

const CosmeticModalTemplate = (
    {
        show,
        title,
        image,
        name,
        rarity,
        quantity,
        buttonTwo,
        buttonOne,
        children,
        closeClick,
        showInfo = true,
        isShowClose = false,
        contentClassName = ""
    }
) => {
    const contentStyle = classNames("cosmetic-modal-template_content_main", contentClassName)

    return (
        <ModalWindowTemplate
            title={title}
            show={show}
            isShowClose={isShowClose}
            changeStep={closeClick}
            size="modal-lg"
            className="cosmetic-modal-template"
        >
            <div className="cosmetic-modal-template_content">
                {showInfo && <div className="cosmetic-modal-template_content_info">
                    <div className={`cosmetic-modal-template_content_info_img ${rarity}-small`}>
                        <Image alt="cosmetic" src={image}/>
                    </div>
                    <div className="cosmetic-modal-template_content_info_name">
                        <div>
                            <h6>{name}</h6>
                            <div className="cosmetic-modal-template_content_info_name_rarity">
                                <Rarity
                                    text={rarity}
                                    rarity={rarity}
                                    size="lg"
                                />
                            </div>
                        </div>
                        <div>
                            {quantity && <p>X {quantity}</p>}
                        </div>
                    </div>
                </div>}
                {children && <div className={contentStyle}>{children}</div>}
                <div className="cosmetic-modal-template_content_btns">
                    {buttonOne &&
                        <Button
                            customStyle="cosmetic-modal-template_content_btns_first"
                            view="secondary"
                            size="md"
                            onClick={() => buttonOne.callBack()}
                        >
                            {buttonOne.text}
                        </Button>}
                    {buttonTwo &&
                        <Button
                            view="opacity"
                            size="md"
                            onClick={() => buttonTwo.callBack()}
                        >
                            {buttonTwo.text}
                        </Button>
                    }
                </div>
            </div>
        </ModalWindowTemplate>
    )
}

export default CosmeticModalTemplate