import {useSelector} from "react-redux";
import React, {memo} from "react"

import {png} from "assets/png";
import "./style.scss"

const LockerMain = () => {
    const {userData} = useSelector(state => state.authV2.signIn)

    return (
        <section className="locker container">
            <div className="locker_main">
                <div className="locker_main_container">
                    <div className="locker_main_container_user-info">
                        <img alt="avatar" src={userData.picture ? userData.picture : png.NewAvatar}/>
                        <span className="locker_main_container_user-info_name">{userData.nickname}</span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default memo(LockerMain)