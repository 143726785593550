import React from "react";
import ProgressiveImage from "components/atoms/ProgressiveImage";
import Title from "components/atoms/TitleTemplate";

import {png} from "assets/png";
import {Button} from "../../atoms";
import {useNavigate} from "react-router-dom";

const TokenBuyingGuid = () => {
    const navigate = useNavigate()

    return (<section className="token-buying-guid">
        <div className="container">
            <Title underline={true}>Buying <span>guide</span></Title>
            <div className="token-buying-guid_content">
                <div>
                    <p>Need help buying KOMPETE Token?</p>
                    <p>We got you covered! Follow our easy guide.</p>
                    <Button onClick={() => navigate("/buy-kompete-token")}>Buy Now</Button>
                </div>
                <div>
                    <ProgressiveImage
                        imgSrc={png.KompeteBackgroundLightPng}
                        previewSrc={png.KompeteBackgroundLightWebp}
                    />
                </div>
            </div>
        </div>
    </section>)
}

export default TokenBuyingGuid