import React from "react"
import Title from "../../atoms/TitleTemplate";

import {svg} from "assets/svg";
import "./style.scss"

const data = [
    {
        name: "Multiple fleshed out game modes",
        icon: svg.WhyIcon1,
        backgroundImage: "one"
    },
    {
        name: "free-to-play",
        icon: svg.WhyIcon2,
        backgroundImage: "two"
    },
    {
        name: "cross-platform play",
        icon: svg.WhyIcon3,
        backgroundImage: "three"
    },
    {
        name: "Cross-progression",
        icon: svg.WhyIcon4,
        backgroundImage: "four"
    },
    {
        name: "photorealistic graphics",
        icon: svg.WhyIcon5,
        backgroundImage: "five"
    },
    {
        name: "modular character customizer",
        icon: svg.WhyIcon6,
        backgroundImage: "six"
    },
    {
        name: "attributes & traits system",
        icon: svg.WhyIcon7,
        backgroundImage: "seven"
    },
    {
        name: "xp progression systems",
        icon: svg.WhyIcon8,
        backgroundImage: "eight"
    },
    {
        name: "consistent content updates",
        icon: svg.WhyIcon9,
        backgroundImage: "nine"
    },
    {
        name: "new game modes to be added",
        icon: svg.WhyIcon10,
        backgroundImage: "ten"
    },
    {
        name: "social party system",
        icon: svg.WhyIcon11,
        backgroundImage: "eleven"
    },
    {
        name: "matchmaking enabled",
        icon: svg.WhyIcon12,
        backgroundImage: "twelve"
    },
]

const HomeWhy = () => {

    return (
        <section className="home_why-kompete">
            <Title underline={true}>why <span>kompete</span>?</Title>
            <div className="home_why-kompete_content container">
                <div className="home_why-kompete_content_why-list">
                    {data.map((elm, i) =>
                        <WhyItem key={i} img={elm.icon} background={elm.backgroundImage}>{elm.name}</WhyItem>
                    )}
                </div>
            </div>
        </section>
    )
}

const WhyItem = ({children, img, background}) => {
    return (
        <div className={`home_why-kompete_content_why-list_item ${background}`}>
            <div className="home_why-kompete_content_why-list_item_block">
                    <h5>{children}</h5>
            </div>
        </div>
    )
}

export default HomeWhy