import React, {memo} from "react";

import {useAuthorization} from "hooks";

import OptionsBlock from "./OptionsBlock";
import {Button} from "components/atoms";
import SwitchBlock from "./SwitchBlock";
import InfoBlock from "./InfoBlock";

import "./../style.scss"

const UserLoginDisplay = ({signOut, changeStep, SetMobileMenu}) => {
    const {accessToken} = useAuthorization()

    return (
        <React.Fragment>
            {accessToken
                ? <div className="navigation_auth_user-details">
                    <div className="navigation_auth_user-details_panel">
                        <SwitchBlock/>
                        <InfoBlock/>
                        <OptionsBlock signOut={signOut} SetMobileMenu={SetMobileMenu}/>
                    </div>
                </div>
                : <div className="navigation_auth_signIn-btn">
                    <Button size="sm" view="white" onClick={() => changeStep("step1")}>Sign in</Button>
                </div>}
        </React.Fragment>
    )
}

export default memo(UserLoginDisplay)