import React from "react"

import TokenWhitePaper from "components/organisms/Token/TokenWhitePaper";
import TokenBuyingGuid from "components/organisms/Token/TokenBuyingGuid";
import TokenThree from "components/organisms/Token/TokenThree";
import TokenSeven from "components/organisms/Token/TokenSeven";
import TokenHero from "components/organisms/Token/TokenHero";
import TokenFour from "components/organisms/Token/TokenFour";
import TokenNine from "components/organisms/Token/TokenNine";
import TokenSix from "components/organisms/Token/TokenSix";
import DefaultLayout from "layouts/DefaultLayout";
import Seo from "components/atoms/Seo";

const Token = () => {
    return (
        <DefaultLayout>
            <Seo
                title="KOMPETE Token - 0x1E0b2992079B620AA13A7c2E7c88D2e1E18E46E9"
                description="KOMPETE Token is the official marketplace currency for the KOMPETE Bazaar."
            />
            <TokenHero/>
            <TokenFour/>
            <TokenBuyingGuid/>
            <TokenSix/>
            <TokenSeven/>
            <TokenWhitePaper/>
            <TokenThree/>
            <TokenNine/>
        </DefaultLayout>
    )
}

export default Token