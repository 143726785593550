import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {userSignUp, setData, userSignUpSocial} from "store/AuthV2/AuthV2Slice";
import {ERROR} from "utils";

import {
    StepSignIn,
    StepSignInForm,
    StepRecoverPassword,
    StepSignUp,
    StepSignUpForm,
    StepIsConnectWallet,
    StepSignUpSuccess,
    StepRecoverPasswordSuccess,
    StepNickname
} from "./Steps"
import {LoadSpinnerAllPage} from "components/atoms";
import {RejectModal} from "components/molecules";

const AuthV2Steps = ({stepData, setStepData, changeStep, fieldHandle}) => {
    const dispatch = useDispatch()

    const {signUp, signUpSocial, signIn} = useSelector(state => state.authV2)
    const {email, password, checkbox1, checkbox2, checkbox3} = stepData.steps.step5.fields
    const {fields} = stepData.steps.step8

    const checkMail = () => {
        const checkTheMailRegexp = /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!mail.ru)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/g

        if (!stepData.currentEmail.match(checkTheMailRegexp)) {
            window.open("https://" + stepData.currentEmail.split("@")[1])
            changeStep("")
        } else {
            changeStep("")
        }
    }

    const checkFields = (field) => {
        const {currentStep} = stepData

        for (let field in stepData.steps[currentStep].fields) {
            stepData.steps[currentStep].fields[field].validation = !stepData.steps[currentStep].fields[field].value
        }

        if (field) {
            stepData.steps[currentStep].fields[field].validation = true
        }

        setStepData(stepData)
    }

    const registerUserDispatch = (registerType) => {
        if (registerType === "regular") {
            let userInfo = {
                email: email.value,
                password: password.value,
                terms: checkbox1.value,
                offers: checkbox2.value,
                is13years: checkbox3.value,
            }

            dispatch(userSignUp(userInfo))
            dispatch(setData({path1: "signUp", path2: "fieldsData", data: userInfo}))
        }

        if (registerType === "social") {
            let userInfo = {
                terms: fields.checkbox1.value,
                offers: fields.checkbox2.value,
                is13years: fields.checkbox3.value,
            }

            dispatch(userSignUpSocial({fields: userInfo, token: signIn.userData?.accessToken}))
        }
    }

    return (
        <React.Fragment>
            {
                {
                    "step1":
                        <StepSignIn
                            show={stepData.currentStep === "step1"}
                            setStepData={setStepData}
                            changeStep={changeStep}
                        />,
                    "step2":
                        <StepSignInForm
                            show={stepData.currentStep === "step2"}
                            stepData={stepData}
                            setStepData={setStepData}
                            changeStep={changeStep}
                            fieldHandle={fieldHandle}
                            checkFields={checkFields}
                        />,
                    "step3":
                        <StepRecoverPassword
                            show={stepData.currentStep === "step3"}
                            stepData={stepData}
                            setStepData={setStepData}
                            changeStep={changeStep}
                            fieldHandle={fieldHandle}
                        />,
                    "step4":
                        <StepSignUp
                            show={stepData.currentStep === "step4"}
                            changeStep={changeStep}
                        />,
                    "step5":
                        <StepSignUpForm
                            show={stepData.currentStep === "step5"}
                            changeStep={changeStep}
                            stepData={stepData}
                            setStepData={setStepData}
                            fieldHandle={fieldHandle}
                            checkFields={checkFields}
                            registerUserDispatch={registerUserDispatch}
                        />,
                    "step6":
                        <StepIsConnectWallet
                            show={stepData.currentStep === "step6"}
                            changeStep={changeStep}
                            setStepData={setStepData}
                            stepData={stepData}
                        />,
                    "step7":
                        <StepSignUpSuccess
                            show={stepData.currentStep === "step7"}
                            changeStep={changeStep}
                        />,
                    "step8":
                        <StepSignUpForm
                            show={stepData.currentStep === "step8"}
                            changeStep={changeStep}
                            stepData={stepData}
                            fieldHandle={fieldHandle}
                        />,
                    "step9":
                        <RejectModal
                            show={true}
                            text1="Oooops!"
                            text2={ERROR.WALLET_CONNECTED_USED_INVALID}
                            isShowClose={true}
                            closeClick={() => changeStep("step6")}
                        />,
                    "step13":
                        <StepRecoverPasswordSuccess
                            show={stepData.currentStep === "step13"}
                            changeStep={changeStep}
                            stepData={stepData}
                            checkMail={checkMail}
                        />,
                    "step14":
                        <StepNickname
                            show={stepData.currentStep === "step14"}
                            stepData={stepData}
                            changeStep={changeStep}
                        />,
                }[stepData.currentStep]
            }
            <LoadSpinnerAllPage show={(signUp.loading || signUpSocial.loading)}/>
        </React.Fragment>
    )
}
export default AuthV2Steps