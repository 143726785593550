import React, {memo} from "react"

import LockerCurrentCosmeticTransferSuccessModal
    from "./LockerCurrentCosmetic/LockerCurrentCosmeticTransferSuccessModal";
import LockerCurrentCosmeticTransferModal from "./LockerCurrentCosmetic/LockerCurrentCosmeticTransferModal";
import {RejectModal, ProcessModal, SuccessModal} from "components/molecules";
import LockerConvertSuccessModal from "./Konvert/LockerConvertSuccessModal";
import {useModalsContext} from "layouts";
import {ERROR, PROCESS} from "utils";

const LockerSteps = ({changeTab}) => {
    const {currentModal, setCurrentModal} = useModalsContext()

    return (
        <React.Fragment>
            {
                {
                    "transfer":
                        <LockerCurrentCosmeticTransferModal/>,
                    "transfer-success":
                        <LockerCurrentCosmeticTransferSuccessModal/>,
                    "konvert-success":
                        <LockerConvertSuccessModal changeTab={changeTab}/>,
                    "transfer-reject":
                        <RejectModal
                            show={true}
                            text1="Ooops!"
                            text2={currentModal?.message ? currentModal.message : ERROR.TRANSFER_FAILED}
                            closeClick={() => setCurrentModal({status: ""})}
                        />,
                    "process":
                        <ProcessModal
                            show={true}
                            text1={currentModal.message ? currentModal.message : "Waiting for transfer..."}
                            text2={PROCESS.APPROVAL}
                        />,
                    "reject":
                        <RejectModal
                            show={true}
                            text1="Oooops!"
                            text2={currentModal.message}
                            isShowClose={true}
                            closeClick={() => setCurrentModal({status: ""})}
                        />,
                    "wallet-success":
                        <SuccessModal
                            show={true}
                            text1="Success!"
                            text2={currentModal?.message}
                            isShowClose={false}
                        />
                }
                    [currentModal.status]
            }
        </React.Fragment>
    )
}

export default memo(LockerSteps)