export const ERROR = {
    CLEAN: "",
    WRONG: "Something went wrong",
    INVALID_SELL_ORDER_PARAMS: "Sell Order parameters invalid!",
    INVALID_BUY_ORDER_PARAMS: "Buy Order parameters invalid!",
    INVALID_SELL_ORDER: "Sell Order invalid!",
    INVALID_BUY_ORDER: "Buy Order invalid!",
    INVALID_MATCH: "Order failed! Please try again soon!",
    USER_DENIED_SIGNATURE: "User denied transaction signature!",
    WALLET_CONNECTED_MATCH_INVALID: "The web3 wallet you connected doesn't match the registered wallet!",
    WALLET_FAILED: "Web3 Wallet failed to connect!",
    WALLET_CONNECTED_USED_INVALID: "The web3 wallet you connected is being used",
    TRANSACTION_FAILED: "Transaction failed!",
    LISTING_FAIL_UPDATE: "The listing was not updated!",
    ALLOWANCE_INVALID: "The approved tokens are less than needed for transaction!",
    BALANCE_INVALID: "The balance is less than needed for the transaction!",
    TRANSFER_FAILED: "Your Cosmetic was not transferred",
    PAYMENT_FAILED: "The payment is failed!",
    INVALID_CHAIN: "Incorrect chain",
    KONVERT_COOLDOWN: "Konvert Cooldown: Please try again a bit later",
    ITEM_OWNERSHIP: "You Don't Own this Item",
    FEW_SOCKETS: "You can only konvert in one tab. Please close any other open tabs to continue",
    ALL_FIELDS_REQUIRED: "All fields are required",
    FAIL_REQUEST: "User rejected the request.",
    NO_COSMETIC: "The cosmetic has already been sold or removed",
}