import React from "react";
import {useNavigate} from "react-router-dom"

import ProgressiveImage from "components/atoms/ProgressiveImage";
import Button from "components/atoms/Button";

import {png} from "assets/png";
import "./style.scss"

const HomeMain = () => {
    const navigate = useNavigate()

    return (
        <section className="home_main">
                <ProgressiveImage
                    imgSrc={png.Hero3Png}
                    previewSrc={png.Hero3Webp}
                    className="home_main_back-img"
                    filter={true}
                />
            <div className="home_main_content">
                <h1>All-in-one <br/> multiplayer game</h1>
                <Button view="secondary" onClick={() => navigate("/download")}>Download now</Button>
            </div>
        </section>
    )
}

export default HomeMain