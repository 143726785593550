import React, {memo} from "react";
import classNames from "classnames";
import CountDown from "../index";
import "./style.scss"

const CountDownWithContainer = ({date, day, text, loading, success}) => {
    const timeBlockStyle = classNames("countdown-container_time", {skeleton: loading})

    return (
        <div className="countdown-container">
            <p className="countdown-container_text">
                {text}
            </p>
            <div className={timeBlockStyle}>
                {!success && !loading && <h2>Coming soon</h2>}
                {success && <CountDown date={date} day={day}/>}
            </div>
        </div>
    )
}

export default memo(CountDownWithContainer)