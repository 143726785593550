import React,{memo} from "react";

const ProfileUserInfoBlock = ({title,info}) => {
    return(
        <li>
            <span>{title}</span>
            <span>{info}</span>
        </li>
    )
}

export default memo(ProfileUserInfoBlock)