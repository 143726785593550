import React from "react";
import {useModalsContext} from "layouts";
import {RejectModal, SuccessModal} from "components/molecules";

const ProfileSteps = () => {
    const {currentModal, setCurrentModal} = useModalsContext()

    return (
        <React.Fragment>
            {
                {
                    "wallet-success":
                        <SuccessModal
                            show={true}
                            text1="Success!"
                            text2={currentModal?.message}
                            isShowClose={false}
                        />,
                    "reject":
                        <RejectModal
                            show={true}
                            text1="Oooops!"
                            text2={currentModal.message}
                            isShowClose={true}
                            closeClick={() => setCurrentModal({status: ""})}
                        />,

                }[currentModal.status]
            }
        </React.Fragment>
    )
}

export default ProfileSteps