import React, {useEffect, memo} from "react";

import {useConnectedWallet, useConnectWallet, useUtils} from "hooks";
import {useModalsContext} from "layouts";
import {ERROR, STEPS_STATUS, SUCCESS} from "utils";

import LockerCurrentCosmeticTransferSuccessModal from "./LockerCurrentCosmeticTransferSuccessModal";
import LockerCurrentCosmeticListingSuccessModal from "./LockerCurrentCosmeticListingSuccessModal";
import LockerCurrentCosmeticTransferModal from "./LockerCurrentCosmeticTransferModal";

import {SuccessModal, ProcessModal, RejectModal} from "components/molecules";

const LockerCurrentCosmeticSteps = () => {
    const {currentModal, setCurrentModal} = useModalsContext()

    const {isConnected, isWalletConnection, isConnectedAddressCorrect} = useConnectedWallet()
    const {disconnectWallet} = useConnectWallet()
    const {switchChain} = useUtils()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => walletChecking(), [isConnected]);

    const walletChecking = async () => {
        if (isConnected && !isWalletConnection) {
            setCurrentModal({status: "process", message: "Waiting for switch..."})
            const {status} = await switchChain()

            if (!status) {
                setCurrentModal({status: STEPS_STATUS.CLOSE})
                disconnectWallet()
            } else {
                if (!isConnectedAddressCorrect) {
                    setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.WALLET_CONNECTED_MATCH_INVALID})

                    disconnectWallet()
                } else {
                    setCurrentModal({status: STEPS_STATUS.SUCCESS, message: SUCCESS.WALLET_CONNECTED})
                    localStorage.setItem("wallet-connection", "CONNECT_SUCCESS")

                    setTimeout(() => {
                        setCurrentModal({status: STEPS_STATUS.CLOSE, message: ""})
                    }, 2000)
                }
            }
        }
    }

    return (
        <React.Fragment>
            {
                {
                    [STEPS_STATUS.TRANSFER]:
                        <LockerCurrentCosmeticTransferModal/>,
                    [STEPS_STATUS.TRANSFER_SUCCESS]:
                        <LockerCurrentCosmeticTransferSuccessModal/>,
                    [STEPS_STATUS.PROCESS]:
                        <ProcessModal
                            show={true}
                            text1={currentModal.message ?? "Waiting for signature..."}
                            text2="After wallet approval, your transaction will be finished shortly."
                        />,
                    [STEPS_STATUS.REJECT]:
                        <RejectModal
                            show={true}
                            text1="Ooops!"
                            text2={currentModal?.message}
                            closeClick={setCurrentModal}
                        />,
                    [STEPS_STATUS.SUCCESS]:
                        <SuccessModal
                            show={true}
                            text1="Success!"
                            text2={currentModal?.message}
                        />,
                    [STEPS_STATUS.LISTING_SUCCESS]:
                        <LockerCurrentCosmeticListingSuccessModal
                            show={true}
                            setCurrentModal={setCurrentModal}
                        />,
                }[currentModal.status]
            }
        </React.Fragment>
    )
}

export default memo(LockerCurrentCosmeticSteps)