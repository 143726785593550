import React from "react"
import PageMain from "components/atoms/PageMain";
import {png} from "assets/png";

const NewsMain = () => {
    return (
        <PageMain
            page="news"
            title="NEWS"
            text={<>Catch up on the latest updates<br/> & happenings in KOMPETE.</>}
            originalImage={png.HeroNews}
            resizeImage={png.HeroNewsResized}
            filter={true}
        />
    )
}

export default NewsMain