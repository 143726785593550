import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {CosmeticModalTemplate} from "components/molecules";
import {Input} from "components/atoms";

import {
    ERROR,
    STEPS_STATUS,
    errorHandler,
    isNegativeNumber,
    isValidEthereumAddress,
    TRANSFER_INPUTS_DEFAULT_DATA,
} from "utils";
import {useConnectedWallet, useConnectWallet, useUtils} from "hooks";
import {useGetCurrentInventoryQuery} from "store/Locker/locker.api";
import {setRefetchInventory} from "store/Locker/lockerSlice";
import {useModalsContext} from "layouts";
import {useLocker} from "../useLocker";

import "../style.scss"

const LockerCurrentCosmeticTransferModal = () => {
    const {isConnected, isConnectedAddressCorrect} = useConnectedWallet()
    const {openWalletModal, disconnectWallet} = useConnectWallet()
    const {getChainDataById, addChain, switchChain} = useUtils()
    const {currentModal, setCurrentModal} = useModalsContext()
    const {safeTransfer} = useLocker()

    const dispatch = useDispatch()

    const [inputsValue, setInputsValue] = useState(TRANSFER_INPUTS_DEFAULT_DATA)
    const [isTransferStart, setIsTransferStart] = useState(false)

    const {name, rarity, image_url, quantity: nftQuantity, sku} = currentModal.data

    const {data} = useGetCurrentInventoryQuery(sku)

    const buttonsOptions = {
        buttonOne: {
            click: () => setCurrentModal({status: STEPS_STATUS.CLOSE}),
            text: "cancel"
        },
        buttonTwo: {
            click: () => isConnected
                ? transferInputsValidation()
                : (openWalletModal() , setCurrentModal({status: STEPS_STATUS.CLOSE})),
            text: isConnected ? "transfer item" : "Connect wallet"
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => startTransferAfterChecking(), [isTransferStart])

    const startTransferAfterChecking = async () => {
        const {address, quantity} = inputsValue
        if (!address.validation && !quantity.validation && address.value && quantity.value) {
            if (!isConnectedAddressCorrect) {
                setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.WALLET_CONNECTED_MATCH_INVALID})
                disconnectWallet()
                return
            }

            setCurrentModal({status: STEPS_STATUS.PROCESS})

            try {
                const {chain_id} = data
                const {chainIdHex, chainId} = getChainDataById(chain_id)
                const {status, error} = await switchChain(chainIdHex)

                if (error) errorHandler(ERROR.FAIL_REQUEST)

                if (!status && error.code === 4902) {
                    const {error} = await addChain(chainId)

                    if (error) errorHandler(ERROR.FAIL_REQUEST)
                }

                await sendTransaction(chainId)
            } catch (error) {
                setCurrentModal({status: STEPS_STATUS.REJECT, message: error.message ?? ERROR.WALLET_FAILED})
            }
        }
    }

    const inputsHandle = (event) => {
        const {name, value} = event.target

        setInputsValue(prevState => {
            return {
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value: value,
                }
            }
        })
    }

    const transferInputsValidation = () => {
        const {quantity, address} = inputsValue
        // eslint-disable-next-line
        let isCorrectData = false
        const setInputsValidation = (name, validation) => {
            isCorrectData = !validation
            setInputsValue(prevState => {
                return {
                    ...prevState, [name]: {...prevState[name], validation: validation}
                }
            })
        }

        !quantity.value
            ? setInputsValidation("quantity", true)
            : isNegativeNumber(quantity.value) || Number(quantity.value) === 0 || quantity.value > nftQuantity
                ? setInputsValidation("quantity", true)
                : setInputsValidation("quantity", false)

        !address.value
            ? setInputsValidation("address", true)
            : !isValidEthereumAddress(address.value)
                ? setInputsValidation("address", true)
                : setInputsValidation("address", false)

        setIsTransferStart(!isTransferStart)
    }

    const sendTransaction = async (chainId) => {
        try {
            const result = await safeTransfer(inputsValue.address.value, Number(data.token_id), Number(inputsValue.quantity.value), chainId)

            if (!result.status) {
                setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.TRANSFER_FAILED})
                return
            }

            dispatch(setRefetchInventory())
            setCurrentModal({
                ...currentModal,
                status: STEPS_STATUS.TRANSFER_SUCCESS,
                quantity: inputsValue.quantity.value
            })
        } catch {
            setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.USER_DENIED_SIGNATURE})
        }
    }

    return (
        <CosmeticModalTemplate
            show={true}
            name={name}
            rarity={rarity}
            image={image_url}
            title="Transfer item"
            contentClassName="inputs"
            buttonOne={{callBack: () => buttonsOptions.buttonOne.click(), text: buttonsOptions.buttonOne.text}}
            buttonTwo={{callBack: () => buttonsOptions.buttonTwo.click(), text: buttonsOptions.buttonTwo.text}}
        >
            <Input
                type="number"
                name="quantity"
                label="quantity"
                onChange={inputsHandle}
                value={inputsValue.quantity.value}
                status={inputsValue.quantity.validation ? "error" : "default"}
                error={inputsValue.quantity.validation && "Incorrect quantity"}
            />
            <Input
                label="address"
                name="address"
                onChange={inputsHandle}
                value={inputsValue.address.value}
                status={inputsValue.address.validation ? "error" : "default"}
                error={inputsValue.address.validation && "Incorrect address"}
            />
        </CosmeticModalTemplate>
    )
}

export default LockerCurrentCosmeticTransferModal