import React from "react"
import Button from "components/atoms/Button";
import Image from "components/atoms/Image";

import {svg} from "assets/svg";
import "./style.scss"

const SignIn = ({changeStep}) => {
    return (
        <div className="protected-container_sign-in">
            <div className="protected-container_sign-in_img">
                <Image src={svg.Require} alt="require"/>
            </div>
            <p className="protected-container_sign-in_text">You need an account to access this page!</p>
            <Button view="white" onClick={() => changeStep("step1")}>sign in</Button>
        </div>
    )
}

export default SignIn