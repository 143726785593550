import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom"
import {getPosts} from "store/NewsPosts/postSlice";

import Button from "components/atoms/Button";
import Title from "components/atoms/TitleTemplate";
import ArticleCart from "components/atoms/ArticleCart";

const HomeNews = () => {
    const {posts, success} = useSelector(state => state.posts)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => dispatch(getPosts({first: 9, skip: 0})), [])

    return (
        <section className="home_news">
            <Title underline={true}>Here's <span>the latest</span></Title>
            <div className="home_news_content container">
                {success &&
                    posts?.map((post, i) =>
                        <ArticleCart
                            key={i}
                            date={post.date}
                            text={post.title}
                            img={post.image.url}
                            linkTo={`/news/${post.slug}`}
                        />
                    )
                }
            </div>
            <div className="home_news_btn">
                <Button view="outline" onClick={() => navigate("/news")}>View more</Button>
            </div>
        </section>
    )
}

export default HomeNews